/* Libs */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  Table,
  MultipleSelect,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Utils */
import { getCurrencyValue, getDate, getInitials } from "../../utils/general";

/* Configs */
import { URLS } from "../../configs/URLS";
import { PAGE_LENGTH } from "../../configs/pagination";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Actions */
import { SearchAction } from "../../actions/searchAction";

/* Types */
import type { TbaseReducer } from "../../types/reducer/reducerType";

interface TableProps {
  onSort: (sortKey: string, key: string, order: boolean) => void;
  filters: Record<string, any>;
}

function FolderTable({ onSort, filters }: TableProps) {
  const dispatch = useAppDispatch();
  const [colFilter, setColFilter] = useState<(string | number)[]>([]);

  const { activePage, items, totalItems } = useSelector(
    (state: TbaseReducer) => state.searchReducer
  );

  const [currentRow, setCurrentRow] = useState(-1);
  const navigate = useNavigate();

  const itemsTable = items.map((item) => ({
    ...item,
    dateMission: getDate(item.dateMission),
    dateAppointmennt: getDate(item.dateAppointmennt),
    dateVisit: getDate(item.dateVisit),
    dateClosing: getDate(item.dateClosing),
    totalReportDutyFree: item.totalReportDutyFree
      ? getCurrencyValue(item.totalReportDutyFree)
      : null,
    vradeDutyFree: item.vradeDutyFree
      ? getCurrencyValue(item.vradeDutyFree)
      : null,
    nameExpert: getInitials(item.nameExpert),
    nameAssistant: getInitials(item.nameAssistant),
    statusHome: item.statusHome ? (
      <div
        className="p-10"
        style={{
          backgroundColor: `var(--${item.statusColor}-light)`,
          borderRadius: "8px",
          color: "var(--white)",
        }}
      >
        {item.statusHome}
      </div>
    ) : null,
  }));

  const columns = [
    {
      key: "numberFolder",
      sortKey: "numberFolder",
      label: "Référence",
      canBeSorted: true,
    },
    {
      key: "nameRegion",
      sortKey: "nameRegion",
      label: "Région",
      canBeSorted: true,
    },
    {
      key: "nameAgency",
      sortKey: "nameAgency",
      label: "Agence",
      canBeSorted: true,
    },
    {
      key: "nameRepairman",
      sortKey: "nameRepairman",
      label: "Réparateur",
      canBeSorted: true,
      width: 160,
    },
    {
      key: "nameExpert",
      sortKey: "nameExpert",
      label: "Expert",
      canBeSorted: true,
    },
    {
      key: "nameAssistant",
      sortKey: "nameAssistant",
      label: "Secrétaire",
      canBeSorted: true,
      width: 120,
    },
    {
      key: "nameInsurance",
      sortKey: "nameInsurance",
      label: "Compagnie",
      canBeSorted: true,
    },
    {
      key: "nameClient",
      sortKey: "nameClient",
      label: "Client",
      canBeSorted: true,
      width: 160,
    },
    {
      key: "phoneClient",
      sortKey: "phoneClient",
      label: "Téléphone",
      canBeSorted: true,
    },
    {
      key: "vehicleBrand",
      sortKey: "vehicleBrand",
      label: "Marque",
      canBeSorted: true,
    },
    {
      key: "vehicleModel",
      sortKey: "vehicleModel",
      label: "Modèle",
      canBeSorted: true,
      width: 120,
    },
    {
      key: "vehicleLicensePlate",
      sortKey: "vehicleLicensePlate",
      label: "Immat.",
      canBeSorted: true,
    },
    {
      key: "dateMission",
      sortKey: "dateMission",
      label: "Date mis.",
      canBeSorted: true,
    },
    {
      key: "dateAppointmennt",
      sortKey: "dateAppointmennt",
      label: "Date RDV",
      canBeSorted: true,
    },
    {
      key: "dateVisit",
      sortKey: "dateVisit",
      label: "Date vis.",
      canBeSorted: true,
    },
    {
      key: "natureExpertise",
      sortKey: "natureExpertise",
      label: "Nat. expertise",
      canBeSorted: true,
      width: 120,
    },
    {
      key: "natureEvenment",
      sortKey: "natureEvenment",
      label: "Nat. évènement",
      canBeSorted: true,
    },
    {
      key: "totalReportDutyFree",
      sortKey: "totalReportDutyFree",
      label: "Coût rép. HT",
      canBeSorted: true,
    },
    {
      key: "vradeDutyFree",
      sortKey: "vradeDutyFree",
      label: "VRADE HT",
      canBeSorted: true,
    },
    {
      key: "dateClosing",
      sortKey: "dateClosing",
      canBeSorted: true,
      label: "Date clôt.",
    },
    {
      key: "statusHome",
      label: "État",
    },
  ];

  const filterColumns = (columns: any, colFilter: (string | number)[]) => {
    if (colFilter.length) {
      return columns.filter((objet: any) =>
        colFilter.some((colKey) => colKey === objet.key)
      );
    } else {
      return columns;
    }
  };

  useEffect(() => {
    const storageColumns = localStorage
      .getItem("columnsHistory")
      ?.split(",")
      .filter((el) => el.length);

    if (storageColumns && storageColumns.length) {
      setColFilter(storageColumns);
    }
  }, []);

  return (
    <Table
      columnsSelect={Object.keys(filters)}
      lastColumnSticky={!colFilter.length}
      className="mt-20"
      title="Dossiers"
      context={{ currentRow, setCurrentRow }}
      onRowClick={(item) => navigate(URLS.folder(item.id))}
      columns={filterColumns(columns, colFilter)}
      data={itemsTable}
      onChange={() => {}}
      headerComponent={
        <div style={{ width: "200px" }}>
          <MultipleSelect
            options={columns.map((col) => ({
              text: col.label,
              value: col.key,
            }))}
            placeholder={"Sélection de colonnes"}
            onChange={(value) => {
              localStorage.setItem("columnsHistory", value.join(","));
              setColFilter(value);
            }}
            value={colFilter}
          />
        </div>
      }
      footerComponent={
        <div
          style={{ display: "flex", justifyContent: "end", padding: "16px" }}
        >
          <Pagination
            totalItems={totalItems}
            itemsPerPage={PAGE_LENGTH}
            onPageChange={(page) =>
              dispatch({
                type: SearchAction.CHANGE_ACTIVE_PAGE,
                data: page,
              })
            }
          />
        </div>
      }
      onSort={onSort}
      currentPage={activePage}
      itemsPerPage={PAGE_LENGTH}
    />
  );
}

export default FolderTable;
