import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type ButtonIconProps = {
  className: string;
  disabled?: boolean;
  onClick: Function;
  icon: IconProp;
};

function ButtonIcon({ className, disabled, onClick, icon }: ButtonIconProps) {
  return (
    <button
      style={{
        backgroundColor: "initial",
        border: "none",
        color: "var(--white)",
        cursor: "pointer",
        fontSize: "22px",
        padding: "0",
        position: "relative",
        transition: ".4s",
      }}
      className={className}
      disabled={disabled}
      onClick={() => onClick()}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );
}

export default ButtonIcon;
