/* Libs */
import {
  Icon,
  InputDropZone,
  InputSelect,
  Table,
} from "@sahadevia/ui-kit-modern-sahadevia";
// @ts-ignore
import { RCamera } from "react-camera-media-stream";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

/* Components */
import ModalLayout from "../../layouts/ModalLayout";

/* Actions */
import { ModalAction } from "../../actions/modalAction";
import { insertFiles } from "../../actions/folderAction";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Utils */
import { resizeImage } from "../../utils/general";

/* Types */
import type { TbaseReducer } from "../../types/reducer/reducerType";
import type { TResultWithData } from "../../types/app/appType";

type Props = {};

const ModalDropZone = ({}: Props) => {
  const [isRCameraOpen, setIsCamera] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState(-1);
  const { fileTypes } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  const dispatch = useAppDispatch();
  const { id } = useParams();

  return (
    <ModalLayout>
      <InputDropZone
        isRCameraOpen={isRCameraOpen}
        setIsRCameraOpen={setIsCamera}
        RCamera={
          <RCamera
            isConfirm={false}
            onClose={() => setIsCamera(false)}
            onTakePicture={(base64: string) => {
              const mime = base64.split(";")[0].split(":")[1];

              resizeImage(base64, mime, (base64: any) => {
                const PictureToSend = {
                  base64,
                  extension: mime.split("/")[1],
                  mime: mime,
                  name: `glaSoftware-${new Date().getTime()}.${
                    mime.split("/")[1]
                  }`,
                  type: "149",
                };
                dispatch(
                  insertFiles(id, [PictureToSend], (res: TResultWithData) => {
                    if (res.success) {
                      dispatch({
                        type: ModalAction.CLOSE_UPLOAD_MODAL,
                      });
                      setIsCamera(false);
                    }
                  })
                );
              });
            }}
            isFullscreen={true}
            namePicture="glaSoftware"
            isTorch={true}
          />
        }
        onClick={(selectedFiles) => {
          dispatch(
            insertFiles(id, selectedFiles, (res: TResultWithData) => {
              if (res.success) {
                dispatch({ type: ModalAction.CLOSE_UPLOAD_MODAL });
              }
            })
          );
        }}
        component={(files: any, setFiles) => {
          return (
            <Table
              onChange={(item: any) => {
                const updatedFiles = [...files];
                updatedFiles[currentRow] = {
                  ...updatedFiles[currentRow],
                  name: typeof item.name === "string" ? item.name : "",
                };
                setFiles(updatedFiles);
              }}
              data={files}
              columns={[
                {
                  key: "",
                  label: "#",
                  component: (_, idx) => <>{idx}</>,
                },
                {
                  key: "name",
                  label: "Nom",
                  isInput: true,
                },
                {
                  key: "extension",
                  label: "Extension",
                },
                {
                  key: "type",
                  label: "Type",
                  component: (_, idx) => (
                    <InputSelect
                      disabled={currentRow !== idx}
                      value={_.type}
                      options={fileTypes.map((type) => ({
                        value: type.value,
                        text: type.name,
                      }))}
                      onChange={(value) => {
                        const updatedFiles = [...files];
                        updatedFiles[currentRow] = {
                          ...updatedFiles[currentRow],
                          type: typeof value === "string" ? Number(value) : "",
                        };
                        setFiles(updatedFiles);
                      }}
                      placeholder={""}
                    />
                  ),
                },
                {
                  key: "action",
                  label: "Action",
                  component: (_, idx) => (
                    <>
                      {currentRow !== -1 && currentRow === idx ? (
                        <Icon
                          icon={"Cross"}
                          size={15}
                          onClick={() => setCurrentRow(-1)}
                        />
                      ) : (
                        <Icon
                          icon={"Pen"}
                          size={15}
                          onClick={() => setCurrentRow(idx!)}
                        />
                      )}
                    </>
                  ),
                },
              ]}
              context={{ currentRow, setCurrentRow }}
            />
          );
        }}
      />
    </ModalLayout>
  );
};

export default ModalDropZone;
