import moment from "moment";
import { LibraryAction } from "../actions/libraryAction";
import type {
  TdataFile,
  TlibraryInitialState,
} from "../types/library/libraryType";

export const initialState = {
  isLoad: false,
  data: [] as TdataFile[],
} satisfies TlibraryInitialState;

export const libraryReducer = (state = initialState, action: any) => {
  let tmpData = state.data;

  switch (action.type) {
    case "LOAD_LIBRARY":
      return {
        ...state,
        isLoad: true,
      };
    case LibraryAction.GET_LIBRARY:
      return {
        ...state,
        isLoad: false,
        data: action.data,
      };
    case LibraryAction.ADD_LIBRARY:
      tmpData.push({
        id: action.id,
        category: action.body.category,
        url: action.body.url,
        path: action.path,
        extension: action.extension,
        name: action.body.name,
        keyword: action.body.keyword,
        date: moment().format("YYYY-MM-DD"),
        note: 0,
        noteCount: 0,
      });

      return {
        ...state,
        isLoad: false,
        data: tmpData,
      };
    case LibraryAction.EDIT_LIBRARY:
      tmpData.forEach((data, idx) => {
        if (data.id === action.body.id) {
          tmpData[idx].name = action.body.name;
          tmpData[idx].category = action.body.category;
          tmpData[idx].keyword = action.body.keyword;
        }
      });

      return {
        ...state,
        isLoad: false,
        data: tmpData,
      };
    case LibraryAction.DELETE_LIBRARY:
      tmpData = tmpData.filter((element) => element.id !== action.body.id);

      return {
        ...state,
        isLoad: false,
        data: tmpData,
      };
    case LibraryAction.ADD_NOTE_LIBRARY:
      tmpData.forEach((element) => {
        if (element.id === action.body.id) {
          element.note =
            (element.note * element.noteCount + action.body.note) /
            (element.noteCount + 1);
          element.noteCount++;
        }
      });

      return {
        ...state,
        isLoad: false,
        data: tmpData,
      };
    default:
      return state;
  }
};
