/* Libs */
import { useSelector } from "react-redux";
/* Components */
import ModalSearchFolders from "../components/modal/ModalSearchFolders";
import ModalInfo from "../components/modal/ModalInfo";
import ModalConfirm from "../components/modal/ModalConfirm";
import ModalDropZone from "../components/modal/ModalDropZone";
import ModalManagingHistory from "../components/modal/ModalManagingHistory";
import ModalAddLibrary from "../components/modal/ModalAddLibrary";
import ModalEditLibrary from "../components/modal/ModalEditLibrary";
import ModalCustom from "../components/modal/ModalCustom";

/* Reducers */
import { useAppDispatch } from "../reducers";

/* Actions */
import { changeFolderFiles } from "../actions/folderAction";
import { ModalAction } from "../actions/modalAction";

/* Types */
import type { TbaseReducer } from "../types/reducer/reducerType";

const FolderModals = () => {
  const dispatch = useAppDispatch();
  const {
    confirmModal,
    uploadModal,
    infoModal,
    searchFoldersModal,
    managingHistoryModal,
    editLibraryModal,
    addLibraryModal,
    customModal,
  } = useSelector((state: TbaseReducer) => state.modalReducer);
  const { dataFile } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  return (
    <>
      {searchFoldersModal.isOpen && (
        <ModalSearchFolders
          onValid={(folderId: Number) => {
            const tokens = searchFoldersModal.activeLines?.map(
              (key: number) => dataFile.files[key].token
            );

            if (searchFoldersModal.option === "copy") {
              dispatch(
                changeFolderFiles(
                  folderId,
                  tokens ?? [],
                  () =>
                    dispatch({
                      type: ModalAction.OPEN_INFO_MODAL,
                      data: {
                        title: "Alerte",
                        message: "Copie effecutée",
                      },
                    }),
                  true
                )
              );
            } else if (searchFoldersModal.option === "transfert") {
              dispatch(
                changeFolderFiles(folderId, tokens ?? [], () =>
                  dispatch({
                    type: ModalAction.OPEN_INFO_MODAL,
                    data: { title: "Alerte", message: "Transfert effecuté" },
                  })
                )
              );
            }
          }}
        />
      )}
      {infoModal.isOpen && (
        <ModalInfo message={infoModal.message} title={infoModal.title} />
      )}
      {confirmModal.isOpen && (
        <ModalConfirm
          title={confirmModal.title}
          message={confirmModal.message}
          onConfirm={confirmModal.onConfirm}
        />
      )}
      {uploadModal.isOpen && <ModalDropZone />}
      {managingHistoryModal.isOpen && <ModalManagingHistory />}
      {addLibraryModal.isOpen && (
        <ModalAddLibrary title={addLibraryModal.title} />
      )}
      {editLibraryModal.isOpen && (
        <ModalEditLibrary
          title={editLibraryModal.title}
          id={editLibraryModal.id || 0}
        />
      )}
      {customModal.isOpen && (
        <ModalCustom>
          {customModal.children ? customModal.children : null}
        </ModalCustom>
      )}
    </>
  );
};

export default FolderModals;
