/* Libs */
import { useEffect } from "react";
import { useSelector } from "react-redux";

/* Components */
import FolderTable from "../components/Table/FolderTable";
import Filter from "../components/general/Filter";

/* Actions */
import {
  SearchAction,
  countFolders,
  getFolders,
  getOptions,
} from "../actions/searchAction";

/* Reducers */
import { useAppDispatch } from "../reducers";

/* Configs */
import { PAGE_LENGTH } from "../configs/pagination";

/* Types */
import { TbaseReducer } from "../types/reducer/reducerType";

function SearchView() {
  const dispatch = useAppDispatch();

  const { order, activePage, filters, activeFilters, optionsInsurance } =
    useSelector((state: TbaseReducer) => state.searchReducer);

  const { regionId, agencyId } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  useEffect(() => {
    if (!optionsInsurance.length) {
      dispatch(getOptions());
    }

    dispatch({
      type: SearchAction.UPDATE_FILTERS,
      data: Object.assign({}, filters, {
        ["nameAgency"]: agencyId,
        ["nameRegion"]: regionId,
      }),
    });
  }, []);

  useEffect(() => {
    dispatch(countFolders(activeFilters));

    _getFolders();
  }, [activeFilters]);

  useEffect(() => {
    _getFolders();
  }, [order]);

  useEffect(() => {
    _getFolders();
  }, [activePage]);

  const _getFolders = () => {
    dispatch(
      getFolders(
        activeFilters,
        PAGE_LENGTH,
        (activePage - 1) * PAGE_LENGTH,
        order.name,
        order.desc
      )
    );
  };

  return (
    <>
      <h2 className="mt-0 mb-0">Liste des dossiers</h2>
      <Filter />

      <FolderTable
        filters={activeFilters}
        onSort={(_sortKey, key, order) => {
          dispatch({
            type: SearchAction.UPDATE_ORDER,
            data: { name: key, desc: order },
          });
        }}
      />
    </>
  );
}

export default SearchView;
