import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  InputText,
  InputSelect,
  Pagination,
  Icon,
  Rating,
  Flex,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

import { getDate } from "../utils/general";

import { useAppDispatch } from "../reducers";

import {
  addNoteLibrary,
  deleteLibrary,
  getLibrary,
} from "../actions/libraryAction";
import { ModalAction } from "../actions/modalAction";

import FolderModals from "../modals/FolderModals";
import { TbaseReducer } from "../types/reducer/reducerType";

export const categories = [
  { value: "", text: "Toutes les catégories" },
  { value: "RGPD", text: "RGPD" },
  { value: "Juridique", text: "Juridique" },
  { value: "Technique", text: "Technique" },
  { value: "Carbone", text: "Carbone" },
  { value: "Autre", text: "Autre" },
];

function LibraryView() {
  const dispatch = useAppDispatch();

  const { data } = useSelector((state: TbaseReducer) => state.libraryReducer);

  const [currentRow, setCurrentRow] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState({ key: "date", order: true });
  const [category, setCategory] = useState<string>("");
  const [currentData, setcurrentData] = useState<Record<string, any>>();

  const dataTable = data
    .map((line) => ({
      ...line,
      noteValue: line.note,
      note: (
        <Rating
          size={14}
          number={line.note}
          onRatingClick={(value) => {
            dispatch(addNoteLibrary(line.id, value));
          }}
        />
      ),
    }))
    .sort((a, b) => {
      // @ts-ignore
      let aX = a[sort.key] || "";
      // @ts-ignore
      let bX = b[sort.key] || "";

      if (typeof aX == "number" && typeof bX == "number") {
        return sort.order ? aX - bX : bX - aX;
      } else {
        aX = aX.toString();
        bX = bX.toString();
        return sort.order
          ? aX.normalize().localeCompare(bX.normalize())
          : bX.normalize().localeCompare(aX.normalize());
      }
    })
    .filter((line) => {
      if (
        ((category && line.category === category) || !category) &&
        ((search &&
          (line.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            search.toLocaleLowerCase() === line.name.toLocaleLowerCase())) ||
          !search)
      ) {
        return true;
      } else {
        return false;
      }
    });

  useEffect(() => {
    dispatch(getLibrary());
  }, []);

  return (
    <>
      <FolderModals />
      <Table
        disableOverflow
        showInput
        title="Bibliothèque"
        context={{ currentRow, setCurrentRow }}
        onRowClick={(item) => {
          if (item.url) {
            window.open(item.url, "_blank");
          } else {
            if (document.location.toString().includes("localhost")) {
              window.open(
                `http://localhost:5000/api/library/download-library/token/${window.localStorage.getItem(
                  "token"
                )}/${item.path}`,
                "_blank"
              );
            } else if (document.location.toString().includes("development")) {
              window.open(
                `https://development.glasoftware.fr/api/library/download-library/token/${window.localStorage.getItem(
                  "token"
                )}/${item.path}`,
                "_blank"
              );
            } else {
              window.open(
                `https://glasoftware.fr/api/library/download-library/token/${window.localStorage.getItem(
                  "token"
                )}/${item.path}`,
                "_blank"
              );
            }
          }
        }}
        onSort={(sortKey, key, order) => {
          setSort({
            key: sortKey,
            order,
          });
        }}
        columns={[
          {
            key: "name",
            sortKey: "name",
            label: "Nom",
            canBeSorted: true,
            component: (item) => {
              return (
                <Flex display="flex" gap={10} align="center">
                  <span>
                    <Icon icon={item.url ? "Link" : "File"} size={20} />
                  </span>
                  <span>{item.name}</span>
                </Flex>
              );
            },
          },
          {
            key: "category",
            sortKey: "category",
            label: "Catégorie",
            canBeSorted: true,
          },
          {
            key: "user",
            sortKey: "user",
            label: "Utilisateur",
            canBeSorted: true,
          },
          {
            key: "date",
            sortKey: "date",
            label: "Date",
            canBeSorted: true,
          },
          {
            key: "note",
            sortKey: "noteValue",
            label: "Note",
            canBeSorted: true,
          },
          {
            key: "action",
            sortKey: "action",
            label: "Actions",
            component: (item, idx) => (
              <div
                className="d-flex"
                style={{
                  gap: 5,
                }}
              >
                <Icon
                  backgroundHover
                  infoText="Modifier"
                  icon={"Pen"}
                  size={20}
                  onClick={() => {
                    dispatch({
                      type: ModalAction.OPEN_EDIT_LIBRARY_MODAL,
                      data: {
                        title: "Éditer une ligne",
                        message: "",
                        id: item.id,
                      },
                    });
                    setcurrentData(item);
                  }}
                />
                <Icon
                  backgroundHover
                  infoText="Supprimer"
                  icon={"Trash"}
                  size={20}
                  onClick={() => {
                    dispatch({
                      type: ModalAction.OPEN_CONFIRM_MODAL,
                      data: {
                        title: "Supprimer",
                        message:
                          "Êtes-vous sûr de vouloir supprimer cette ligne ?",
                        onConfirm: () => dispatch(deleteLibrary(item.id)),
                      },
                    });
                  }}
                />
              </div>
            ),
          },
        ]}
        data={dataTable
          .map((item) => ({ ...item, date: getDate(item.date) }))
          .slice((currentPage - 1) * 16, currentPage * 16)}
        headerComponent={
          <>
            <InputText
              placeholder={"Rechercher"}
              onChange={({ target }: any) => {
                setSearch(target.value);
              }}
              value={search}
            />
            <InputSelect
              placeholder={""}
              options={[...categories]}
              onChange={(value) => {
                setCategory(value.toString());
              }}
              value={category}
            />
            <Button
              text="Ajouter un fichier"
              outline
              onClick={() => {
                dispatch({
                  type: ModalAction.OPEN_ADD_LIBRARY_MODAL,
                  data: { title: "Ajouter une ligne", message: "" },
                });
              }}
            />
          </>
        }
        footerComponent={
          <div
            style={{ display: "flex", justifyContent: "end", padding: "16px" }}
          >
            <Pagination
              totalItems={data.length}
              itemsPerPage={16}
              onPageChange={setCurrentPage}
            />
          </div>
        }
      />
      {currentData ? null : null}
    </>
  );
}

export default LibraryView;
