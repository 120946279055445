/* LIBS */
import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";

/* TYPES */
import { TSources } from "../../types/folder/actions/managingType";

/* LOGO */
import ixperienceLogo from "../../images/logo/ixperience.svg";
import pilotageCabinetLogo from "../../images/logo/pilotagecabinet.svg";
import glaLogo from "../../images/gla.svg";
import lacourLogo from "../../images/logo/lacour.png";
import pixtransferLogo from "../../images/logo/pixtransfer.svg";
import redaxionLogo from "../../images/logo/redaxion.svg";
import evalcarLogo from "../../images/logo/evalcar-sigle.svg";

export const getLogo = (source: TSources) => {
  switch (source) {
    case "gla-software":
    case "suivis":
      return (
        <img
          loading="lazy"
          style={{
            maxWidth: 20,
            maxHeight: 20,
          }}
          src={glaLogo}
          alt={`images-${source}`}
        />
      );
    case "ixperience":
      return (
        <img
          loading="lazy"
          style={{
            maxWidth: 20,
            maxHeight: 20,
          }}
          src={ixperienceLogo}
          alt={`images-${source}`}
        />
      );
    case "pilotage-cabinet":
      return (
        <img
          loading="lazy"
          style={{
            maxWidth: 20,
            maxHeight: 20,
          }}
          src={pilotageCabinetLogo}
          alt={`images-${source}`}
        />
      );
    case "lacour":
      return (
        <img
          loading="lazy"
          style={{
            maxWidth: 20,
            maxHeight: 20,
          }}
          src={lacourLogo}
          alt={`images-${source}`}
        />
      );
    case "pixtransfer":
      return (
        <img
          loading="lazy"
          style={{
            maxWidth: 20,
            maxHeight: 20,
          }}
          src={pixtransferLogo}
          alt={`images-${source}`}
        />
      );
    case "redaxion":
      return (
        <img
          loading="lazy"
          style={{
            maxWidth: 20,
            maxHeight: 20,
          }}
          src={redaxionLogo}
          alt={`images-${source}`}
        />
      );
    case "evalcar":
      return (
        <img
          loading="lazy"
          style={{
            maxWidth: 20,
            maxHeight: 20,
          }}
          src={evalcarLogo}
          alt={`images-${source}`}
        />
      );
    case "ega":
      return <>EGA</>;
    case "darva":
      return <>DARVA</>;
    case "actions":
      return <>ACTIONS</>;
    case "mail-sortant":
    case "mail-entrant":
      return <Icon icon={"Envelope"} size={20} noPadding />;

    default:
      <img
        loading="lazy"
        style={{
          maxWidth: 20,
          maxHeight: 20,
        }}
        src={glaLogo}
        alt={`images-${source}`}
      />;
  }
};
