/* Libs */
import { useState } from "react";
import {
  Flex,
  Button,
  InputText,
  InputSelect,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Compnents */
import ModalLayout from "../../layouts/ModalLayout";

/* Actions */
import { editLibrary } from "../../actions/libraryAction";
import { ModalAction } from "../../actions/modalAction";

/* Data */
import { categories } from "../../views/LibraryView";

/* Reducers*/
import { useAppDispatch } from "../../reducers";

/* Types */
import type { TbaseReducer } from "../../types/reducer/reducerType";

type Props = {
  title?: string;
  id: number;
};

const ModalEditLibrary = ({ title, id }: Props) => {
  const dispatch = useAppDispatch();

  const { data } = useSelector((state: TbaseReducer) => state.libraryReducer);

  let idx = 0;
  data.forEach((item, _idx) => {
    if (item.id === id) {
      idx = _idx;
    }
  });

  const [name, setName] = useState(data[idx].name);
  const [category, setCategory] = useState(data[idx].category);
  const [keyword, setKeyword] = useState(data[idx].keyword);

  return (
    <ModalLayout>
      <>
        {title ? <h4 style={{ fontSize: 14, margin: 0 }}>{title}</h4> : null}
        <InputText
          placeholder="Nom"
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
        <InputText
          placeholder="Mots clés"
          value={keyword}
          onChange={({ target }) => setKeyword(target.value)}
        />
        <InputSelect
          placeholder="Catégorie"
          options={[...categories]}
          onChange={(value) => setCategory(value.toString())}
          value={category}
        />
        <Flex display="flex" justify="left" gap={10}>
          <Button
            onClick={() => {
              dispatch({ type: ModalAction.CLOSE_CURRENT_MODAL });
            }}
            text={"Annuler"}
            outline
          />
          <Button
            onClick={() => {
              dispatch(editLibrary(data[idx].id, name, keyword, category));
              dispatch({ type: ModalAction.CLOSE_CURRENT_MODAL });
            }}
            text={"Modifier"}
          />
        </Flex>
      </>
    </ModalLayout>
  );
};

export default ModalEditLibrary;
