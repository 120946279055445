/* Libs */
import {
  Flex,
  Icon,
  InputCheckbox,
  InputSelect,
  InputText,
  Table,
  tableColumnType,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Types */
import {
  ColumnsOnChange,
  TCostingChoc,
  TCostingChocKey,
  TPartsDetails,
} from "../../../../../types/folder/expertisesType";
import { TbaseReducer } from "../../../../../types/reducer/reducerType";

/* Utils */
import { getTotalValue } from "../../../../../utils/costing/indesx";

type Props = {
  currentExpertise: number;
  currentchoc: number;
  handleOnChange: (key: keyof TCostingChoc, item: any) => void;
  addNewRow: (newData: any, key: TCostingChocKey) => void;
  deleteRow: (key: Exclude<TCostingChocKey, "general">, id: number) => void;
};

const PartsDetailsTable = ({
  handleOnChange,
  addNewRow,
  currentExpertise,
  currentchoc,
  deleteRow,
}: Props) => {
  const partsDetails = useSelector(
    (state: TbaseReducer) =>
      state.folderReducer.expertises[currentExpertise].chocs[currentchoc]
        .partsDetails
  );

  const totalPieces = getTotalValue(partsDetails);
  const totalPiecesVAT = getTotalValue(partsDetails, true);

  return (
    <Table
      title="Détail des pièces"
      handleAddRow={(newData) => addNewRow(newData, "partsDetails")}
      columns={partsColumns((item) => {
        handleOnChange("partsDetails", item);
      }, deleteRow)}
      data={partsDetails}
      lastRowColumns={lastRowColumns}
      lastRowData={{
        amountHT: totalPieces,
        amountTTC: totalPiecesVAT,
      }}
      context={undefined}
      bottomAddRow
      headerComponent={
        <Flex
          styles={{
            borderTop: "1px solid var(--light-grey)",
            borderBottom: "1px solid var(--light-grey)",
            borderLeft: "1px solid var(--light-grey)",
            borderRadius: "5px",
          }}
        >
          <Flex
            classNames="disable"
            align="center"
            justify="center"
            styles={{
              borderRight: "1px solid var(--light-grey)",
              height: "100%",
              padding: "10px 20px",
            }}
          >
            DIVA
          </Flex>
          <Flex
            classNames="disable"
            align="center"
            justify="center"
            styles={{
              borderRight: "1px solid var(--light-grey)",
              height: "100%",
              padding: "10px 20px",
            }}
          >
            SIDEXA
          </Flex>
          <Icon
            disabled
            noPadding
            icon={"Reload"}
            style={{
              borderRight: "1px solid var(--light-grey)",
              height: "100%",
              padding: "10px 20px",
            }}
            size={20}
          />
        </Flex>
      }
    />
  );
};

export default PartsDetailsTable;

/** SELECT DATA */
const selectOperationData = [
  { text: "E", value: "E" },
  { text: "R", value: "R" },
  { text: "RP", value: "RP" },
  { text: "PP", value: "PP" },
  { text: "S", value: "S" },
  { text: "EP", value: "EP" },
  { text: "L", value: "L" },
  { text: "C", value: "C" },
  { text: "R", value: "R" },
  { text: "N", value: "N" },
  { text: "RM", value: "RM" },
  { text: "EM", value: "EM" },
  { text: "CB", value: "CB" },
  { text: "D", value: "D" },
  { text: "A", value: "A" },
];

/** COLUMNS */
const partsColumns: (
  onChange: ColumnsOnChange,
  onDelete: (key: Exclude<TCostingChocKey, "general">, id: number) => void
) => tableColumnType<TPartsDetails>[] = (onChange, onDelete) => [
  {
    key: "nbe",
    label: "Nbe",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.nbe}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.nbe = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "libelle",
    label: "Libelllé",
    isInput: true,
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.libelle}
        onChange={(e) => {
          item.libelle = e.target.value;
          onChange(item);
        }}
      />
    ),
  },
  {
    key: "constructRef",
    label: "Réf. constructeur",
    isInput: true,
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.constructRef}
        onChange={(e) => {
          item.constructRef = e.target.value;
          onChange(item);
        }}
      />
    ),
  },
  {
    key: "operation",
    label: "Opération",
    isSelect: true,
    selectData: selectOperationData,
    component: (item) => {
      return (
        <InputSelect
          options={selectOperationData}
          value={item.operation}
          onChange={(value) => {
            item.operation = value;
            onChange(item);
          }}
          placeholder={""}
        />
      );
    },
  },
  {
    key: "p",
    label: "P",
    isCheckbox: true,
    component: (item) => {
      return (
        <InputCheckbox
          text={""}
          isCheck={item.p}
          onChange={(value) => {
            item.p = value;
            onChange(item);
          }}
        />
      );
    },
  },
  {
    key: "priceHT",
    label: "Prix unitaire HT",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.priceHT}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.priceHT = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "disrepair",
    label: "% vétusté",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.disrepair}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.disrepair = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "discount",
    label: "% remise",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.discount}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.discount = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "TVA",
    label: "% TVA",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.TVA}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.TVA = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "amountHT",
    label: "Montant HT",
    component: (item) => {
      const priceHT = item.nbe * item.priceHT;
      const disrepairAmount = priceHT - priceHT * (item.disrepair / 100);
      const discountAmount =
        disrepairAmount - disrepairAmount * (item.discount / 100);
      item.amountHT = discountAmount;
      return <>{discountAmount} €</>;
    },
  },
  {
    key: "amountTTC",
    label: "Montant TTC",
    component: (item) => {
      return (
        <>{(item.amountHT + (item.amountHT * item.TVA) / 100).toFixed(2)} €</>
      );
    },
  },
  {
    key: "action",
    label: "Action",
    component: (item) => (
      <Flex justify="end">
        <Icon
          icon={"Trash"}
          size={20}
          infoText="Supprimer"
          infoTop
          backgroundHover
          onClick={() => onDelete("partsDetails", item.id)}
        />
      </Flex>
    ),
  },
];

const lastRowColumns: tableColumnType<TPartsDetails>[] = [
  { key: "libelle", label: "", colSpan: 9 },
  {
    key: "amountHT",
    label: "",
    component: (item) => (
      <div
        style={{
          color: "white",
          background: "var(--primary-bg-color)",
          padding: 5,
          borderRadius: "5px",
        }}
      >
        {item.amountHT} €
      </div>
    ),
  },
  {
    key: "amountTTC",
    label: "",
    component: (item) => (
      <div
        style={{
          color: "white",
          background: "var(--primary-bg-color)",
          padding: 5,
          borderRadius: "5px",
        }}
      >
        {item.amountTTC} €
      </div>
    ),
  },
  {
    key: "action",
    label: "",
  },
];
