/* Components */
import { useSelector } from "react-redux";
import ModalLayout from "../../layouts/ModalLayout";
import { TbaseReducer } from "../../types/reducer/reducerType";

type Props = {
  children: JSX.Element | null;
};

const ModalCustom = ({ children }: Props) => {
  const { customModal } = useSelector(
    (state: TbaseReducer) => state.modalReducer
  );
  const isGoogleMap = customModal.target;

  if (!children) return;

  return (
    <ModalLayout
      target={isGoogleMap ? isGoogleMap : "root"}
      styles={isGoogleMap ? { height: "100%", width: "100%" } : {}}
    >
      {children}
    </ModalLayout>
  );
};

export default ModalCustom;
