/* Libs */
import {
  Flex,
  Table,
  tableColumnType,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Types */
import { TGeneralAmount } from "../../../../../types/folder/expertisesType";
import { TbaseReducer } from "../../../../../types/reducer/reducerType";

/* Types */
import { getTotalValue } from "../../../../../utils/costing/indesx";

type Props = {
  currentExpertise: number;
  currentchoc: number;
};

const GeneralTable = ({ currentExpertise, currentchoc }: Props) => {
  const partsDetails = useSelector(
    (state: TbaseReducer) =>
      state.folderReducer.expertises[currentExpertise].chocs[currentchoc]
        .partsDetails
  );
  const detailsOperation = useSelector(
    (state: TbaseReducer) =>
      state.folderReducer.expertises[currentExpertise].chocs[currentchoc]
        .detailsOperation
  );
  const { dataGeneral } = useSelector(
    (state: TbaseReducer) =>
      state.folderReducer.expertises[currentExpertise].chocs[currentchoc]
  );

  const totalPieces = getTotalValue(partsDetails);
  const totalPiecesVAT = getTotalValue(partsDetails, true);

  const totalHours = getTotalValue(detailsOperation);
  const totalHoursVAT = getTotalValue(detailsOperation, true);

  const { pourcRemisePiece, pourcRemiseMo, discount } = dataGeneral;

  const totalPiecesHT = totalPieces - totalPieces * (pourcRemisePiece / 100);
  const totalPiecesWithDiscount =
    totalPiecesVAT - totalPiecesVAT * (pourcRemisePiece / 100);

  const totalHoursHT = totalHours - totalHours * (pourcRemiseMo / 100);
  const totalMoWithDiscount =
    totalHoursVAT - totalHoursVAT * (pourcRemiseMo / 100);

  const totalBeforeDiscount = totalPiecesHT + totalHoursHT;

  const totalDiscountTTC = totalPiecesWithDiscount + totalMoWithDiscount;

  const totalDiscountHT =
    (totalBeforeDiscount -
      (totalBeforeDiscount - totalBeforeDiscount * (discount / 100))) *
    -1;

  const discountTTC = totalDiscountTTC - totalDiscountTTC * (discount / 100);

  const discountTva =
    (totalDiscountTTC -
      discountTTC -
      (totalBeforeDiscount -
        (totalBeforeDiscount - totalBeforeDiscount * (discount / 100)))) *
    -1;

  const totalTVA =
    Number((totalPiecesWithDiscount - totalPiecesHT).toFixed(2)) +
    Number((totalMoWithDiscount - totalHoursHT).toFixed(2)) +
    discountTva;

  const totalHT = totalPiecesHT + totalHoursHT + totalDiscountHT;

  return (
    <Table
      title="Total général"
      data={[
        {
          id: 0,
          libelle: "Pièces",
          amountHTWithoutDiscount: totalPieces,
          discount: pourcRemisePiece,
          amountHT: totalPiecesHT,
          amountTVA: Number(
            (totalPiecesWithDiscount - totalPiecesHT).toFixed(2)
          ),
          amountTTC: totalPiecesWithDiscount,
        },
        {
          id: 1,
          libelle: "Main d'œuvre",
          amountHTWithoutDiscount: totalHours,
          discount: pourcRemiseMo,
          amountHT: totalHoursHT,
          amountTVA: Number((totalMoWithDiscount - totalHoursHT).toFixed(2)),
          amountTTC: totalMoWithDiscount,
        },
        {
          id: 2,
          libelle: "Remise/Total",
          amountHTWithoutDiscount: totalPiecesHT + totalHoursHT,
          discount: discount,
          amountHT: Number(totalDiscountHT.toFixed(2)),
          amountTVA: Number(discountTva.toFixed(2)),
          amountTTC: Number(((totalDiscountTTC - discountTTC) * -1).toFixed(2)),
        },
      ]}
      columns={generalColumns()}
      lastRowColumns={[
        {
          key: "libelle",
          label: "TOTAL",
          colSpan: 3,
          component: (item) => <Flex justify="center">{item.libelle}</Flex>,
        },
        {
          key: "amountHT",
          label: "",
          component: (item) => (
            <div
              style={{
                color: "white",
                background: "var(--primary-bg-color)",
                padding: 5,
                borderRadius: "5px",
              }}
            >
              {item.amountHT} €
            </div>
          ),
        },
        {
          key: "amountTVA",
          label: "",
          component: (item) => {
            return (
              <div
                style={{
                  color: "white",
                  background: "var(--primary-bg-color)",
                  padding: 5,
                  borderRadius: "5px",
                }}
              >
                {item.amountTVA} €
              </div>
            );
          },
        },

        {
          key: "amountTTC",
          label: "",
          component: (item) => (
            <div
              style={{
                color: "white",
                background: "var(--primary-bg-color)",
                padding: 5,
                borderRadius: "5px",
              }}
            >
              {item.amountTTC} €
            </div>
          ),
        },
      ]}
      lastRowData={{
        libelle: "TOTAL",
        discount: 0,
        amountHT: Number(totalHT.toFixed(2)),
        amountTVA: Number(totalTVA.toFixed(2)),
        amountTTC: Number(discountTTC.toFixed(2)),
      }}
      context={undefined}
    />
  );
};

export default GeneralTable;

/** COLUMNS */
const generalColumns: () => tableColumnType<TGeneralAmount>[] = () => [
  {
    key: "libelle",
    label: "Libellé",
    component: (item) => <>{item.libelle}</>,
  },
  {
    key: "amountHTWithoutDiscount",
    label: "Montant HT avant remise",
    component: (item) => <>{item.amountHTWithoutDiscount} €</>,
  },
  {
    key: "discount",
    label: "% REMISE",
    component: (item) => <>{item.discount}</>,
  },
  {
    key: "amountHT",
    label: "Montant HT",
    component: (item) => <>{item.amountHT} €</>,
  },
  {
    key: "amountTVA",
    label: "Montant TVA",
    component: (item) => {
      return <>{item.amountTVA} €</>;
    },
  },

  {
    key: "amountTTC",
    label: "Montant TTC",
    component: (item) => <>{item.amountTTC} €</>,
  },
];
