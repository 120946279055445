import { AuthAction } from "../actions/authAction";
import type { TauthInitialState } from "../types/auth/authType";

export const initialState = {
  isLoad: true,
  isAuthenticated: false,
  redirect: "",
  role: "",
  type: "",
  redirectSignout: "",
  name: "",
  token: "",
  userId: null,
  regionId: null,
  agencyId: null,
} satisfies TauthInitialState;

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LOAD_AUTH":
      return {
        ...state,
        isLoad: true,
      };
    case AuthAction.REFRESH_TOKEN:
      window.localStorage.setItem("token", action.token);

      return {
        ...state,
        isLoad: false,
        isAuthenticated: action.success,
        redirect: action.redirect,
        userId: action.data ? action.data.id : "",
        role: action.data ? action.data.role : "",
        type: action.data ? action.data.type : "",
        name: action.data ? action.data.name : "",
        token: action.token,
        regionId: action.data ? action.data.region_id : null,
        agencyId: action.data ? action.data.agency_id : null,
      };
    case AuthAction.SIGNOUT:
      return {
        ...state,
        redirectSignout: action.redirect,
      };
    default:
      return state;
  }
};
