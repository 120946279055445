import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// @ts-ignore
import { fabric } from "fabric";
import {
  faHandPointer,
  faCaretLeft,
  faCaretRight,
  faClose,
  faPen,
  faSave,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faHighlighter,
  faUnderline,
  faKeyboard,
  faTrash,
  faRotateLeft,
  faRotateRight,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle, faSquare } from "@fortawesome/free-regular-svg-icons";
import { Loader } from "@sahadevia/ui-kit-modern-sahadevia";
import { usePdf } from "@mikecousins/react-pdf";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ButtonIcon from "./ButtonIcon";

import ColorPicker from "./ColorPicker";
import { useAppDispatch } from "../../../reducers";
import { insertFiles } from "../../../actions/folderAction";
import { ModalAction } from "../../../actions/modalAction";

interface DocumentEditorProps {
  path: string;
  filename: string;
  type: string;
  extension: string;
  token: string;
  onBack?: Function;
  onNext?: Function;
  current?: number;
  length?: number;
  onClose: Function;
}

interface ButtonIconCustom {
  isActive?: boolean;
  disabled?: boolean;
  color?: string;
  icon: IconProp;
  onClick: Function;
}

function DocumentEditor({
  path,
  filename,
  type,
  extension,
  token,
  onBack,
  onNext,
  current,
  length = 0,
  onClose,
}: DocumentEditorProps) {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const ButtonIconCustom = ({
    isActive,
    color,
    icon,
    onClick,
    disabled = false,
  }: ButtonIconCustom) => {
    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          borderRadius: "2px",
          cursor: "pointer",
          margin: "auto",
        }}
      >
        <ButtonIcon
          disabled={disabled}
          className={`${color} ${isActive ? "green" : ""} ml-10 mr-10`}
          onClick={onClick}
          icon={icon}
        />
      </div>
    );
  };
  const Divider = () => {
    return (
      <div
        style={{
          height: "calc(100% - 16px)",
          marginTop: "8px",
          marginBottom: "8px",
          marginLeft: "8px",
          marginRight: "8px",
          width: "2px",
          borderRadius: "1px",
          backgroundColor: "rgba(255,255,255,0.4)",
        }}
      />
    );
  };

  const [activeTool, setActiveTool] = useState("");

  /* START Render PDF */

  const [page, setPage] = useState(1);
  const [scale, setScale] = useState(1);
  const canvasRef = useRef(null);

  const uint8ToBase64 = (arr: any) =>
    btoa(
      Array(arr.length)
        .fill("")
        .map((_, i) => String.fromCharCode(arr[i]))
        .join("")
    );

  const { pdfDocument } = usePdf({
    file: path,
    page,
    canvasRef,
    scale,
    onDocumentLoadFail: () => {
      dispatch({
        type: ModalAction.OPEN_INFO_MODAL,
        data: { title: "Alerte", message: "Une erreur est survenue !" },
      });
      onClose();
    },
  });

  /* START Render PDF */

  /* START FabricJS */

  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [text, setText] = useState("");
  const [hexa, setHexa] = useState("#000000");
  const [isColorPicker, setIsColorPicker] = useState(false);
  const [isRedoing, setIsRedoing] = useState<Boolean | null>(null);
  const [h, setH] = useState<Array<fabric.Object>>([]);
  const [statusSave, setStatusSave] = useState<Boolean>(true);
  const textRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (canvas) {
      let object = canvas.getActiveObject();
      if (object?.type === "text") {
        // @ts-ignore
        object?.set("text", text);
      }
    }
  }, [text]);

  useEffect(() => {
    setIsColorPicker(false);
  }, [hexa]);

  let isDown = false;
  let x = 0;
  let y = 0;

  useEffect(() => {
    if (pdfDocument) {
      if (canvas) {
        setTimeout(() => initCanvas(), 500);
      } else {
        initCanvas();
      }
    }
  });

  useEffect(() => {
    initEvent();
  }, [activeTool]);

  useEffect(() => {
    loadCanvas();
  }, [canvas, current, page]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp" && current !== 0 && onBack) {
      onBack();
    } else if (event.key === "ArrowDown" && current !== length - 1 && onNext) {
      onNext();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [current]);

  const loadCanvas = () => {
    if (canvas) {
      canvas.loadFromJSON({}, () => {});
    }
  };

  const initEvent = () => {
    if (canvas) {
      if (activeTool === "pen") {
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.width = 2;
        canvas.freeDrawingBrush.color = hexa;
      } else {
        canvas.isDrawingMode = false;
      }

      if (activeTool === "pointer") {
        canvas.selection = true;
        canvas.forEachObject((object) => {
          object.selectable = true;
          object.evented = true;
        });
      } else {
        canvas.selection = false;
      }

      // @ts-ignore
      delete canvas.__eventListeners;

      let object: any = null;

      canvas.on("mouse:down", (o) => {
        isDown = true;
        var pointer = canvas.getPointer(o.e);
        x = pointer.x;
        y = pointer.y;

        if (activeTool === "square") {
          object = new fabric.Rect({
            width: 1,
            height: 1,
            left: x,
            top: y,
            stroke: hexa,
            strokeWidth: 2,
            fill: "",
          });
        } else if (activeTool === "circle") {
          object = new fabric.Ellipse({
            left: x,
            top: y,
            rx: 0,
            ry: 0,
            stroke: hexa,
            strokeWidth: 2,
            fill: "",
          });
        } else if (activeTool === "stamp1") {
          fabric.Image.fromURL(
            // @ts-ignore
            "/images/approved.png",
            (myImg: any) => {
              var img1 = myImg.set({
                left: pointer.x - 100,
                top: pointer.y - 30,
                scaleX: 0.2,
                scaleY: 0.2,
              });
              canvas.add(img1);
            }
          );
        } else if (activeTool === "stamp2") {
          fabric.Image.fromURL(
            // @ts-ignore
            "/images/not-approved.png",
            (myImg: any) => {
              var img1 = myImg.set({
                left: pointer.x - 100,
                top: pointer.y - 30,
                scaleX: 0.2,
                scaleY: 0.2,
              });
              canvas.add(img1);
            }
          );
        } else if (activeTool === "highlight") {
          object = new fabric.Line(
            [pointer.x, pointer.y - 10, pointer.x, pointer.y - 10],
            {
              left: pointer.x,
              top: pointer.y - 10,
              stroke: "rgba(0,0,0,0.2)",
              strokeWidth: 20,
            }
          );
        } else if (activeTool === "underline") {
          object = new fabric.Line(
            [pointer.x, pointer.y - 1, pointer.x, pointer.y - 1],
            {
              left: pointer.x,
              top: pointer.y - 1,
              stroke: hexa,
              strokeWidth: 2,
            }
          );
        } else if (activeTool === "keyboard") {
          object = new fabric.Text("", {
            fill: hexa,
            left: pointer.x,
            top: pointer.y,
          });
        }

        if (object) {
          canvas.add(object);
          canvas.setActiveObject(object);
          canvas.renderAll();
        }
      });

      canvas.on("mouse:move", (o) => {
        if (!isDown) return;
        var pointer = canvas.getPointer(o.e);

        let _x = Math.min(pointer.x, x);
        let _y = Math.min(pointer.y, y);
        let w = Math.abs(pointer.x - x);
        let h = Math.abs(pointer.y - y);

        let object = canvas.getActiveObject();

        if (activeTool === "square") {
          if (!w || !h) {
            return false;
          }

          object
            ?.set("top", _y)
            .set("left", _x)
            .set("width", w)
            .set("height", h);
        } else if (activeTool === "circle") {
          if (x > pointer.x) {
            object?.set("left", Math.abs(pointer.x));
          }

          if (y > pointer.y) {
            object?.set("top", Math.abs(pointer.y));
          }

          object
            ?.set(
              // @ts-ignore
              "rx",
              Math.abs((x - pointer.x) / 2)
            )
            .set(
              // @ts-ignore
              "ry",
              Math.abs((y - pointer.y) / 2)
            );
        } else if (activeTool === "highlight" || activeTool === "underline") {
          // @ts-ignore
          object?.set("x2", pointer.x);
        }

        canvas.renderAll();
      });

      canvas.on("mouse:up", (o) => {
        isDown = false;

        if (
          activeTool === "keyboard" ||
          activeTool === "stamp1" ||
          activeTool === "stamp2"
        ) {
          setActiveTool("pointer");
        }

        if (
          activeTool !== "pointer" &&
          activeTool !== "keyboard" &&
          activeTool !== "stamp1" &&
          activeTool !== "stamp2"
        ) {
          canvas.discardActiveObject();
          canvas.forEachObject((object) => {
            object.selectable = false;
            object.evented = false;
          });
          canvas.renderAll();
        }
      });

      canvas.on("object:added", () => {
        setStatusSave(false);
        if (!isRedoing) {
          setH([]);
        }
        setIsRedoing(false);
      });
      canvas.on("object:removed", () => setStatusSave(false));
      canvas.on("object:modified", () => setStatusSave(false));
    }
  };

  const undo = () => {
    setIsRedoing(null);
    if (canvas && canvas._objects.length > 0) {
      const item = canvas._objects.pop();
      if (item) {
        setH([...h, item]);
      }
      canvas.renderAll();
    }
  };

  const redo = () => {
    if (h.length > 0) {
      setIsRedoing(true);
      const item = h.pop();
      if (item) {
        canvas?.add(item);
      }
    }
  };

  const initCanvas = () => {
    if (canvas) {
      // @ts-ignore
      canvas.setHeight(canvasRef?.current?.clientHeight || 0);
      // @ts-ignore
      canvas.setWidth(canvasRef?.current?.clientWidth || 0);
      canvas.renderAll();
      canvas.setZoom(scale);
    } else {
      setCanvas(
        new fabric.Canvas("canvas", {
          // @ts-ignore
          height: canvasRef?.current?.clientHeight || 0,
          // @ts-ignore
          width: canvasRef?.current?.clientWidth || 0,
        })
      );
    }
  };

  useEffect(() => {
    document.removeEventListener("keydown", onText, false);
    document.addEventListener("keydown", onText, false);

    return () => {
      document.removeEventListener("keydown", onText, false);
    };
  }, [canvas]);

  const onText = () => {
    if (canvas) {
      const object = canvas.getActiveObject();
      if (object?.type === "text") {
        // @ts-ignore
        setText(object?.get("text"));
        textRef.current?.focus();
      }
    }
  };

  /* END FabricJS */

  if (!pdfDocument) {
    return <Loader />;
  }

  const _saveFile = (base64Page: string) => {
    pdfDocument.getData().then((result: any) => {
      dispatch(
        // @ts-ignore
        insertFiles(
          // @ts-ignore
          id,
          [
            {
              base64: uint8ToBase64(result),
              base64_png: base64Page,
              page,
              extension,
              name: `${filename}-copie`,
              type,
            },
          ],
          () => {
            onClose();
          }
        )
      );
    });
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 500,
        backgroundColor: "#282828",
      }}
    >
      <input
        ref={textRef}
        style={{ zIndex: 0, position: "absolute", top: "-100px" }}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
      {onBack && onNext ? (
        <div
          className="pl-10 pr-10 d-flex"
          style={{
            position: "absolute",
            left: "8px",
            top: "8px",
            listStyle: "none",
            margin: "0 auto",
            textAlign: "center",
            verticalAlign: "middle",
            borderRadius: "20px",
            backgroundColor: "rgb(53,53,53)",
            zIndex: "2",
            height: "40px",
          }}
        >
          <ButtonIconCustom
            disabled={current === 0}
            icon={faCaretLeft}
            onClick={() => {
              if (statusSave) {
                setPage(1);
                onBack();
              } else {
                dispatch({
                  type: ModalAction.OPEN_CONFIRM_MODAL,
                  data: {
                    title: "Confirmation",
                    message:
                      "Êtes-vous sûr de vouloir changer de document sans sauvegarder",
                    onConfirm: () => {
                      setPage(1);
                      onBack();
                    },
                  },
                });
              }
            }}
          />
          <ButtonIconCustom
            disabled={current === length - 1}
            icon={faCaretRight}
            onClick={() => {
              if (statusSave) {
                setPage(1);
                onNext();
              } else {
                dispatch({
                  type: ModalAction.OPEN_CONFIRM_MODAL,
                  data: {
                    title: "Confirmation",
                    message:
                      "Êtes-vous sûr de vouloir changer de document sans sauvegarder",
                    onConfirm: () => {
                      setPage(1);
                      onNext();
                    },
                  },
                });
              }
            }}
          />
          <p className="ml-10 mt-auto mb-auto white">{filename}</p>
        </div>
      ) : null}
      <div
        className="pl-10 pr-10 d-flex"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "8px",
          listStyle: "none",
          margin: "0 auto",
          textAlign: "center",
          verticalAlign: "middle",
          borderRadius: "20px",
          backgroundColor: "rgb(53,53,53)",
          zIndex: "2",
          height: "40px",
        }}
      >
        <div
          className="cursor-pointer"
          style={{
            margin: "auto",
            backgroundColor: hexa,
            width: "28px",
            height: "28px",
            borderRadius: "14px",
            border: "solid 2px var(--gray)",
          }}
          onClick={() => {
            setIsColorPicker(true);
            setActiveTool("");
          }}
        />
        <Divider />
        <ButtonIconCustom
          isActive={activeTool === "pointer"}
          icon={faHandPointer}
          onClick={() => {
            setActiveTool("pointer");
          }}
        />
        <Divider />
        <ButtonIconCustom
          isActive={activeTool === "highlight"}
          icon={faHighlighter}
          onClick={() => {
            setActiveTool("highlight");
          }}
        />
        <ButtonIconCustom
          isActive={activeTool === "stamp1"}
          icon={faCircleCheck}
          onClick={() => {
            setActiveTool("stamp1");
          }}
        />
        <ButtonIconCustom
          isActive={activeTool === "stamp2"}
          icon={faCircleXmark}
          onClick={() => {
            setActiveTool("stamp2");
          }}
        />
        <ButtonIconCustom
          isActive={activeTool === "circle"}
          icon={faCircle}
          onClick={() => {
            setActiveTool("circle");
          }}
        />
        <ButtonIconCustom
          isActive={activeTool === "square"}
          icon={faSquare}
          onClick={() => {
            setActiveTool("square");
          }}
        />
        <ButtonIconCustom
          isActive={activeTool === "underline"}
          icon={faUnderline}
          onClick={() => {
            setActiveTool("underline");
          }}
        />
        <ButtonIconCustom
          isActive={activeTool === "keyboard"}
          icon={faKeyboard}
          onClick={() => {
            setActiveTool("keyboard");
          }}
        />
        <ButtonIconCustom
          isActive={activeTool === "pen"}
          icon={faPen}
          onClick={() => {
            setActiveTool("pen");
          }}
        />
        <ButtonIconCustom
          icon={faTrash}
          onClick={() => {
            if (canvas) {
              const activeObjects = canvas.getActiveObjects();
              if (activeObjects) {
                activeObjects.forEach((object) => {
                  canvas.remove(object);
                });
              }
            }
          }}
          color="red"
        />
        <Divider />
        <ButtonIconCustom
          icon={faMagnifyingGlassMinus}
          onClick={() => setScale(scale - 0.05)}
        />
        <ButtonIconCustom
          icon={faMagnifyingGlassPlus}
          onClick={() => setScale(scale + 0.05)}
        />
        <Divider />
        <ButtonIconCustom
          icon={faRotateLeft}
          onClick={() => undo()}
          disabled={!canvas || canvas._objects.length === 0}
        />
        <ButtonIconCustom
          icon={faRotateRight}
          onClick={() => redo()}
          disabled={h.length === 0}
        />
        <Divider />
        <ButtonIconCustom
          icon={faCaretLeft}
          onClick={() => {
            if (statusSave) {
              setPage(page - 1);
            } else {
              dispatch({
                type: ModalAction.OPEN_CONFIRM_MODAL,
                data: {
                  title: "Confirmation",
                  message:
                    "Êtes-vous sûr de vouloir changer de page sans sauvegarder",
                  onConfirm: () => {
                    setPage(page - 1);
                  },
                },
              });
            }
          }}
          disabled={page === 1}
        />
        <p className="mt-auto mb-auto white">
          {page}/{pdfDocument.numPages}
        </p>
        <ButtonIconCustom
          icon={faCaretRight}
          onClick={() => {
            if (statusSave) {
              setPage(page + 1);
            } else {
              dispatch({
                type: ModalAction.OPEN_CONFIRM_MODAL,
                data: {
                  title: "Confirmation",
                  message:
                    "Êtes-vous sûr de vouloir changer de page sans sauvegarder",
                  onConfirm: () => {
                    setPage(page + 1);
                  },
                },
              });
            }
          }}
          disabled={page === pdfDocument.numPages}
        />
      </div>
      <div
        className="pl-10 pr-10 d-flex"
        style={{
          position: "absolute",
          right: "8px",
          top: "8px",
          listStyle: "none",
          margin: "0 auto",
          textAlign: "center",
          verticalAlign: "middle",
          borderRadius: "20px",
          backgroundColor: "rgb(53,53,53)",
          zIndex: "2",
          height: "40px",
        }}
      >
        <ButtonIconCustom
          color={statusSave ? "green" : "orange"}
          icon={faSave}
          onClick={() => {
            _saveFile(
              canvas?.toDataURL({
                format: "png",
              }) || ""
            );
          }}
        />
        <ButtonIconCustom
          color="red"
          icon={faClose}
          onClick={() => {
            if (statusSave) {
              onClose();
            } else {
              dispatch({
                type: ModalAction.OPEN_CONFIRM_MODAL,
                data: {
                  title: "Confirmation",
                  message:
                    "Êtes-vous sûr de vouloir fermer l'éditeur sans sauvegarder",
                  onConfirm: () => {
                    onClose();
                  },
                },
              });
            }
          }}
        />
      </div>
      <div
        style={{
          height: "56px",
          width: "100%",
        }}
      />
      <div
        className="m-auto"
        style={{
          position: "relative",
          overflowY: "auto",
        }}
      >
        <canvas ref={canvasRef} />
        <div
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <canvas id="canvas" />
        </div>
      </div>
      {isColorPicker ? (
        <ColorPicker onValid={(hexa: string) => setHexa(hexa)} />
      ) : null}
    </div>
  );
}

export default DocumentEditor;
