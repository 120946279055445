import { LoaderAction } from "../actions/loaderAction";
import type { TLoaderInitialState } from "../types/app/appType";

export const initialState = {
  loaderQueue: 0,
} satisfies TLoaderInitialState;

export const loaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LoaderAction.START_LOADING:
      return {
        ...state,
        loaderQueue: state.loaderQueue + 1,
      };
    case LoaderAction.STOP_LOADING:
      return {
        ...state,
        loaderQueue:
          state.loaderQueue !== 0 ? state.loaderQueue - 1 : state.loaderQueue,
      };
    default:
      return state;
  }
};
