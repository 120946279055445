/* Libs */
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Flex,
  Icon,
  InputSelect,
  Table,
  InputCheckbox,
} from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";

/* Reducers */
import { useAppDispatch } from "../../../reducers";

/* Actions */
import {
  deleteFiles,
  updateDataFile,
  updateDataFileOff,
} from "../../../actions/folderAction";
import { ModalAction } from "../../../actions/modalAction";

/* Utils */
import { downloadFile } from "../../../utils/general";
import getUrl from "../../../utils/getUrl";

/* Components */
import Editor from "./Editor";
import ButtonList from "./ButtonList";

/* Types */
import type { TbaseReducer } from "../../../types/reducer/reducerType";

type Props = {
  handleGedImageList: (idxArray: string[]) => void;
};

function Documents({ handleGedImageList }: Props) {
  const dispatch = useAppDispatch();

  const [activeEdit, setActiveEdit] = useState<string | null>(null);
  const [activeImage, setActiveImage] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState(-1);

  const { id } = useParams();

  const { dataFile, fileTypes, gedImageList } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  const files = (dataFile.files || [])
    .filter(
      (file) =>
        !["jpg", "png", "jpeg"].includes(file.extension.toLowerCase()) ||
        activeImage
    )
    .map((file) => ({
      ...file,
      date:
        file.date && !file.date.startsWith("0000")
          ? moment(file.date).format("DD/MM/YYYY")
          : "",
    }));

  const _deleteFiles = (tokens: Array<string>) => {
    dispatch(deleteFiles(id, tokens, () => handleGedImageList([])));
  };

  const openModalFlex = (item: any) => {
    dispatch({
      type: ModalAction.OPEN_FLEX_MODAL,
      data: {
        position: "rightBottom",
        children: (
          <div>
            <embed
              src={getUrl(id, item.token)}
              type="application/pdf"
              width="700px"
              height="1050px"
            />
          </div>
        ),
      },
    });
  };

  return (
    <>
      {!activeEdit && (
        <Table
          headerComponent={
            <Flex
              display="flex"
              direction="column"
              gap={5}
              styles={{ padding: "15px 20px" }}
            >
              <Flex display="flex" justify="space-between" align="center">
                <h2 style={{ margin: 0, fontSize: "16px" }}>Documents</h2>
                <ButtonList
                  activeLines={gedImageList}
                  files={files}
                  resetActiveLines={() => handleGedImageList([])}
                />
              </Flex>
              <InputCheckbox
                text={"Afficher les images"}
                isCheck={activeImage}
                onChange={() => null}
                onClick={() => setActiveImage(!activeImage)}
              />
            </Flex>
          }
          showInput
          onChange={(e) => {
            if (typeof e !== "string") {
              dispatch(updateDataFileOff(e.token, e.filename, Number(e.type)));
            }
          }}
          onInputOnBlur={(_e, _idx, file) => {
            if (file) {
              dispatch(
                updateDataFile(
                  id,
                  file.token,
                  file.filename,
                  Number(file.type),
                  () => {},
                  true
                )
              );
            }
          }}
          onInputKeyDown={(_e, _idx, file) => {
            if (file) {
              dispatch(
                updateDataFile(
                  id,
                  file.token,
                  file.filename,
                  Number(file.type),
                  () => {},
                  true
                )
              );
            }
          }}
          data={files}
          columns={[
            {
              key: "",
              label: "",
              component: (item) => {
                return (
                  <InputCheckbox
                    text={""}
                    isCheck={gedImageList.includes(item.token)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => {
                      if (gedImageList.includes(item.token)) {
                        handleGedImageList(
                          gedImageList.filter((number) => number !== item.token)
                        );
                      } else {
                        handleGedImageList([...gedImageList, item.token]);
                      }
                    }}
                  />
                );
              },
            },
            {
              key: "date",
              sortKey: "date",
              label: "Date",
              canBeSorted: true,
            },
            {
              key: "filename",
              sortKey: "filename",
              label: "Nom",
              isInput: true,
              canBeSorted: true,
            },
            {
              key: "type",
              sortKey: "type",
              label: "Type",
              canBeSorted: true,
              component: (file, idx) => {
                const fileType = fileTypes.find(
                  (type) => type.value === file.type
                );

                return (
                  <InputSelect
                    value={fileType ? fileType.value : 145}
                    options={fileTypes.map((type) => ({
                      value: type.value,
                      text: type.name,
                    }))}
                    onChange={(value) => {
                      dispatch(
                        updateDataFile(
                          id,
                          file.token,
                          file.filename,
                          (file.type = value as number),
                          () => {},
                          true
                        )
                      );
                    }}
                    placeholder={fileType ? fileType.name : ""}
                  />
                );
              },
            },
            {
              key: "source",
              label: "Source",
              sortKey: "source",
              canBeSorted: true,
            },
            {
              key: "action",
              label: "Action",
              component: (item) => (
                <div
                  className="d-flex"
                  style={{
                    gap: 5,
                  }}
                >
                  <Icon
                    backgroundHover
                    infoText="Télécharger"
                    icon={"Download"}
                    size={20}
                    onClick={() =>
                      downloadFile(
                        getUrl(id, item.token),
                        `${item.filename}.${item.extension}`
                      )
                    }
                  />
                  <Icon
                    backgroundHover
                    infoText="Ouvrir avec l'éditeur natif"
                    icon={"Fire"}
                    size={20}
                    onClick={() => {}}
                  />
                  <Icon
                    backgroundHover
                    infoText="Supprimer"
                    icon={"Trash"}
                    size={20}
                    onClick={() => {
                      dispatch({
                        type: ModalAction.OPEN_CONFIRM_MODAL,
                        data: {
                          title: "Confirmation",
                          message:
                            "Êtes-vous sûr de vouloir supprimer ce document ?",
                          action: () => _deleteFiles([item.token]),
                        },
                      });
                    }}
                  />
                  <Icon
                    backgroundHover
                    infoText="Ouvrir dans l'éditeur"
                    icon={"Search"}
                    size={20}
                    onClick={() => setActiveEdit(item.token)}
                  />
                </div>
              ),
            },
          ]}
          context={{ currentRow, setCurrentRow }}
          onRowClick={(item) => {
            openModalFlex(item);
          }}
        />
      )}
      {activeEdit ? (
        <Editor
          setActiveEdit={(token: string) => setActiveEdit(token)}
          token={activeEdit}
          files={files}
          path={getUrl(id, activeEdit, 1920)}
          onClose={() => setActiveEdit(null)}
        />
      ) : null}
    </>
  );
}

export default Documents;
