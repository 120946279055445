/* Libs */
import {
  Flex,
  Icon,
  InputText,
  Table,
  tableColumnType,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Types */
import {
  ColumnsOnChange,
  TCostingChoc,
  TCostingChocKey,
  TDetailsSRGC,
} from "../../../../../types/folder/expertisesType";
import { TbaseReducer } from "../../../../../types/reducer/reducerType";

type Props = {
  currentExpertise: number;
  currentchoc: number;
  handleOnChange: (key: keyof TCostingChoc, item: any) => void;
  addNewRow: (newData: any, key: TCostingChocKey) => void;
  deleteRow: (key: Exclude<TCostingChocKey, "general">, id: number) => void;
};

const DetailsSrgcTable = ({
  currentExpertise,
  currentchoc,
  handleOnChange,
  addNewRow,
  deleteRow,
}: Props) => {
  const detailsSrgc = useSelector(
    (state: TbaseReducer) =>
      state.folderReducer.expertises[currentExpertise].chocs[currentchoc]
        .detailsSRGC
  );
  return (
    <Table
      title="Détail des SRGC"
      handleAddRow={(newData) => addNewRow(newData, "detailsSRGC")}
      data={detailsSrgc}
      columns={srgcColumns((item) => {
        handleOnChange("detailsSRGC", item);
      }, deleteRow)}
      context={undefined}
      bottomAddRow
    />
  );
};

export default DetailsSrgcTable;

/** COLUMNS */
const srgcColumns: (
  onChange: ColumnsOnChange,
  onDelete: (key: Exclude<TCostingChocKey, "general">, id: number) => void
) => tableColumnType<TDetailsSRGC>[] = (onChange, onDelete) => [
  {
    key: "libelle",
    label: "Libelllé",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.libelle}
        onChange={(e) => {
          item.libelle = e.target.value;
          onChange(item);
        }}
      />
    ),
    isInput: true,
  },
  {
    key: "code",
    label: "Code",
    isInput: true,
    component: (item) => {
      return <>{item.code}</>;
    },
  },
  {
    key: "priceUnit",
    label: "Prix unitaire",
    isInput: true,
    type: "number",
    component: (item) => {
      return (
        <InputText
          placeholder={""}
          value={item.priceUnit}
          onChange={(e) => {
            if (!isNaN(Number(e.target.value))) {
              item.priceUnit = Number(e.target.value);
              onChange(item);
            }
          }}
        />
      );
    },
  },
  {
    key: "disrepair",
    label: "% vestusté",
    isInput: true,
    type: "number",
    component: (item) => {
      return (
        <InputText
          placeholder={""}
          value={item.disrepair}
          onChange={(e) => {
            if (!isNaN(Number(e.target.value))) {
              item.disrepair = Number(e.target.value);
              onChange(item);
            }
          }}
        />
      );
    },
  },
  {
    key: "discount",
    label: "% Remise",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.discount}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.discount = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "TVA",
    label: "% TVA",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.TVA}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.TVA = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "amountHT",
    label: "Montant HT",
    component: (item) => {
      const disrepairAmount =
        item.priceUnit - item.priceUnit * (item.disrepair / 100);
      const discountAmount =
        disrepairAmount - disrepairAmount * (item.discount / 100);
      item.amountHT = discountAmount;

      return <>{Number(discountAmount.toFixed(2))}</>;
    },
  },
  {
    key: "amountTTC",
    label: "Montant TTC",
    component: (item) => {
      return (
        <>{(item.amountHT + (item.amountHT * item.TVA) / 100).toFixed(2)} €</>
      );
    },
  },
  {
    key: "action",
    label: "Action",
    component: (item) => (
      <Flex justify="end">
        <Icon
          icon={"Trash"}
          size={20}
          infoText="Supprimer"
          infoTop
          backgroundHover
          onClick={() => onDelete("detailsSRGC", item.id)}
        />
      </Flex>
    ),
  },
];
