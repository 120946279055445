import { Card, InfoCard, Grid, Flex } from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";

import { getTypeUser } from "../../../utils/general";

import type { TDataFile } from "../../../types/folder/folderType";

type Props = {
  dataFile: TDataFile;
};

function MissionIntervener({ dataFile }: Props) {
  const cardsEdit = [
    {
      title: "Mission",
      name: "mission",
      inputs: [
        {
          title: "Date mission",
          name: "date",
        },
        {
          title: "Numéro mission",
          name: "number",
        },
        {
          title: "Numéro de police",
          name: "police_number",
        },
        {
          title: "Règlement direct réparateur",
          name: "repairer_direct_settlement",
        },
        {
          title: "Observations",
          name: "comment",
        },
      ],
    },
    {
      title: "Sinistre",
      name: "sinister",
      inputs: [
        {
          title: "Numéro sinistre",
          name: "number",
        },
        {
          title: "Date sinistre",
          name: "date",
        },
        {
          title: "Nature évènement",
          name: "nature_event",
        },
        {
          title: "Choc déclaré",
          name: "declared_shock",
        },
      ],
    },
    {
      title: "Rendez-vous",
      name: "appointment",
      inputs: [
        {
          title: "Lieu",
          name: "location",
        },
        {
          title: "Date",
          name: "date",
        },
        {
          title: "Heure",
          name: "hour",
        },
        {
          title: "Ville",
          name: "city",
        },
        {
          title: "Code Postal",
          name: "zip_code",
        },
        {
          title: "Adresse 1",
          name: "address1",
        },
        {
          title: "Adresse 2",
          name: "address2",
        },
      ],
    },
    {
      title: "Véhicule",
      name: "vehicle",
      inputs: [
        {
          title: "Marque",
          name: "brand",
        },
        {
          title: "Modèle",
          name: "model",
        },
        {
          title: "Immatriculaton",
          name: "license_plate",
        },
        {
          title: "1ère mise en circuation",
          name: "first_registration",
        },
        {
          title: "VIN",
          name: "VIN",
        },
      ],
    },
  ];

  const getValue = (
    dataFile: Record<string, any>,
    cardName?: string,
    inputName?: string
  ) => {
    let value = "N.C.";

    if (cardName && inputName === "date") {
      return (
        <b>
          {moment(dataFile[cardName][inputName], "YYYYMMDD").format(
            "DD/MM/YYYY"
          )}
        </b>
      );
    }

    if (
      cardName &&
      inputName &&
      dataFile[cardName] &&
      dataFile[cardName][inputName]
    ) {
      if (inputName === "comment") {
        return dataFile[cardName][inputName];
      } else {
        return <b>{dataFile[cardName][inputName]}</b>;
      }
    }

    return value;
  };

  return (
    <Flex
      display="flex"
      gap={20}
      direction="column"
      styles={{ paddingBottom: 60 }}
    >
      <Grid display="grid" columnNumber={4} gap={20}>
        {cardsEdit.map((card, idx) => {
          return (
            <InfoCard key={idx} title={card.title} style={{ height: "100%" }}>
              <>
                {card.inputs.map((input, keyInput) => (
                  <p className="m-0 mb-5" key={keyInput}>
                    {input.title} : {getValue(dataFile, card.name, input.name)}
                  </p>
                ))}
              </>
            </InfoCard>
          );
        })}
      </Grid>
      <div>
        <Card title="Identification des parties">
          <>
            <div>
              <h3>Lésé</h3>

              <Grid display="grid" columnNumber={4} gap={10}>
                {dataFile &&
                  dataFile.parties[0].users.map((user, idx) => {
                    return (
                      <InfoCard
                        key={idx}
                        tagAction
                        tagText={getTypeUser(user.type)}
                        color="blue"
                        title={user.firstname}
                        // actionButtonChildren={
                        // <Icon disabled icon={<PenIcon size={20} />} />
                        // }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                          }}
                        >
                          {user.address1 && <div>{user.address1}</div>}
                          {user.address2 && <div>{user.address2}</div>}
                          {user.name && <div>{user.name}</div>}
                          {user.city && <div>{user.city}</div>}
                          {user.phone && <div>{user.phone}</div>}
                          {user.email && <div>{user.email}</div>}
                        </div>
                      </InfoCard>
                    );
                  })}
              </Grid>
            </div>
            <div>
              <h3>Adverse1</h3>
              <Grid display="grid" columnNumber={4} gap={10}>
                <InfoCard
                  tagAction
                  tagText="Adverse"
                  color="red"
                  title={"Titre"}
                  // actionButtonChildren={
                  // <Icon disabled icon={<PenIcon size={20} />} />
                  // }
                >
                  <div>test</div>
                </InfoCard>
                <></>
              </Grid>
            </div>
            <div>
              <h3>Aucune</h3>
              <Grid display="grid" columnNumber={4} gap={10}>
                <InfoCard
                  tagAction
                  tagText="Dépositaire"
                  color="green"
                  title={"Titre"}
                  // actionButtonChildren={
                  // <Icon disabled icon={<PenIcon size={20} />} />
                  // }
                >
                  <div>test</div>
                </InfoCard>
                <InfoCard
                  tagAction
                  tagText="Récupèrateur"
                  color="green"
                  title={"Titre"}
                  // actionButtonChildren={
                  // <Icon disabled icon={<PenIcon size={20} />} />
                  // }
                >
                  <div>test</div>
                </InfoCard>
              </Grid>
            </div>
            {/* <div style={{ margin: "30px 0 15px 0" }}>
              <Button text={"Ajouter un intervenant"} />
            </div> */}
          </>
        </Card>
      </div>
    </Flex>
  );
}
export default MissionIntervener;
