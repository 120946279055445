import React from "react";

import DocumentEditor from "./DocumentEditor";
import ImageEditor from "./ImageEditor";

interface EditorProps {
  token: string;
  files: Array<any>;
  path: string;
  setActiveEdit: Function;
  onClose: Function;
}

function Editor({ token, files, path, setActiveEdit, onClose }: EditorProps) {
  let current = 0;

  files.forEach((file: any, idx) => {
    if (file.token === token) {
      current = idx;
    }
  });

  const { extension, filename, type } = files[current];

  if (["jpg", "jpeg", "png"].includes(extension)) {
    return (
      <ImageEditor
        onBack={() => setActiveEdit(files[current - 1].token)}
        onNext={() => setActiveEdit(files[current + 1].token)}
        onClickImageList={(id: number) => setActiveEdit(files[id].token)}
        setActiveEdit={setActiveEdit}
        current={current}
        length={files.length}
        files={files}
        path={path}
        filename={filename}
        type={type}
        token={token}
        extension={extension}
        onClose={onClose}
      />
    );
  } else {
    return (
      <DocumentEditor
        onBack={() => setActiveEdit(files[current - 1].token)}
        onNext={() => setActiveEdit(files[current + 1].token)}
        current={current}
        length={files.length}
        path={path}
        filename={filename}
        type={type}
        extension={extension}
        token={token}
        onClose={onClose}
      />
    );
  }
}

export default Editor;
