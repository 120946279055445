import { StaticAction } from "../actions/staticAction";
import { TstaticInitialState } from "../types/static/staticType";

export const initialState = {
  isload: false,
  ixperienceMessageType: [],
  userList: [],
  statusHome: [],
} satisfies TstaticInitialState;

export const staticReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case StaticAction.GET_STATIC_STATE:
      return {
        ...state,
        isLoad: true,
        ixperienceMessageType: action.messagesType,
        userList: action.userList,
        statusHome: action.statusHome,
      };
    default:
      return state;
  }
};
