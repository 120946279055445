import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { searchReducer } from "./searchReducer";
import { folderReducer } from "./folderReducer";
import { libraryReducer } from "./libraryReducer";
import { tasksReducer } from "./tasksReducer";
import { modalReducer } from "./modalReducer";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { loaderReducer } from "./loaderReducer";
import { staticReducer } from "./staticReducer";

const config = {
  authReducer,
  searchReducer,
  folderReducer,
  libraryReducer,
  tasksReducer,
  modalReducer,
  loaderReducer,
  staticReducer,
};
const appReducer = combineReducers(config);

export const useAppDispatch: () => AppDispatch = useDispatch;

export default appReducer;
