/* Libs */
import {
  Flex,
  Icon,
  InputSelect,
  InputText,
  Table,
  tableColumnType,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Types */
import {
  ColumnsOnChange,
  TCostingChoc,
  TCostingChocKey,
  TdetailsOperation,
} from "../../../../../types/folder/expertisesType";
import { TbaseReducer } from "../../../../../types/reducer/reducerType";

/* Utils */
import { getTotalValue } from "../../../../../utils/costing/indesx";

type Props = {
  currentExpertise: number;
  currentchoc: number;
  handleOnChange: (key: keyof TCostingChoc, item: any) => void;
  addNewRow: (newData: any, key: TCostingChocKey) => void;
  deleteRow: (key: Exclude<TCostingChocKey, "general">, id: number) => void;
};

const DetailsOperationTable = ({
  handleOnChange,
  addNewRow,
  currentExpertise,
  currentchoc,
  deleteRow,
}: Props) => {
  const detailsOperation = useSelector(
    (state: TbaseReducer) =>
      state.folderReducer.expertises[currentExpertise].chocs[currentchoc]
        .detailsOperation
  );
  const totalHours = getTotalValue(detailsOperation);
  const totalHoursVAT = getTotalValue(detailsOperation, true);

  return (
    <Table
      title="Détail des opérations"
      handleAddRow={(newData) => addNewRow(newData, "detailsOperation")}
      data={detailsOperation}
      columns={operationColumns((item) => {
        handleOnChange("detailsOperation", item);
      }, deleteRow)}
      context={undefined}
      lastRowColumns={lastRowColumns}
      lastRowData={{
        amountHT: totalHours,
        amountTTC: totalHoursVAT,
      }}
      bottomAddRow
      headerComponent={
        <Flex
          styles={{
            borderTop: "1px solid var(--light-grey)",
            borderBottom: "1px solid var(--light-grey)",
            borderLeft: "1px solid var(--light-grey)",
            borderRadius: "5px",
          }}
        >
          <Flex
            classNames="disable"
            align="center"
            justify="center"
            styles={{
              borderRight: "1px solid var(--light-grey)",
              height: "100%",
              padding: "10px 20px",
            }}
          >
            Historique des tarifs
          </Flex>
          <Flex
            classNames="disable"
            align="center"
            justify="center"
            styles={{
              borderRight: "1px solid var(--light-grey)",
              height: "100%",
              padding: "10px 20px",
            }}
          >
            Tarifs en cours
          </Flex>
          <Flex
            classNames="disable"
            align="center"
            justify="center"
            styles={{
              borderRight: "1px solid var(--light-grey)",
              height: "100%",
              padding: "10px 20px",
            }}
          >
            Tarifs compagnie
          </Flex>
          <Flex
            classNames="disable"
            align="center"
            justify="center"
            styles={{
              borderRight: "1px solid var(--light-grey)",
              height: "100%",
              padding: "10px 20px",
            }}
          >
            Tarifs autres réparateurs
          </Flex>
        </Flex>
      }
    />
  );
};

export default DetailsOperationTable;

/** SELECT DATA */
const selectTypeData = [
  { text: "T1", value: "T1" },
  { text: "T2", value: "T2" },
  { text: "T3", value: "T3" },
  { text: "M1", value: "M1" },
  { text: "M2", value: "M2" },
  { text: "M3", value: "M3" },
  { text: "P1", value: "P1" },
  { text: "P2", value: "P2" },
  { text: "DSP", value: "DSP" },
  { text: "Ing. 1", value: "Ing. 1" },
  { text: "Ing. 2", value: "Ing. 2" },
];

/** COLUMNS */
const operationColumns: (
  onChange: ColumnsOnChange,
  onDelete: (key: Exclude<TCostingChocKey, "general">, id: number) => void
) => tableColumnType<TdetailsOperation>[] = (onChange, onDelete) => [
  {
    key: "type",
    label: "Type",
    isSelect: true,
    selectData: selectTypeData,
    component: (item) => {
      return (
        <InputSelect
          options={selectTypeData}
          value={item.type}
          onChange={(value) => {
            item.type = value;
            onChange(item);
          }}
          placeholder={""}
        />
      );
    },
  },
  {
    key: "subType",
    label: "Sous-type",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.subType}
        onChange={(e) => {
          item.subType = e.target.value;
          onChange(item);
        }}
      />
    ),
    isInput: true,
  },
  {
    key: "nbe",
    label: "Heure",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.nbe}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.nbe = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "priceHT",
    label: "Tarifs horaire",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.priceHT}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.priceHT = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "TVA",
    label: "% TVA",
    isInput: true,
    type: "number",
    component: (item) => (
      <InputText
        placeholder={""}
        value={item.TVA}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            item.TVA = Number(e.target.value);
            onChange(item);
          }
        }}
      />
    ),
  },
  {
    key: "amountHT",
    label: "Montant HT",
    component: (item) => {
      const amountHT = Number((item.nbe * item.priceHT).toFixed(2));
      return <>{amountHT} €</>;
    },
  },
  {
    key: "amountTTC",
    label: "Montant TTC",
    component: (item) => {
      const priceHT = item.nbe * item.priceHT;
      const amountTTC = Number(
        (priceHT + (priceHT * item.TVA) / 100).toFixed(2)
      );

      return <>{amountTTC} €</>;
    },
  },
  {
    key: "action",
    label: "Action",
    component: (item) => (
      <Flex justify="end">
        <Icon
          icon={"Trash"}
          size={20}
          infoText="Supprimer"
          infoTop
          backgroundHover
          onClick={() => onDelete("detailsOperation", item.id)}
        />
      </Flex>
    ),
  },
];

const lastRowColumns: tableColumnType<TdetailsOperation>[] = [
  { key: "libelle", label: "", colSpan: 5 },
  {
    key: "amountHT",
    label: "",
    component: (item) => (
      <div
        style={{
          color: "white",
          background: "var(--primary-bg-color)",
          padding: 5,
          borderRadius: "5px",
        }}
      >
        {item.amountHT} €
      </div>
    ),
  },
  {
    key: "amountTTC",
    label: "",
    component: (item) => (
      <div
        style={{
          color: "white",
          background: "var(--primary-bg-color)",
          padding: 5,
          borderRadius: "5px",
        }}
      >
        {item.amountTTC} €
      </div>
    ),
  },
  {
    key: "action",
    label: "",
  },
];
