/* Libs */
import { Loader, Modal } from "@sahadevia/ui-kit-modern-sahadevia";

type Props = {};

const ModalLoader = ({}: Props) => {
  return (
    <Modal target="root">
      <Loader />
    </Modal>
  );
};

export default ModalLoader;
