import { TResultBase } from "../types/app/appType";
import { _fetch } from "../utils/fetch";

export enum SearchAction {
  COUNT_FOLDERS = "COUNT_FOLDERS",
  GET_FOLDERS = "GET_FOLDERS",
  SEARCH_FOLDERS = "SEARCH_FOLDERS",
  GET_OPTIONS = "GET_OPTIONS",
  LOAD_SEARCH = "LOAD_SEARCH",
  CHANGE_ACTIVE_PAGE = "CHANGE_ACTIVE_PAGE",
  UPDATE_ORDER = "UPDATE_ORDER",
  UPDATE_FILTERS = "UPDATE_FILTERS",
  SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS",
}

export const countFolders = (activeFilters: any) => {
  return (dispatch: any) => {
    _fetch(
      "/api/search/count",
      SearchAction.COUNT_FOLDERS,
      JSON.stringify({ filters: activeFilters }),
      dispatch
    );
  };
};

export const getFolders = (
  filters: any,
  value: number | null,
  offset: number,
  orderName: string,
  orderDesc: boolean
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/search/pagination",
      SearchAction.GET_FOLDERS,
      JSON.stringify({ filters, value, offset, orderName, orderDesc }),
      dispatch
    );
  };
};

export const searchFolders = (
  reference: string,
  callback?: (result: TResultBase) => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/search/search-folders",
      SearchAction.SEARCH_FOLDERS,
      JSON.stringify({ reference }),
      dispatch,
      callback,
      SearchAction.SEARCH_FOLDERS,
      true
    );
  };
};

export const resetSearchFolders = () => {
  return (dispatch: any) => {
    dispatch({ type: SearchAction.SEARCH_FOLDERS, data: [] });
  };
};

export const getOptions = () => {
  return (dispatch: any) => {
    _fetch("/api/search/options", SearchAction.GET_OPTIONS, "", dispatch);
  };
};
