import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import getUrl from "../../../utils/getUrl";
import {
  Button,
  Flex,
  Grid,
  InputCheckbox,
} from "@sahadevia/ui-kit-modern-sahadevia";

import type { TFile } from "../../../types/folder/folderType";

import Editor from "./Editor";
import ImageCard from "./ImageCard";
import ButtonList from "./ButtonList";
import { TbaseReducer } from "../../../types/reducer/reducerType";

export const PPRESELECTIONS = ["AO", "RI", "AU"];

type Props = {
  handleGedImageList: (idxArray: string[]) => void;
};

function ImageList({ handleGedImageList }: Props) {
  const { dataFile, gedImageList } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  const [activeEdit, setActiveEdit] = useState<string | null>(null);

  const [checkAO, setCheckAO] = useState(false);
  const [checkRI, setCheckRI] = useState(false);
  const [checkAU, setCheckAU] = useState(false);

  const { id } = useParams();

  const files = dataFile.files.filter((file: any) =>
    ["jpg", "jpeg", "png"].includes(file.extension.toLowerCase())
  );

  const activeLinesFilter = (preselect = "", isReverse = false) => {
    let tmpToken: string[] = [];

    // Deselect all
    if (!preselect && isReverse) {
      tmpToken = [];
    }
    // Select all
    else if (!preselect && !isReverse) {
      tmpToken = files
        .map((file) => file.token)
        .filter((token) => !tmpToken.includes(token));
    } else {
      files.forEach((file) => {
        if (
          file.preselect?.includes(preselect) &&
          !tmpToken.includes(file.token)
        ) {
          if (isReverse) {
            tmpToken = tmpToken.filter((token) => token !== file.token);
          } else {
            tmpToken.length
              ? (tmpToken = [...tmpToken, file.token])
              : tmpToken.push(file.token);
          }
        }
      });
    }

    handleGedImageList(tmpToken);
  };

  return (
    <>
      {!activeEdit && (
        <Flex display="flex" direction="column" gap={10}>
          <Flex display="flex">
            {files.length > 0 && (
              <Flex display="flex" gap={10} classNames="mt-auto mb-auto">
                <Button
                  text={
                    gedImageList.length === files.length
                      ? "Tous déselectionner"
                      : "Tous sélectionner"
                  }
                  outline
                  onClick={() =>
                    activeLinesFilter("", gedImageList.length === files.length)
                  }
                />
                <InputCheckbox
                  text={"Sélectionner AO"}
                  isCheck={checkAO}
                  onClick={() => null}
                  onChange={(isCheck) => {
                    setCheckAO(isCheck);
                    activeLinesFilter("AO", !isCheck);
                  }}
                />
                <InputCheckbox
                  text={"Sélectionner RI"}
                  isCheck={checkRI}
                  onClick={() => null}
                  onChange={(isCheck) => {
                    setCheckRI(isCheck);
                    activeLinesFilter("RI", !isCheck);
                  }}
                />
                <InputCheckbox
                  text={"Sélectionner AU"}
                  isCheck={checkAU}
                  onClick={() => null}
                  onChange={(isCheck) => {
                    setCheckAU(isCheck);
                    activeLinesFilter("AU", !isCheck);
                  }}
                />
              </Flex>
            )}
            <ButtonList
              activeLines={gedImageList}
              files={files}
              resetActiveLines={() => handleGedImageList([])}
              isPreselect={true}
            />
          </Flex>
          <Grid display="grid" columnNumber={6} gap={17} justify="center">
            {files &&
              files.map((file: TFile, idx: number) => {
                if (
                  (!checkAO && !checkRI && !checkAU) ||
                  gedImageList.includes(file.token) ||
                  (checkAO && file.preselect?.includes("AO")) ||
                  (checkRI && file.preselect?.includes("RI")) ||
                  (checkAU && file.preselect?.includes("AU"))
                ) {
                  return (
                    <ImageCard
                      key={file.token}
                      file={file}
                      handleGedImageList={handleGedImageList}
                      idx={idx}
                      onClick={() => {
                        setActiveEdit(file.token);
                      }}
                    />
                  );
                }
                return null;
              })}
          </Grid>
        </Flex>
      )}
      {activeEdit ? (
        <Editor
          setActiveEdit={(token: string) => setActiveEdit(token)}
          token={activeEdit}
          files={files}
          path={getUrl(id, activeEdit, 1920)}
          onClose={() => setActiveEdit(null)}
        />
      ) : null}
    </>
  );
}

export default ImageList;
