/* Libs */
import {
  Card,
  Flex,
  Grid,
  Icon,
  InfoCard,
  InputCheckbox,
  InputDate,
  InputText,
  Pagination,
  StatusColor,
  Table,
  tableColumnType,
} from "@sahadevia/ui-kit-modern-sahadevia";
import GoogleMapReact from "./GoogleMapReact";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";

/* Reducers */
import { TbaseReducer } from "../../../types/reducer/reducerType";
import { useAppDispatch } from "../../../reducers";

/* Types */
import {
  TAvailabilityAgency,
  TMapDataAgencies,
  TMapDataCustomers,
  TMapDataGarages,
  Tparties,
} from "../../../types/folder/folderType";

/* Actions */
import { getFolders } from "../../../actions/searchAction";
import {
  getAllAgency,
  getAvailabilityAgency,
  updateQuestionList,
} from "../../../actions/folderAction";

/* Configs */
import { PAGE_LENGTH } from "../../../configs/pagination";

/* Utils */
import { getDate } from "../../../utils/general";

const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];

const FolderPreparation = () => {
  const dispatch = useAppDispatch();
  const [activePage, setActivePage] = useState(1);
  const {
    dataFile,
    dateAppointmennt,
    availabilityAgency,
    nameAgency,
    agencyId,
    allAgency,
    dataMaileva
  } = useSelector((state: TbaseReducer) => state.folderReducer);
  const { items } = useSelector((state: TbaseReducer) => state.searchReducer);
  const repairman = dataFile.parties[0].users.find(
    (user) => user.type === "repairman"
  );
  const insuredInfo = dataFile.parties[0].users.find(
    (user) => user.type === "insured"
  );
  const vehicleInfo = dataFile.vehicle;

  const garages = allAgency.garages || [];
  const agencies = allAgency.agencies || [];
  const customers = allAgency.customers || [];

  const itemsTable = items.map((item) => ({
    ...item,
    dateAppointmennt: getDate(item.dateAppointmennt),
  }));

  useEffect(() => {
    dispatch(getAvailabilityAgency({ agencyId, nameAgency }));
    dispatch(getAllAgency());
    _getFolders();
  }, []);

  const _getFolders = () => {
    dispatch(
      getFolders(
        { nameAgency: [agencyId], folderPerWeek: true },
        PAGE_LENGTH,
        (activePage - 1) * PAGE_LENGTH,
        "",
        true
      )
    );
  };

  const availabilityColumns: tableColumnType<TAvailabilityAgency>[] =
    availabilityAgency.length
      ? [
          {
            key: "nameAgency",
            label: "Agence",
          },
          ...availabilityAgency[0].dates.map((day, idx) => ({
            key: day.day,
            label: day.label,
            component: (item: Record<string, any>) => {
              const { dates } = item;
              const percentage = (dates[idx].count / dates[idx].total) * 100;
              return (
                <Flex display="flex" gap={10}>
                  {`${dates[idx].count}/${dates[idx].total}`}
                  <StatusColor
                    color={
                      percentage >= 100
                        ? "red"
                        : percentage >= 20
                        ? "orange"
                        : "green"
                    }
                  />
                </Flex>
              );
            },
          })),
          {
            key: "time",
            label: "RDV Agence",
            component: (item: Record<string, any>) => {
              const { time } = item;
              return (
                <Flex display="flex">
                  {(time.type || 0) === 0 ? (
                    <>
                      {time.startAM ? (
                        <span>
                          Matin - {time.startAM} - {time.endAM}
                        </span>
                      ) : (
                        ""
                      )}
                      <br />
                      {time.startPM ? (
                        <span>
                          Après-midi - {time.startPM} - {time.endPM}
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {days.map((day, idx) => (
                        <span className="d-block" key={idx}>
                          {day} - {time[`startAM_${idx}`]} -{" "}
                          {time[`endAM_${idx}`]} |<br />{" "}
                          {time[`startPM_${idx}`]} - {time[`endPM_${idx}`]}
                        </span>
                      ))}
                    </>
                  )}
                </Flex>
              );
            },
          },
        ]
      : [];

  const columns = [
    {
      key: "numberFolder",
      sortKey: "numberFolder",
      label: "Référence",
      canBeSorted: true,
    },
    {
      key: "nameAgency",
      sortKey: "nameAgency",
      label: "Agence",
      canBeSorted: true,
    },
    {
      key: "nameExpert",
      sortKey: "nameExpert",
      label: "Expert",
      canBeSorted: true,
    },
    {
      key: "natureExpertise",
      sortKey: "natureExpertise",
      label: "Nature d'expertise",
      canBeSorted: true,
    },
    {
      key: "dateAppointmennt",
      sortKey: "dateAppointmennt",
      label: "Date de RDV",
      canBeSorted: true,
    },
    {
      key: "namePrincipal",
      sortKey: "namePrincipal",
      label: "Lieu de visite",
      canBeSorted: true,
    },
    {
      key: "nameRepairman",
      sortKey: "nameRepairman",
      label: "Garage",
      canBeSorted: true,
    },
  ];

  // reformats the array to display the list of agencies
  const reformatedAgenciesList = () => {
    return [...garages, ...agencies, ...customers].reduce(
      (
        uniqueArray: (TMapDataAgencies | TMapDataCustomers | TMapDataGarages)[],
        currentItem
      ) => {
        const isDuplicate = uniqueArray.some(
          (obj) => obj.agencyName === currentItem.agencyName
        );
        if (!isDuplicate) {
          uniqueArray.push(currentItem);
        }
        return uniqueArray;
      },
      []
    );
  };

  return (
    <Flex
      display="flex"
      direction="column"
      gap={20}
      styles={{ paddingBottom: 60 }}
    >
      <Grid display="grid" columnNumber={2} gap={10}>
        <Card title="Appel client" classNameChildren="h-100">
          <Flex display="flex" gap={10} classNames="h-100">
            <QuestionList insuredInfo={{ ...insuredInfo, ...vehicleInfo }} />
          </Flex>
        </Card>
        <Card title="Rendez-vous">
          <Flex display="flex" gap={10} direction="column">
            <Flex display="flex" gap={10}>
              <InputDate label="Date d'expertise" value={dateAppointmennt} />
              <InputDate label="Heure" />
            </Flex>

            <Flex display="flex" direction="column">
              <h3>Lieu</h3>
              <Grid display="grid" columnNumber={2} gap={10}>
                <>
                  {repairman && (
                    <InfoCard
                      tagAction
                      color="blue"
                      tagText="Réparateur"
                      title={repairman.name}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 10,
                        }}
                      >
                        <div>{repairman.address1}</div>
                        <div>{repairman.address2}</div>
                        <div>{repairman.name}</div>
                        <div>{repairman.city}</div>
                        <div>{repairman.phone}</div>
                        <div>{repairman.email}</div>
                      </div>
                    </InfoCard>
                  )}
                  <InfoCard title={""}>
                    <Flex display="flex" direction="column" gap={10}>
                      <div style={{ color: "var(--light-grey)" }}>
                        DISTANCE RÉPARATEUR - CABINET :{" "}
                      </div>
                      <div>35 KM</div>
                      <div style={{ color: "var(--light-grey)" }}>
                        JOURS DE PASSAGE :
                      </div>
                      <div>LUNDI MARDI MERCREDI</div>
                    </Flex>
                  </InfoCard>
                </>
              </Grid>
            </Flex>
          </Flex>
        </Card>
      </Grid>

      <Table
        title="Liste des courriers"
        data={dataMaileva}
        columns={[
          {
            key: "dateSend",
            label: "Date d'envoi",
          },
          {
            key: "statusText",
            label: "Statut",
          },
          {
            key: "type",
            label: "Type d'envoi",
          },
        ]}
        context={undefined}
      />

      <Table
        title="Disponibilité agence"
        data={availabilityAgency}
        columns={availabilityColumns}
        context={undefined}
      />

      <Card title="Carte des tournées">
        <Flex display="flex" gap={10}>
          <GoogleMapReact />
          <Card>
            <Flex
              display="flex"
              direction="column"
              gap={10}
              styles={{ maxHeight: "450px", overflow: "auto" }}
            >
              <h3>Légende</h3>
              <Flex display="flex" align="center" gap={5}>
                <Icon icon={"User"} size={20} /> Client
              </Flex>
              <Flex display="flex" align="center" gap={5}>
                <Icon icon={"House"} size={20} /> Agence
              </Flex>
              <Flex display="flex" align="center" gap={5}>
                <Icon icon={"Marker"} size={20} /> Garage
              </Flex>
              {reformatedAgenciesList().map((agency, idx) => (
                <Flex display="flex" align="center" gap={5} key={idx}>
                  <Icon icon={"Marker"} size={20} color={agency.agencyColor} />
                  {agency.agencyName}
                </Flex>
              ))}
            </Flex>
          </Card>
        </Flex>
      </Card>

      <Table
        title="Liste des tournées"
        data={itemsTable}
        columns={columns}
        context={undefined}
        currentPage={activePage}
        itemsPerPage={PAGE_LENGTH}
        footerComponent={
          <Flex display="flex" justify="end" styles={{ padding: "16px" }}>
            <Pagination
              totalItems={itemsTable.length}
              itemsPerPage={PAGE_LENGTH}
              onPageChange={(page) => setActivePage(page)}
            />
          </Flex>
        }
      />
    </Flex>
  );
};

export default FolderPreparation;

/* PRIVATE COMPONENTS */

type QuestionListProps = {
  insuredInfo: Tparties & { license_plate: string };
};

const QuestionList = ({ insuredInfo }: QuestionListProps) => {
  const dispatch = useAppDispatch();
  const { phone, email, license_plate } = insuredInfo;
  const { questionList, folderId } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [questions, setQuestions] = useState([
    {
      id: 1,
      text: `Votre numéro de téléphone est bien le`,
      userInfo: phone,
      isCheckedYes: false,
      isCheckedNo: false,
    },
    {
      id: 2,
      text: `Votre adresse e-mail est bien`,
      userInfo: email,
      isCheckedYes: false,
      isCheckedNo: false,
    },
    {
      id: 3,
      text: `L’immatriculation de votre véhicule est bien`,
      userInfo: license_plate,
      isCheckedYes: false,
      isCheckedNo: false,
    },
  ]);

  const questionsPerPage = 3;
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const handleCheckboxChange = (
    questionId: number,
    isYes: boolean,
    value: boolean
  ) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === questionId
          ? isYes
            ? { ...question, isCheckedYes: value, isCheckedNo: !value }
            : { ...question, isCheckedNo: value, isCheckedYes: !value }
          : question
      )
    );
  };

  const handleUserValueChange = (questionId: number, value: string) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === questionId ? { ...question, userInfo: value } : question
      )
    );
  };

  const handleUpdateQuestionList = (questionId: number, value: boolean) => {
    dispatch(
      updateQuestionList({
        folderId,
        questionId,
        value,
        questionList,
      })
    );
  };

  return (
    <Flex
      display="flex"
      direction="column"
      classNames="w-100 h-100"
      justify="space-between"
    >
      {currentQuestions.map((question) => {
        const { userInfo, isCheckedNo, isCheckedYes, id, text } = question;

        return (
          <Fragment key={id}>
            {!!userInfo && (
              <div>
                {isCheckedNo ? (
                  <Flex display="flex" align="center" gap={10}>
                    {text}
                    <InputText
                      onChange={(e) =>
                        handleUserValueChange(id, e.target.value)
                      }
                      placeholder={userInfo}
                      value={userInfo}
                      iconRight={{
                        name: "Check",
                        size: 10,
                        onClick: () => {
                          handleCheckboxChange(id, false, false);
                          handleUpdateQuestionList(id, true);
                        },
                      }}
                    />
                  </Flex>
                ) : (
                  <p key={id}>
                    {text} {userInfo} ?
                  </p>
                )}
                <Flex display="flex" direction="column" gap={10}>
                  <InputCheckbox
                    text={"OUI"}
                    isCheck={
                      !questionList
                        ? isCheckedYes
                        : questionList.hasOwnProperty(id) && questionList[id]
                    }
                    onClick={() => handleUpdateQuestionList(id, true)}
                    onChange={(e) => handleCheckboxChange(id, true, e)}
                  />
                  <InputCheckbox
                    text={"NON"}
                    isCheck={
                      !questionList
                        ? isCheckedNo
                        : questionList.hasOwnProperty(id) && !questionList[id]
                    }
                    onClick={() => handleUpdateQuestionList(id, false)}
                    onChange={(e) => handleCheckboxChange(id, false, e)}
                  />
                </Flex>
              </div>
            )}
          </Fragment>
        );
      })}

      <Flex display="flex" justify="center">
        <Pagination
          totalItems={currentQuestions.length}
          itemsPerPage={3}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Flex>
    </Flex>
  );
};
