/* Libs */
import { Button, Flex } from "@sahadevia/ui-kit-modern-sahadevia";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Components */
import ModalLayout from "../../layouts/ModalLayout";

/* Actions */
import { ModalAction } from "../../actions/modalAction";

type Props = {
  title?: string;
  message: string;
  onConfirm?: () => void;
};

const ModalConfirm = ({ title, message, onConfirm }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <ModalLayout>
      <>
        {title ? <h4 style={{ fontSize: 14, margin: 0 }}>{title}</h4> : null}
        <p style={{ fontSize: 14, margin: 0 }}>{message}</p>
        <Flex display="flex" justify="left" gap={10}>
          <Button
            onClick={() => {
              dispatch({ type: ModalAction.CLOSE_CONFIRM_MODAL });
            }}
            text={"NON"}
            outline
          />
          <Button
            onClick={() => {
              if (onConfirm) {
                onConfirm();
                dispatch({ type: ModalAction.CLOSE_CONFIRM_MODAL });
              }
            }}
            text={"OUI"}
          />
        </Flex>
      </>
    </ModalLayout>
  );
};

export default ModalConfirm;
