/* Libs */
import { Icon, StatusColor, Table } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Components */
import ModalLayout from "../../layouts/ModalLayout";

/* Utils */
import { getGradeColor } from "../../utils/getGradeColor";

/* Actions */
import { updateManaging } from "../../actions/folderAction";
import { ModalAction } from "../../actions/modalAction";

/* Types */
import { TbaseReducer } from "../../types/reducer/reducerType";

type Props = {};

const ModalManagingHistory = ({}: Props) => {
  const { id } = useParams();
  const { dataFile } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );
  const { managingHistoryModal } = useSelector(
    (state: TbaseReducer) => state.modalReducer
  );

  const dispatch = useAppDispatch();

  return (
    <ModalLayout>
      <>
        <Table
          customRowStyle={(_, idx) => {
            const isInitialMessage =
              idx ===
              dataFile.managings.find(
                (managing) =>
                  managing.id === managingHistoryModal.currentManagingId
              )?.updatedSubject.length! -
                1;
            let color = "";

            if (isInitialMessage) {
              color = "lightgreen";
            }

            return { background: isInitialMessage ? color : "" };
          }}
          style={{ height: "100%", overflow: "auto" }}
          data={
            dataFile.managings &&
            (dataFile.managings.find(
              (managing) =>
                managing.id === managingHistoryModal.currentManagingId
            )?.updatedSubject ||
              [])
          }
          columns={[
            {
              key: "",
              label: "",
              component: (_, idx) => {
                const isInitialMessage =
                  idx ===
                  dataFile.managings.find(
                    (managing) =>
                      managing.id === managingHistoryModal.currentManagingId
                  )?.updatedSubject.length! -
                    1;
                let color = "";

                if (isInitialMessage) {
                  color = "green";
                }
                return <div style={{ color: color }}>{idx}</div>;
              },
            },
            {
              key: "color",
              label: "",
              component: (item) => (
                <StatusColor color={getGradeColor(item.color)} />
              ),
            },
            { key: "subject", label: "Libellé" },
            { key: "insertDate", label: "Date" },
            {
              key: "actions",
              label: "Actions",
              component: (item) => (
                <Icon
                  backgroundHover
                  infoText="Réinitialiser"
                  icon={"Reload"}
                  size={20}
                  onClick={() => {
                    const currentManaging = dataFile.managings.find(
                      (m) => m.id === item.managingId
                    );
                    if (currentManaging) {
                      dispatch(
                        updateManaging(
                          item.managingId,
                          id,
                          {
                            newColor: item.color,
                            oldColor: currentManaging?.color,
                            newSubject: item.subject,
                            oldSubject: currentManaging?.subject,
                          },
                          () => {
                            dispatch({
                              type: ModalAction.CLOSE_HISTORY_MANAGING_MODAL,
                            });
                          }
                        )
                      );
                    }
                  }}
                />
              ),
            },
          ]}
          context={null}
        />
      </>
    </ModalLayout>
  );
};

export default ModalManagingHistory;
