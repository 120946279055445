/* Libs */
import { Button, Flex } from "@sahadevia/ui-kit-modern-sahadevia";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Actions */
import { ModalAction } from "../../actions/modalAction";

/* Components */
import ModalLayout from "../../layouts/ModalLayout";

type Props = {
  title?: string;
  message: string;
};

const ModalInfo = ({ message, title }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <ModalLayout>
      <>
        {title ? <h4 style={{ fontSize: 14, margin: 0 }}>{title}</h4> : null}
        <p style={{ fontSize: 14, margin: 0 }}>{message}</p>
        <Flex display="flex" justify="left" gap={10}>
          <Button
            onClick={() => {
              dispatch({ type: ModalAction.CLOSE_INFO_MODAL });
            }}
            text={"OK !"}
            outline
          />
        </Flex>
      </>
    </ModalLayout>
  );
};

export default ModalInfo;
