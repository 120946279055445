import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// @ts-ignore
import ImageEditor from "@toast-ui/react-image-editor";
import {
  faClose,
  faSave,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ButtonIcon from "./ButtonIcon";

import "tui-image-editor/dist/tui-image-editor.css";

import { useAppDispatch } from "../../../reducers";

import { insertFiles } from "../../../actions/folderAction";
import { Flex } from "@sahadevia/ui-kit-modern-sahadevia";
import getUrl from "../../../utils/getUrl";

interface ImagesEditorProps {
  path: string;
  filename: string;
  extension: string;
  type: string;
  token: string;
  onBack?: Function;
  onNext?: Function;
  current?: number;
  length?: number;
  onClose: Function;
  onClickImageList: (id: number) => void;
  files: Array<any>;
  setActiveEdit: Function;
}

interface ButtonIconCustom {
  isActive?: boolean;
  disabled?: boolean;
  color?: string;
  icon: IconProp;
  onClick: Function;
}

function ImagesEditor({
  path,
  filename,
  extension,
  type,
  token,
  onBack,
  onNext,
  current,
  length = 0,
  onClose,
  onClickImageList,
  files,
  setActiveEdit,
}: ImagesEditorProps) {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const refImageEditor = useRef<HTMLDivElement>(null);

  const _saveFile = () => {
    if (refImageEditor && refImageEditor.current) {
      dispatch(
        insertFiles(
          id,
          [
            {
              base64:
                // @ts-ignore
                refImageEditor.current.getInstance().toDataURL(),
              extension,
              name: `${filename}-copie`,
              type,
            },
          ],
          () => {
            onClose();
          }
        )
      );
    }
  };

  const ButtonIconCustom = ({
    isActive,
    color,
    icon,
    onClick,
    disabled = false,
  }: ButtonIconCustom) => {
    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          borderRadius: "2px",
          cursor: "pointer",
          margin: "auto",
        }}
      >
        <ButtonIcon
          disabled={disabled}
          className={`${color} ${isActive ? "green" : ""} ml-10 mr-10`}
          onClick={onClick}
          icon={icon}
        />
      </div>
    );
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp" && current !== 0 && onBack) {
      onBack();
    } else if (event.key === "ArrowDown" && current !== length - 1 && onNext) {
      onNext();
    }
  };

  useEffect(() => {
    if (refImageEditor.current) {
      // @ts-ignore
      refImageEditor.current.imageEditorInst.loadImageFromURL(path, filename);
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [current]);

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 500,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          backgroundColor: "black",
        }}
      >
        <Flex
          display="flex"
          direction="column"
          gap={25}
          styles={{ overflowY: "scroll", padding: 20, marginTop: "50px" }}
        >
          {files.map((file) => {
            return (
              <div
                className="cursor-pointer"
                style={{
                  border: `${file.token === token ? "1px solid green" : ""}`,
                  padding: 10,
                }}
                onClick={() => setActiveEdit(file.token)}
              >
                <img
                  loading="lazy"
                  className="w-auto"
                  style={{ minHeight: "100%", maxWidth: "100%" }}
                  src={getUrl(id, file.token, 320, file.timestamp)}
                  alt={`image`}
                />
              </div>
            );
          })}
        </Flex>
        <ImageEditor
          idx={current}
          ref={refImageEditor}
          includeUI={{
            loadImage: {
              path,
              name: filename,
            },
            theme: {},
            menu: ["crop", "flip", "rotate", "draw", "shape", "icon", "text"],
            menuBarPosition: "bottom",
          }}
          cssMaxHeight={window.innerHeight - 100}
          cssMaxWidth={window.innerWidth - 100}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={true}
        />
      </div>

      {onBack && onNext ? (
        <div
          className="pl-10 pr-10 d-flex"
          style={{
            position: "absolute",
            left: "8px",
            top: "8px",
            listStyle: "none",
            margin: "0 auto",
            textAlign: "center",
            verticalAlign: "middle",
            borderRadius: "20px",
            backgroundColor: "rgb(53,53,53)",
            zIndex: "2",
            height: "40px",
          }}
        >
          <ButtonIconCustom
            disabled={current === 0}
            icon={faCaretLeft}
            onClick={onBack}
          />
          <ButtonIconCustom
            disabled={current === length - 1}
            icon={faCaretRight}
            onClick={onNext}
          />
          <p
            className="ml-10 mt-auto mb-auto"
            style={{ color: "var(--white)" }}
          >
            {filename}
          </p>
        </div>
      ) : null}
      <div
        className="pl-10 pr-10 d-flex"
        style={{
          position: "absolute",
          right: "8px",
          top: "8px",
          listStyle: "none",
          margin: "0 auto",
          textAlign: "center",
          verticalAlign: "middle",
          borderRadius: "20px",
          backgroundColor: "rgb(53,53,53)",
          zIndex: "2",
          height: "40px",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "inline-block",
            borderRadius: "2px",
            cursor: "pointer",
            margin: "auto",
          }}
        >
          <ButtonIcon
            className={`green ml-10 mr-10`}
            onClick={() => _saveFile()}
            icon={faSave}
          />
        </div>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            borderRadius: "2px",
            cursor: "pointer",
            margin: "auto",
          }}
        >
          <ButtonIcon
            className="red ml-10 mr-10"
            onClick={() => {
              onClose();
            }}
            icon={faClose}
          />
        </div>
      </div>
    </div>
  );
}

export default ImagesEditor;
