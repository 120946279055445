/* Libs */
import GoogleMap from "google-maps-react-markers";
import { Flex, Icon, IconNameType } from "@sahadevia/ui-kit-modern-sahadevia";
import { CSSProperties, memo, useState } from "react";
import { useSelector } from "react-redux";

/* Reducers */
import { useAppDispatch } from "../../../reducers";

/* Actions */
import { ModalAction } from "../../../actions/modalAction";

/* Types */
import { TbaseReducer } from "../../../types/reducer/reducerType";
import { TMapData } from "../../../types/folder/folderType";

const containerStyle: CSSProperties = {
  width: "90%",
  height: "500px",
  position: "relative",
};

const GoogleMapReact = () => {
  const dispatch = useAppDispatch();
  const { agencyCoordinates, allAgency } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  return (
    <div style={containerStyle} id="google-map">
      <GoogleMap
        apiKey="AIzaSyAu0mZkek1vPk-2o2bmU0WqV0MTdbhvA8I"
        defaultCenter={agencyCoordinates}
        defaultZoom={10}
      >
        {allAgency.customers &&
          allAgency.customers.map((customer, idx) => {
            const { folder, customerLat, customerLng, agencyColor } = customer;
            if (folder.length === 1) {
              return (
                <Marker
                  key={idx}
                  lat={customerLat}
                  lng={customerLng}
                  icon={"User"}
                  iconColor={agencyColor}
                  onClick={() => {
                    dispatch({
                      type: ModalAction.OPEN_CUSTOM_MODAL,
                      data: {
                        target: "google-map",
                        children: <PopUp data={folder} />,
                      },
                    });
                  }}
                />
              );
            } else {
              if (folder.length > 1) {
                return (
                  <MarkerCustom
                    key={idx}
                    lat={customerLat}
                    lng={customerLng}
                    icon={"User"}
                    iconColor={agencyColor}
                    onClick={() => {
                      dispatch({
                        type: ModalAction.OPEN_CUSTOM_MODAL,
                        data: {
                          target: "google-map",
                          children: <PopUp data={folder} />,
                        },
                      });
                    }}
                  />
                );
              }
            }
          })}
        {allAgency.garages &&
          allAgency.garages.map((garage, idx) => {
            const { folder, garageLat, garageLng, agencyColor } = garage;
            if (folder.length === 1) {
              return (
                <Marker
                  key={idx}
                  lat={garageLat}
                  lng={garageLng}
                  icon={"Marker"}
                  iconColor={agencyColor}
                  onClick={() => {
                    dispatch({
                      type: ModalAction.OPEN_CUSTOM_MODAL,
                      data: {
                        target: "google-map",
                        children: <PopUp data={folder} />,
                      },
                    });
                  }}
                />
              );
            } else {
              if (folder.length > 1) {
                return (
                  <MarkerCustom
                    key={idx}
                    lat={garageLat}
                    lng={garageLng}
                    icon={"Marker"}
                    iconColor={agencyColor}
                    onClick={() => {
                      dispatch({
                        type: ModalAction.OPEN_CUSTOM_MODAL,
                        data: {
                          target: "google-map",
                          children: <PopUp data={folder} />,
                        },
                      });
                    }}
                  />
                );
              }
            }
          })}
        {allAgency.agencies &&
          allAgency.agencies.map((agency, idx) => {
            const { folder, agencyLat, agencyLng, agencyColor } = agency;
            if (folder.length === 1) {
              return (
                <Marker
                  key={idx}
                  lat={agencyLat}
                  lng={agencyLng}
                  icon={"House"}
                  iconColor={folder[0].agencyColor}
                  onClick={() => {
                    dispatch({
                      type: ModalAction.OPEN_CUSTOM_MODAL,
                      data: {
                        target: "google-map",
                        children: <PopUp data={folder} />,
                      },
                    });
                  }}
                />
              );
            } else {
              if (folder.length > 1) {
                return (
                  <MarkerCustom
                    key={idx}
                    lat={agencyLat}
                    lng={agencyLng}
                    icon={"House"}
                    iconColor={agencyColor}
                    onClick={() => {
                      dispatch({
                        type: ModalAction.OPEN_CUSTOM_MODAL,
                        data: {
                          target: "google-map",
                          children: <PopUp data={folder} />,
                        },
                      });
                    }}
                  />
                );
              }
            }
          })}
      </GoogleMap>
    </div>
  );
};

export default memo(GoogleMapReact);

/* PRIVATE COMPONENTS */
type MarkerProps = {
  lat: number;
  lng: number;
  icon: IconNameType;
  iconColor: string;
  onClick: () => void;
};
const Marker = ({ lat, lng, icon, iconColor, onClick }: MarkerProps) => {
  return (
    <div
      style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
      onClick={onClick}
    >
      <Icon icon={icon} size={20} color={iconColor} />
    </div>
  );
};

type PopUpProps = {
  data: TMapData[];
};
const PopUp = (popUpProps: PopUpProps) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const { data } = popUpProps;

  return (
    <Flex display="flex" direction="column" gap={10}>
      <h2>Détails expertise</h2>
      <Flex display="flex" gap={10}>
        {Array.isArray(data) &&
          data.map((el, idx) => (
            <div
              key={idx}
              style={{
                padding: 5,
                borderRadius: 10,
                cursor: "pointer",
                color: idx === currentIdx ? "var(--white)" : "",
                background: idx === currentIdx ? "var(--primary-bg-color)" : "",
              }}
              onClick={() => setCurrentIdx(idx)}
            >
              {el.reference}
            </div>
          ))}
      </Flex>
      <>
        <div>Nom: {data[currentIdx].garageName}</div>
        <div>Référence: {data[currentIdx].reference}</div>
        <div>Agence: {data[currentIdx].agencyName}</div>
        <div>Expert: {data[currentIdx].expertName}</div>
        <div>Nature d'expertise: {data[currentIdx].natureExpertise}</div>
        <div>Distance: Km Entre l'agence et le garage</div>
      </>
    </Flex>
  );
};

type MarkerWrapperProps = {
  lat: number;
  lng: number;
  icon: IconNameType;
  iconColor: string;
  onClick: () => void;
};
const MarkerCustom = ({
  lat,
  lng,
  icon,
  iconColor,
  onClick,
}: MarkerWrapperProps) => {
  return (
    <div
      style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
      onClick={onClick}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 5,
            background: "var(--primary-red)",
            borderRadius: "20px",
            zIndex: 10,
          }}
        >
          <Icon
            icon={"Plus"}
            color="var(--white)"
            size={5}
            style={{ padding: "2px" }}
          />
        </div>
        <Icon icon={icon} size={20} color={iconColor} />
      </div>
    </div>
  );
};
