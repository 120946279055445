/* Libs */
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ColorSelect,
  Flex,
  InputCheckbox,
  InputSelect,
  Table,
  Icon,
  Pagination,
  InputText,
  Button,
  MultipleSelect,
  StatusColor,
  Card,
  DropdownAction,
  ExportDot,
  IconNameType,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { Col, Row } from "react-flexbox-grid";
import moment from "moment";

/* Reducers */
import { useAppDispatch } from "../../../reducers";

/* Actions */
import { ModalAction, openMessageModal } from "../../../actions/modalAction";
import {
  getDocumentPDF,
  getUpdatedManaging,
  newManaging,
  updateManaging,
} from "../../../actions/folderAction";

/* Utils */
import getUrl from "../../../utils/getUrl";
import { getGradeColor, getGradeLevel } from "../../../utils/getGradeColor";
import { previewTemplate } from "../../../utils/managing/previewTemplate";

/* Types */
import type {
  TManaging,
  TOptionsDropdownAction,
  TitemPDF,
} from "../../../types/folder/actions/managingType";
import type { TDataFile, TFile } from "../../../types/folder/folderType";
import type { TbaseReducer } from "../../../types/reducer/reducerType";
import type { TresultWithContent } from "../../../types/app/appType";
import { getLogo } from "../../../utils/managing/getLogo";

export enum DeleteManaging {
  DELETE = 1,
  UNDELETE = 0,
}

const sourcesOptions = [
  { value: "manuel", text: "manuel" },
  { value: "lacour", text: "lacour" },
  { value: "pixtransfer", text: "pixtransfer" },
  { value: "ixperience", text: "ixperience" },
  { value: "redaxion", text: "redaxion" },
  { value: "pilotage-cabinet", text: "pilotage-cabinet" },
  { value: "evalcar", text: "evalcar" },
  { value: "ega", text: "ega" },
  { value: "mail-entrant", text: "mail-entrant" },
  { value: "mail-sortant", text: "mail-sortant" },
  { value: "darva", text: "darva" },
  { value: "gla-software", text: "gla-software" },
];

const colorsOptions = [
  { value: 1, text: "Bleu" },
  { value: 2, text: "Vert" },
  { value: 3, text: "Orange" },
  { value: 4, text: "Rouge" },
];

const Managing = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { name, userId } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );
  const { token } = useSelector((state: TbaseReducer) => state.authReducer);
  const { dataFile } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  const [showDeletedManagings, setShowDeletedManaging] = useState(false);
  const [activeLines, setActiveLines] = useState<number[]>([]);
  const [activeLinesIdx, setActiveLinesIdx] = useState<number[]>([]);
  const [editLineId, setEditLineId] = useState(-1);
  const [activeLine, setActiveLine] = useState(-1);
  const [activeFile, setActiveFile] = useState(-1);
  const [activePageFile, setActivePageFile] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState<Record<string, any>>({});
  const [filterImage, setFilterImage] = useState<boolean>(true);
  const [filterObject, setFilterObject] = useState<Record<string, any>>({});
  const [showPj, setShowPj] = useState(false);

  const managingContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeLine > -1) {
      let idx: Array<number> = [];

      managingFilter(dataFile)[activeLine].attachments.map((token: string) => {
        dataFile.files.forEach((file: any, i: number) => {
          if (file.token === token) {
            idx.push(i);
          }
        });
      });

      if (idx.length) {
        setActiveFile(idx[0]);
      }
    }
  }, [activeLine]);

  const getXLSX = (data: TitemPDF[], workBook: any) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workBook, workSheet, "");
    XLSX.writeFile(workBook, `suivi_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
    if (activeLines.length) {
      setActiveLines([]);
    }
  };

  const getDocument = (
    token: string,
    data: TitemPDF[],
    callback: (result: TresultWithContent) => void
  ) => {
    dispatch(getDocumentPDF(token, data, callback));
  };

  const handleAddRow = (newData: TManaging) => {
    const { fromSource, subject, color } = newData;

    dispatch(
      newManaging({
        folderId: Number(id),
        from: userId,
        fromSource,
        toSource: "suivis",
        subject,
        grade: getGradeLevel(color),
      })
    );
  };

  const handleDeleteManaging = (managing: any) => {
    dispatch(
      updateManaging(managing.id, id, { deleted: DeleteManaging.DELETE })
    );
  };

  const handleUpdateManaging = (managing: any, editData: any) => {
    dispatch(
      updateManaging(
        managing.id,
        id,
        {
          newColor: getGradeLevel(editData.color),
          oldColor: Number(managing.color),
          newSubject: editData.subject,
          oldSubject: managing.subject,
        },
        () => {
          setIsEdit(false);
        }
      )
    );
  };

  const openHistoryManagingModal = (item: any, idx?: number) => {
    dispatch(
      getUpdatedManaging(item.id, () => {
        dispatch({
          type: ModalAction.OPEN_HISTORY_MANAGING_MODAL,
          data: { currentManagingId: item.id, managingIdx: idx },
        });
      })
    );
  };

  const managingFilter = useCallback(
    (data: TDataFile) => {
      return data.managings.filter((managing) => {
        if (showDeletedManagings) {
          return managing.deleted === DeleteManaging.DELETE;
        } else if (
          filterObject.hasOwnProperty("color") &&
          filterObject.hasOwnProperty("source")
        ) {
          return (
            filterObject.color.includes(Number(managing.color)) &&
            managing.fromSource === filterObject.fromSource &&
            !managing.deleted
          );
        } else if (filterObject.hasOwnProperty("source")) {
          return (
            managing.fromSource === filterObject.fromSource && !managing.deleted
          );
        } else if (filterObject.hasOwnProperty("color")) {
          return (
            filterObject.color.includes(Number(managing.color)) &&
            !managing.deleted
          );
        } else {
          return !managing.deleted;
        }
      });
    },
    [filterObject, showDeletedManagings]
  );

  return (
    <div
      className="managing-flex-direction"
      style={{
        display: "flex",
        gap: 10,
        overflow: "hidden",
      }}
    >
      <div
        className="managing-table-width"
        style={{ width: "60%" }}
        ref={managingContainerRef}
      >
        <Table
          wrapText
          style={{ height: "80vh", overflowY: "scroll" }}
          data={managingFilter(dataFile)}
          topAddRow
          handleAddRow={handleAddRow}
          customRowStyle={(managing, idx) => {
            return {
              background:
                managing.deleted === DeleteManaging.DELETE
                  ? "var(--red-light)"
                  : idx === activeLine
                  ? "#EEEEEE"
                  : "",
            };
          }}
          headerComponent={
            <Flex
              display="flex"
              direction="column"
              gap={5}
              styles={{ padding: 20 }}
            >
              <Flex display="flex" justify="space-between">
                <h2 style={{ margin: 0, fontSize: "18px" }}>Suivi</h2>
                <Flex display="flex" gap={15}>
                  <Button
                    outline
                    text={"PDF"}
                    icon={<ExportDot size={18} />}
                    onClick={() => {
                      const data = managingFilter(dataFile).map((managing) => ({
                        Date: moment(managing.date).format("DD/MM/YYYY HH:mm"),
                        De: managing.user,
                        Libelle: managing.subject,
                        Source: managing.fromSource,
                        PJ: managing.attachments.length,
                      }));

                      getDocument(
                        token,
                        activeLinesIdx.length > 0
                          ? data.filter((_el, index) =>
                              activeLinesIdx.includes(index)
                            )
                          : data,
                        (result) => {
                          if (result.success) {
                            let a = document.createElement("a");
                            a.href =
                              "data:application/pdf;base64," + result.content;
                            a.download = "liste_suivis.pdf";
                            a.click();
                            if (activeLinesIdx.length) {
                              setActiveLinesIdx([]);
                            }
                          }
                        }
                      );
                    }}
                  />
                  <Button
                    outline
                    text={"XSLSX"}
                    icon={<ExportDot size={18} />}
                    onClick={() => {
                      const workBook = XLSX.utils.book_new();
                      const data = managingFilter(dataFile).map((managing) => ({
                        Date: moment(managing.date).format("DD/MM/YYYY HH:mm"),
                        De: managing.user,
                        Libelle: managing.subject,
                        Source: managing.fromSource,
                        PJ: managing.attachments.length,
                      }));

                      getXLSX(
                        activeLinesIdx.length > 0
                          ? data.filter((_el, index) =>
                              activeLinesIdx.includes(index)
                            )
                          : data,
                        workBook
                      );
                    }}
                  />
                </Flex>
              </Flex>
              <Flex display="flex" gap={10}>
                <MultipleSelect
                  options={colorsOptions}
                  onChange={(value) => {
                    if (value.length) {
                      setFilterObject((prev) => ({
                        ...prev,
                        ["color"]: value,
                      }));
                    } else {
                      setFilterObject((prev) => {
                        const filterCopy = { ...prev };
                        delete filterCopy["color"];
                        return filterCopy;
                      });
                    }
                  }}
                  placeholder={"Filtrer les couleurs"}
                  value={filterObject["color"] || []}
                />
                <InputSelect
                  options={sourcesOptions}
                  onChange={(value) =>
                    setFilterObject((prev) => ({ ...prev, ["source"]: value }))
                  }
                  value={filterObject["source"] || ""}
                  placeholder={"Toutes les sources"}
                />

                <Icon
                  backgroundHover
                  infoText="Réinitialiser le filtre"
                  icon={"Reload"}
                  size={20}
                  onClick={() => {
                    setFilterObject({});
                  }}
                />
              </Flex>
              <Flex display="flex" gap={10}>
                <InputCheckbox
                  text={"Masquer les images"}
                  isCheck={filterImage}
                  onClick={() => null}
                  onChange={() => setFilterImage(!filterImage)}
                />
                <InputCheckbox
                  text={"Voir les lignes supprimées"}
                  isCheck={showDeletedManagings}
                  onClick={() => null}
                  onChange={() => {
                    setActiveLine(-1);
                    setShowDeletedManaging(!showDeletedManagings);
                  }}
                />
              </Flex>
            </Flex>
          }
          columns={[
            {
              key: "",
              label: "",
              component: (_, idx) => (
                <InputCheckbox
                  text={""}
                  isCheck={activeLinesIdx.includes(idx!)}
                  onClick={() => null}
                  onChange={() => {
                    if (activeLinesIdx.includes(idx!)) {
                      setActiveLinesIdx((prev) => [
                        ...prev.filter((number) => number !== idx),
                      ]);
                    } else {
                      setActiveLinesIdx((prev) => [...prev, idx!]);
                    }
                  }}
                />
              ),
            },
            {
              key: "color",
              label: "",
              isSelectColor: true,
              component: (item) => {
                return !item.deleted ? (
                  <ColorSelect
                    value={getGradeColor(Number(item.color))}
                    onChange={(color) => {
                      handleUpdateManaging(item, {
                        ["color"]: color,
                      });
                    }}
                    colorList={["blue", "green", "orange", "red"]}
                  />
                ) : (
                  <StatusColor color={getGradeColor(Number(item.color))} />
                );
              },
            },
            {
              key: "date",
              label: "Date",
              component: (item) => {
                return (
                  <div>
                    <div>{moment(item.date).format("DD/MM/YYYY")}</div>
                    <div style={{ fontSize: "10px", textAlign: "center" }}>
                      {moment(item.date).format("HH:mm:ss")}
                    </div>
                  </div>
                );
              },
            },
            {
              key: "user",
              label: "De",
            },
            {
              key: "subject",
              label: "Libellé",
              isInput: true,
              component: (item) => {
                return (
                  <>
                    {isEdit && editLineId === item.id ? (
                      <InputText
                        placeholder={""}
                        value={
                          editData.subject ? editData.subject : item.subject
                        }
                        onChange={(e) =>
                          setEditData((prev) => ({
                            ...prev,
                            ["subject"]: e.target.value,
                          }))
                        }
                      />
                    ) : (
                      item.subject
                    )}
                  </>
                );
              },
            },
            {
              key: "fromSource",
              label: "Source",
              isSelect: true,
              selectData: sourcesOptions,
              component: (item) => {
                return (
                  <Flex align="center" justify="center" gap={5}>
                    {getLogo(item.fromSource)}
                    <Icon
                      noPadding
                      icon={"ArrowLeft"}
                      size={10}
                      style={{ transform: "rotate(180deg)" }}
                    />
                    {getLogo(item.toSource)}
                  </Flex>
                );
              },
            },

            {
              key: "attachments",
              label: "PJ",
              component: (item) => <>{item.attachments.length}</>,
            },
            {
              key: "",
              label: "",
              component: (item, idx) => {
                const optionsArray: TOptionsDropdownAction[] = [
                  {
                    title: "Supprimer",
                    icon: "Trash",
                    size: 10,
                    onClick: () =>
                      dispatch({
                        type: ModalAction.OPEN_CONFIRM_MODAL,
                        data: {
                          title: "Supprimer une ligne de suivi",
                          message:
                            "Êtes-vous sûr de vouloir supprimer ce suivi ?",
                          onConfirm: () => handleDeleteManaging(item),
                        },
                      }),
                  },
                  {
                    title: "Modifier",
                    icon: "Pen",
                    size: 10,
                    onClick: () => {
                      setEditLineId(item.id);
                      setIsEdit(true);
                    },
                  },
                ];
                if (item.updatedCount > 0) {
                  optionsArray.push({
                    title: "Historique",
                    icon: "Copy",
                    size: 10,
                    onClick: () => openHistoryManagingModal(item, idx),
                  });
                }
                return (
                  <>
                    {!isEdit && !item.deleted ? (
                      <DropdownAction
                        containerRef={managingContainerRef}
                        options={optionsArray}
                      />
                    ) : (
                      editLineId === item.id && (
                        <div className="d-flex">
                          <Icon
                            icon={"Check"}
                            size={15}
                            color="green"
                            onClick={() => handleUpdateManaging(item, editData)}
                          />
                          <Icon
                            icon={"Cross"}
                            size={15}
                            onClick={() => setIsEdit(false)}
                          />
                        </div>
                      )
                    )}
                  </>
                );
              },
            },
          ]}
          context={null}
          onRowClick={(_, idx) => {
            setActiveLine(idx!);
            setShowPj(false);
          }}
        />
      </div>
      {activeLine >= 0 &&
      managingFilter(dataFile)[activeLine] &&
      managingFilter(dataFile)[activeLine].attachments.length ? (
        <AttachmentsCard
          managing={managingFilter(dataFile)[activeLine]}
          showPj={showPj}
          setShowPj={setShowPj}
          activePageFile={activePageFile}
          activeFile={activeFile}
          setActiveFile={setActiveFile}
          setActivePageFile={setActivePageFile}
        />
      ) : activeLine >= 0 &&
        managingFilter(dataFile)[activeLine] &&
        managingFilter(dataFile)[activeLine].messages ? (
        <Card className="w-100" classNameChildren="h-100">
          <iframe
            style={{
              marginTop: 10,
              minHeight: "90%",
              width: "100%",
              border: 0,
              borderRadius: 8,
              backgroundColor: "#EEEEEE",
            }}
            srcDoc={previewTemplate(
              managingFilter(dataFile)[activeLine].toSource === "mail-sortant",
              managingFilter(dataFile)[activeLine],
              name,
              dataFile,
              false,
              null
            )}
          />
        </Card>
      ) : (
        <Card className="col_full_height w-100">
          <Flex display="flex" align="center" justify="center">
            <p style={{ color: "var(--medium-grey)" }}>
              {activeLine !== -1 &&
              managingFilter(dataFile)[activeLine].attachments.length === 0
                ? "La ligne de suivi ne comporte pas de pièce jointe."
                : "Aucune ligne de suivi sélectionnée"}
            </p>
          </Flex>
        </Card>
      )}
    </div>
  );
};

export default Managing;

/** Private Components **/

type AttachmentsCardProps = {
  managing: TManaging;
  showPj: boolean;
  setShowPj: Dispatch<React.SetStateAction<boolean>>;
  activePageFile: number;
  activeFile: number;
  setActiveFile: Dispatch<React.SetStateAction<number>>;
  setActivePageFile: Dispatch<React.SetStateAction<number>>;
};
const AttachmentsCard = ({
  managing,
  showPj,
  setShowPj,
  activePageFile,
  activeFile,
  setActiveFile,
  setActivePageFile,
}: AttachmentsCardProps) => {
  const { id } = useParams();
  const { name } = useSelector((state: TbaseReducer) => state.authReducer);
  const { dataFile } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );
  const { attachments, messages } = managing;

  return (
    <Card className="w-100 p-20" classNameChildren="h-100" header={false}>
      <>
        {showPj ? (
          <>
            <ActionHeaderCard
              managing={managing}
              setShowPj={setShowPj}
              showPj={showPj}
            />
            <Row style={{ marginTop: "16px" }}>
              {attachments.length > 1
                ? attachments
                    .slice((activePageFile - 1) * 4, activePageFile * 4)
                    .map((token, key) => {
                      let idx: number = -1;
                      let files = dataFile.files.filter(
                        (file: any, i: number) => {
                          if (file.token === token) {
                            idx = i;
                            return true;
                          }
                          return false;
                        }
                      );
                      if (files.length) {
                        return (
                          <AttachmentsImageCard
                            key={key}
                            files={files}
                            activeFile={activeFile}
                            idx={idx}
                            setActiveFile={setActiveFile}
                          />
                        );
                      } else {
                        return null;
                      }
                    })
                : null}
              {attachments.length > 4 ? (
                <Col xs={12}>
                  <Pagination
                    totalItems={attachments.length}
                    itemsPerPage={4}
                    onPageChange={setActivePageFile}
                  />
                </Col>
              ) : null}
            </Row>
            {activeFile >= 0 && dataFile.files[activeFile] ? (
              <>
                {!["jpg", "jpeg", "png"].includes(
                  dataFile.files[activeFile].extension
                ) ? (
                  <iframe
                    className="mt-10"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src={getUrl(id, dataFile.files[activeFile].token, 0)}
                  />
                ) : (
                  <Flex
                    display="flex"
                    justify="center"
                    align="center"
                    styles={{ height: "100%" }}
                  >
                    <img
                      loading="lazy"
                      className="m-auto"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                      src={getUrl(
                        id,
                        dataFile.files[activeFile].token,
                        320,
                        dataFile.files[activeFile].timestamp
                      )}
                      alt={`image-${dataFile.files[activeFile].token}`}
                    />
                  </Flex>
                )}
              </>
            ) : null}
          </>
        ) : (
          <Flex display="flex" direction="column" styles={{ height: "100%" }}>
            <ActionHeaderCard
              managing={managing}
              setShowPj={setShowPj}
              showPj={showPj}
            />
            <div style={{ height: "100%", width: "100%" }}>
              {messages ? (
                <iframe
                  style={{
                    marginTop: 10,
                    minHeight: "100%",
                    width: "100%",
                    border: 0,
                    borderRadius: 8,
                    backgroundColor: "#EEEEEE",
                  }}
                  srcDoc={previewTemplate(
                    managing.toSource === "mail-sortant",
                    managing,
                    name,
                    dataFile,
                    false,
                    null
                  )}
                />
              ) : (
                <Flex
                  display="flex"
                  align="center"
                  justify="center"
                  classNames="h-100"
                >
                  <p style={{ color: "var(--medium-grey)" }}>
                    La ligne de suivi ne comporte pas de message
                  </p>
                </Flex>
              )}
            </div>
          </Flex>
        )}
      </>
    </Card>
  );
};

type AttachmentsImageCardProps = {
  files: TFile[];
  activeFile: number;
  idx: number;
  setActiveFile: Dispatch<React.SetStateAction<number>>;
};
const AttachmentsImageCard = ({
  files,
  activeFile,
  idx,
  setActiveFile,
}: AttachmentsImageCardProps) => {
  const { id } = useParams();
  const file = files[0];

  return (
    <Col key={file.token} xs={12} lg={3}>
      <Flex
        display="flex"
        direction="column"
        styles={{
          border: "1px solid var(--medium-grey)",
          borderRadius: "10px",
          overflow: "hidden",
          height: "120px",
          cursor: "pointer",
          opacity: activeFile === idx ? 0.7 : 1,
        }}
        onClick={() =>
          activeFile === idx ? setActiveFile(-1) : setActiveFile(idx)
        }
      >
        <p
          style={{
            textAlign: "center",
            fontSize: 10,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: 3,
          }}
        >
          {file.filename}.{file.extension}
        </p>

        {["jpg", "jpeg", "png"].includes(file.extension.toLowerCase()) ? (
          <Flex
            display="flex"
            direction="column"
            styles={{
              height: "100%",
              overflow: "hidden",
              cursor: "pointer",
              borderTop: "1px solid var(--medium-grey)",
            }}
          >
            <Flex
              display="flex"
              direction="column"
              align="center"
              styles={{
                height: "100%",
                overflow: "hidden",
                cursor: "pointer",
                padding: 10,
              }}
            >
              <img
                loading="lazy"
                className="m-auto"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
                src={getUrl(id, file.token, 320, file.timestamp)}
                alt={`image-${file.token}`}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex align="center" justify="center">
            <Icon icon={"File"} />
          </Flex>
        )}
      </Flex>
    </Col>
  );
};

type ActionHeaderCardProps = {
  managing: TManaging;
  setShowPj: Dispatch<React.SetStateAction<boolean>>;
  showPj: boolean;
};

const ActionHeaderCard = ({
  managing,
  setShowPj,
  showPj,
}: ActionHeaderCardProps) => {
  const dispatch = useAppDispatch();
  const {
    fromSource,
    attachments,
    messages,
    subject,
    fromEmail,
    emailDate,
    metadata,
  } = managing;

  return (
    <Flex display="flex" justify="space-between">
      <Button
        text={!showPj ? "Voir les piéces jointes" : "Voir le message"}
        onClick={() => setShowPj(!showPj)}
      />
      {metadata?.from && fromSource === "mail-entrant" ? (
        <Icon
          infoTop
          backgroundHover
          infoText="Répondre"
          icon={"Back"}
          size={20}
          onClick={() => {
            dispatch(
              openMessageModal({
                messages,
                reply: true,
                source: fromSource,
                fromEmail: metadata.from,
                emailDate,
                attachments,
                metadata,
              })
            );
          }}
        />
      ) : (
        <>
          {fromSource !== "mail-entrant" && (
            <Flex display="flex" gap={10}>
              <Icon
                infoTop
                backgroundHover
                infoText="Message IxPerience"
                icon={"Ixperience"}
                size={14}
                onClick={() => {
                  dispatch(
                    openMessageModal({
                      messages,
                      reply: true,
                      source: "ixperience",
                      fromEmail,
                      emailDate: managing.date,
                      attachments,
                      subject,
                      metadata,
                    })
                  );
                }}
              />
              <Icon
                infoTop
                backgroundHover
                infoText="Mail"
                icon={"Envelope"}
                size={20}
                onClick={() => {
                  dispatch(
                    openMessageModal({
                      messages,
                      reply: true,
                      source: "mail-sortant",
                      fromEmail,
                      emailDate: managing.date,
                      attachments,
                      subject,
                      metadata,
                    })
                  );
                }}
              />
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};
