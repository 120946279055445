import { openMessageModalType } from "../types/action/modalActionType";

export enum ModalAction {
  /* Confirm Modal*/
  OPEN_CONFIRM_MODAL = "OPEN_CONFIRM_MODAL",
  CLOSE_CONFIRM_MODAL = "CLOSE_CONFIRM_MODAL",

  /* Upload Modal */
  OPEN_UPLOAD_MODAL = "OPEN_UPLOAD_MODAL",
  CLOSE_UPLOAD_MODAL = "CLOSE_UPLOAD_MODAL",

  /* Close all Modal */
  CLOSE_CURRENT_MODAL = "CLOSE_CURRENT_MODAL",

  /* Error Modal */
  OPEN_INFO_MODAL = "OPEN_INFO_MODAL",
  CLOSE_INFO_MODAL = "CLOSE_INFO_MODAL",

  /* SEARCH FOLDERS MODAL */
  OPEN_SEARCH_FOLDERS_MODAL = "OPEN_SEARCH_FOLDERS_MODAL",
  CLOSE_SEARCH_FOLDERS_MODAL = "CLOSE_SEARCH_FOLDERS_MODAL",

  /*MANAGING HISTORY MODAL */
  OPEN_HISTORY_MANAGING_MODAL = "OPEN_HISTORY_MANAGING_MODAL",
  CLOSE_HISTORY_MANAGING_MODAL = "CLOSE_HISTORY_MANAGING_MODAL",

  /* LIBRARY MODAL */
  OPEN_ADD_LIBRARY_MODAL = "OPEN_ADD_LIBRARY_MODAL",
  OPEN_EDIT_LIBRARY_MODAL = "OPEN_EDIT_LIBRARY_MODAL",

  /* CUSTOM MODAL */
  OPEN_CUSTOM_MODAL = "OPEN_CUSTOM_MODAL",
  CLOSE_CUSTOM_MODAL = "CLOSE_CUSTOM_MODAL",

  /* FLEX MODAL */
  OPEN_FLEX_MODAL = "OPEN_FLEX_MODAL",
  CLOSE_FLEX_MODAL = "CLOSE_FLEX_MODAL",

  /* MESSAGE MODAL */
  OPEN_MESSAGE_MODAL = "OPEN_MESSAGE_MODAL",
  CLOSE_MESSAGE_MODAL = "CLOSE_MESSAGE_MODAL",
}

export const openMessageModal = (options: openMessageModalType) => {
  const {
    source,
    attachments,
    messages,
    subject,
    fromEmail,
    reply,
    emailDate,
    metadata,
  } = options;

  return (dispatch: any) => {
    dispatch({
      type: ModalAction.OPEN_MESSAGE_MODAL,
      data: {
        messageOptions: source,
        attachments,
        messages,
        subject,
        fromEmail,
        reply,
        emailDate,
        metadata,
      },
    });
  };
};
