import { colorType } from "@sahadevia/ui-kit-modern-sahadevia";
import { ELevelColor } from "../types/folder/actions/managingType";

const getGradeColor = (grade: number): colorType => {
  switch (grade) {
    case ELevelColor.LEVEL_1:
      return "blue";
    case ELevelColor.LEVEL_2:
      return "green";
    case ELevelColor.LEVEL_3:
      return "orange";
    case ELevelColor.LEVEL_4:
      return "red";
    default:
      return "blue";
  }
};

const getGradeLevel = (grade: colorType): number => {
  switch (grade) {
    case "blue":
      return ELevelColor.LEVEL_1;
    case "green":
      return ELevelColor.LEVEL_2;
    case "orange":
      return ELevelColor.LEVEL_3;
    case "red":
      return ELevelColor.LEVEL_4;
    default:
      return ELevelColor.LEVEL_1;
  }
};

export { getGradeColor, getGradeLevel };
