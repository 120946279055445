import { NavLink } from "react-router-dom";

type Props = {
  text: string;
  path: string;
  active?: boolean;
  isMobile: boolean;
};

function CustomLink({ text, path, active, isMobile }: Props) {
  const css = isMobile
    ? {
        display: "inline-flex",
        alignItems: "center",
        height: "36px",
        padding: "16px",
        cursor: "pointer",
        textDecoration: "none",
        color: "#212121",
      }
    : {
        display: "inline-flex",
        alignItems: "center",
        height: "36px",
        borderRadius: "8px",
        paddingLeft: "14px",
        paddingRight: "14px",
        cursor: "pointer",
        margin: "auto",
        textDecoration: "none",
        color: "#212121",
      };
  return (
    <NavLink
      className={({ isActive }) => (isActive || active ? "active" : "")}
      style={css}
      to={path}
    >
      {text}
    </NavLink>
  );
}

export default CustomLink;
