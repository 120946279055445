/* Libs */
import {
  Button,
  ButtonSwitch,
  Card,
  Flex,
  Gauge,
  Grid,
  Icon,
  InputDate,
  InputSelect,
  InputText,
  Table,
  selectOptionType,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

/* Reducers */
import { useAppDispatch } from "../../../reducers";

/* Actions */
import {
  FolderAction,
  getHistovec,
  getHistovecPDF,
  updateGaugeInfo,
  updateVehicleInfo,
} from "../../../actions/folderAction";
import { ModalAction } from "../../../actions/modalAction";

/* Types */
import type { TbaseReducer } from "../../../types/reducer/reducerType";
import type {
  TSwitchButtonKeys,
  TVehicle,
} from "../../../types/folder/folderType";
import type { TInsuredData } from "../../../types/folder/vehicle/vehicleType";
import type { ExcludeKey, TResultWithData } from "../../../types/app/appType";

enum VehicleLabel {
  Immat = "Immatriculation",
  Constructeur = "Marque",
  Annee_Modele = "Année",
  Carrosserie = "Carrosserie",
  Categorie = "",
  Code_Moteur = "",
  Couleur = "Couleur",
  Date1MEC = "Date de mise en circulation",
  DateDernierCT = "Date dernier CT",
  DateCertificatImmat = "Date certificat",
  Energie = "Énergie",
  Finition = "",
  Genre = "",
  Modele = "Modèle",
  Nation_Immat = "",
  Nb_Places = "",
  NumPR = "Kilométrage",
  Numero_Chassis = "Numéro de série",
  Puissance_Fiscale = "",
  Puissance_Reelle = "",
  Type_Mine = "",
  VIN = "VIN",
  generalStatus = "État du véhicule",
  formula = "N° de formule",
  gearbox = "Boîte de vitesse",
  ImmatButton = "Conforme",
  VINButton = "Conforme",
  NumPRButton = "Conforme",
  DateDernierCTButton = "Conforme",
}

const Vehicle = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { vehicle, vehicleInfo } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  const initialVehicleData = useMemo(() => vehicle, []);

  if (!id) return;

  const operationColumns = [
    {
      key: "date",
      label: "Date",
    },
    {
      key: "text",
      label: "Opération",
    },
  ];
  const technicalControlcolumns = [
    {
      key: "date",
      label: "Date",
    },
    {
      key: "nature",
      label: "Nature",
    },
    {
      key: "result",
      label: "Résultat",
    },
    {
      key: "mileage",
      label: "Kilométrage",
    },
  ];

  const getInputText = (key: keyof TVehicle, keyButton?: TSwitchButtonKeys) => {
    return (
      <Flex display="flex" align="center" gap={5}>
        <InputText
          value={vehicle[key]?.toString() || ""}
          style={{ width: "100%" }}
          label={VehicleLabel[key]}
          placeholder={""}
          onChange={(e) => {
            if (!isEditing) {
              setIsEditing(true);
            }
            dispatch({
              type: FolderAction.UPDATE_VEHICLE_INFO,
              data: { [key]: e.target.value },
            });
          }}
        />
        {keyButton &&
          Array.from<TSwitchButtonKeys>([
            "ImmatButton",
            "VINButton",
            "NumPRButton",
          ]).includes(keyButton) && (
            <ButtonSwitch
              label="Conforme"
              firstOption={"Oui"}
              secondOption={"Non"}
              value={vehicle[keyButton]}
              onChange={(v) => {
                if (!isEditing) {
                  setIsEditing(true);
                }
                dispatch({
                  type: FolderAction.UPDATE_VEHICLE_INFO,
                  data: { [`${key}Button`]: v },
                });
              }}
            />
          )}
      </Flex>
    );
  };

  const getInputSelect = (
    options: selectOptionType[],
    key: keyof ExcludeKey<
      TVehicle,
      "ImmatButton" | "VINButton" | "NumPRButton" | "DateDernierCTButton"
    >,
    placeholder?: string
  ) => {
    return (
      <InputSelect
        placeholder={placeholder || ""}
        label={VehicleLabel[key]}
        options={options}
        value={vehicle[key] || ""}
        onChange={(v) => {
          if (!isEditing) {
            setIsEditing(true);
          }
          dispatch({
            type: FolderAction.UPDATE_VEHICLE_INFO,
            data: { [key]: v },
          });
        }}
      />
    );
  };

  const handleGaugeChange = (key: string, position: number) => {
    dispatch(
      updateGaugeInfo({
        [key]: position,
        folderId: id,
        vehicleInfo,
        source: "gaugeInfo",
      })
    );
  };

  const handleUpdateVehicle = () => {
    dispatch(
      updateVehicleInfo({
        newVehicleData: vehicle,
        folderId: id,
        vehicleInfo,
        source: "vehicleData",
      })
    );
  };

  const getInputDate = (key: keyof TVehicle, keyButton?: TSwitchButtonKeys) => {
    return (
      <Flex display="flex" align="center" gap={5}>
        <InputDate
          style={{ width: "100%" }}
          label={VehicleLabel[key]}
          value={(vehicle[key] as string) || ""}
          onChange={(v) => {
            if (!isEditing) {
              setIsEditing(true);
            }

            dispatch({
              type: FolderAction.UPDATE_VEHICLE_INFO,
              data: { [key]: v.target.value },
            });
          }}
        />
        {keyButton &&
          Array.from<TSwitchButtonKeys>(["DateDernierCTButton"]).includes(
            keyButton
          ) && (
            <ButtonSwitch
              label="Conforme"
              firstOption={"Oui"}
              secondOption={"Non"}
              value={vehicle[keyButton]}
              onChange={(v) => {
                if (!isEditing) {
                  setIsEditing(true);
                }
                dispatch({
                  type: FolderAction.UPDATE_VEHICLE_INFO,
                  data: { [`${key}Button`]: v },
                });
              }}
            />
          )}
      </Flex>
    );
  };

  return (
    <Flex
      display="flex"
      direction="column"
      gap={20}
      styles={{ paddingBottom: 60 }}
    >
      {/* Card 1 */}
      <Card title="Identification du véhicule">
        <Flex display="flex" direction="column" gap={10}>
          <Grid display="grid" columnNumber={3} gap={10}>
            <Flex display="flex" direction="column" gap={10}>
              {getInputText("Immat", "ImmatButton")}
              {getInputText("Constructeur")}
              {getInputDate("Date1MEC")}
              {getInputSelect(
                [
                  { value: "Manuelle", text: "Manuelle" },
                  { value: "Automatique", text: "Automatique" },
                ],
                "gearbox",
                "Veuillez sélectionner un type de boîte de vitesse"
              )}
              {getInputText("VIN", "VINButton")}
              {getInputSelect(
                [
                  { value: "B", text: "Bon" },
                  { value: "N", text: "Normal" },
                  { value: "M", text: "Mauvais" },
                  { value: "MA", text: "Mauvais" },
                  { value: "D", text: "Dangereux" },
                  { value: "MO", text: "Moyen" },
                ],
                "generalStatus",
                "État du véhicule"
              )}
            </Flex>
            <Flex display="flex" direction="column" gap={10}>
              {getInputText("Numero_Chassis")}
              {getInputText("Modele")}
              {getInputDate("DateCertificatImmat")}
              {getInputText("Carrosserie")}
              {getInputText("NumPR", "NumPRButton")}
            </Flex>
            <Flex display="flex" direction="column" gap={10}>
              {getInputDate("DateDernierCT", "DateDernierCTButton")}
              {getInputText("Annee_Modele")}
              {getInputSelect(
                [
                  { value: "Essence", text: "Essence" },
                  { value: "Diesel", text: "Diesel" },
                  { value: "Électrique", text: "Électrique" },
                  { value: "Hybride", text: "Hybride" },
                  { value: "GPL", text: "GPL" },
                  { value: "Sans énergie", text: "Sans énergie" },
                ],
                "Energie",
                "Veuillez sélectionner un d'énergie"
              )}
              {getInputText("Couleur")}
              {getInputText("formula")}
            </Flex>
          </Grid>
          {isEditing && (
            <Flex display="flex" justify="end" gap={5}>
              <Button
                text={"Valider les changements"}
                onClick={() => {
                  setIsEditing(false);
                  handleUpdateVehicle();
                }}
              />
              <Button
                text={"Annuler"}
                onClick={() => {
                  setIsEditing(false);
                  dispatch({
                    type: FolderAction.UPDATE_VEHICLE_INFO,
                    data: initialVehicleData,
                  });
                }}
              />
            </Flex>
          )}
        </Flex>
      </Card>
      {/* Card 2 */}
      <Card header={false}>
        <Flex display="flex" justify="space-between">
          <h3>Rapport Histovec</h3>
          <Flex display="flex">
            {vehicleInfo?.histovec && (
              <Icon
                icon={"Download"}
                size={20}
                onClick={() => {
                  dispatch(
                    getHistovecPDF(
                      vehicleInfo?.histovec,
                      (result: TResultWithData) => {
                        if (result.success) {
                          let a = document.createElement("a");
                          a.href = "data:application/pdf;base64," + result.data;
                          a.download = "Rapport_histovec.pdf";
                          a.click();
                        }
                      }
                    )
                  );
                }}
              />
            )}
            <Icon
              icon={"Search"}
              size={20}
              onClick={() =>
                dispatch({
                  type: ModalAction.OPEN_CUSTOM_MODAL,
                  data: {
                    children: <PopUp />,
                  },
                })
              }
            />
          </Flex>
        </Flex>
      </Card>
      {vehicleInfo?.histovec?.operations && (
        <Table
          title="Opérations éffectuées"
          data={vehicleInfo.histovec.operations}
          columns={operationColumns}
          context={undefined}
        />
      )}
      {vehicleInfo?.histovec?.technical_control && (
        <Table
          title="Controle technique
        "
          data={vehicleInfo.histovec.technical_control}
          columns={technicalControlcolumns}
          context={undefined}
        />
      )}
      {/* Card 3 */}
      <Card title="Niveaux et usures" style={{ paddingBottom: 60 }}>
        <Grid display="grid" columnNumber={2} gap={20}>
          <Flex display="flex" direction="column" gap={50}>
            <Gauge
              noText
              title={"Liquide moteur"}
              colors={["#FF6871", "#11D979", "#FF6871"]}
              min={10}
              max={80}
              position={
                vehicleInfo.gaugeInfo && vehicleInfo.gaugeInfo["motorFluid"]
                  ? vehicleInfo.gaugeInfo["motorFluid"]
                  : 0
              }
              onMouseUp={(position) =>
                handleGaugeChange("motorFluid", position)
              }
            />
            <Gauge
              noText
              title={"Liquide de refroidissement"}
              colors={["#FF6871", "#11D979", "#FF6871"]}
              min={10}
              max={80}
              position={
                vehicleInfo.gaugeInfo && vehicleInfo.gaugeInfo["coolingMotor"]
                  ? vehicleInfo.gaugeInfo["coolingMotor"]
                  : 0
              }
              onMouseUp={(position) =>
                handleGaugeChange("coolingMotor", position)
              }
            />
            <Gauge
              noText
              title={"Liquide de freins"}
              colors={["#FF6871", "#11D979", "#FF6871"]}
              min={10}
              max={80}
              position={
                vehicleInfo.gaugeInfo && vehicleInfo.gaugeInfo["brakeFluid"]
                  ? vehicleInfo.gaugeInfo["brakeFluid"]
                  : 0
              }
              onMouseUp={(position) =>
                handleGaugeChange("brakeFluid", position)
              }
            />
            <Gauge
              noText
              title={"Autres liquides"}
              colors={["#FF6871", "#11D979", "#FF6871"]}
              min={10}
              max={80}
              position={
                vehicleInfo.gaugeInfo && vehicleInfo.gaugeInfo["otherFluid"]
                  ? vehicleInfo.gaugeInfo["otherFluid"]
                  : 0
              }
              onMouseUp={(position) =>
                handleGaugeChange("otherFluid", position)
              }
            />
          </Flex>
          <Flex display="flex" direction="column" gap={50}>
            <Gauge
              noText
              title={"Pneu avant gauche"}
              colors={["#11D979", "#FF6871"]}
              position={
                vehicleInfo.gaugeInfo && vehicleInfo.gaugeInfo["frontLeftTire"]
                  ? vehicleInfo.gaugeInfo["frontLeftTire"]
                  : 0
              }
              onMouseUp={(position) =>
                handleGaugeChange("frontLeftTire", position)
              }
            />

            <Gauge
              noText
              title={"Pneu avant droit"}
              colors={["#11D979", "#FF6871"]}
              position={
                vehicleInfo.gaugeInfo && vehicleInfo.gaugeInfo["frontRightTire"]
                  ? vehicleInfo.gaugeInfo["frontRightTire"]
                  : 0
              }
              onMouseUp={(position) =>
                handleGaugeChange("frontRightTire", position)
              }
            />

            <Gauge
              noText
              title={"Pneu arrière gauche"}
              colors={["#11D979", "#FF6871"]}
              position={
                vehicleInfo.gaugeInfo && vehicleInfo.gaugeInfo["rearLeftTire"]
                  ? vehicleInfo.gaugeInfo["rearLeftTire"]
                  : 0
              }
              onMouseUp={(position) =>
                handleGaugeChange("rearLeftTire", position)
              }
            />

            <Gauge
              noText
              title={"Pneu arrière droit"}
              colors={["#11D979", "#FF6871"]}
              position={
                vehicleInfo.gaugeInfo && vehicleInfo.gaugeInfo["rearRightTire"]
                  ? vehicleInfo.gaugeInfo["rearRightTire"]
                  : 0
              }
              onMouseUp={(position) =>
                handleGaugeChange("rearRightTire", position)
              }
            />
          </Flex>
        </Grid>
      </Card>
    </Flex>
  );
};

export default Vehicle;

/** PRIVATE COMPONENTS */

const PopUp = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { vehicle } = useSelector((state: TbaseReducer) => state.folderReducer);
  const { dataFile, vehicleInfo } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );
  const insuredInfo = dataFile.parties[0].users.find(
    (user) => user.type === "insured"
  );

  const [insuredData, setInsuredData] = useState<TInsuredData>({
    name: insuredInfo?.firstname.split(" ")[1],
    firstname: insuredInfo?.firstname.split(" ")[0],
    licensePlate: vehicle.Immat,
    formula: "",
  });

  const handleChange = (key: keyof TInsuredData, value: string) => {
    setInsuredData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Flex display="flex" direction="column" gap={10} styles={{ padding: 10 }}>
      <InputText
        label="Nom"
        placeholder={""}
        value={insuredData?.name}
        onChange={(value) => handleChange("name", value.target.value)}
      />
      <InputText
        label="Prénom"
        placeholder={""}
        value={insuredData?.firstname}
        onChange={(value) => handleChange("firstname", value.target.value)}
      />
      <InputText
        label="Immatriculation"
        placeholder={""}
        value={insuredData.licensePlate}
        onChange={(value) => handleChange("licensePlate", value.target.value)}
      />
      <InputText
        label="N° de formule"
        placeholder={""}
        value={insuredData?.formula}
        onChange={(value) => handleChange("formula", value.target.value)}
      />
      <Button
        text={"SYNCHRONISER"}
        onClick={() =>
          dispatch(getHistovec({ ...insuredData, folderId: id, vehicleInfo }))
        }
      />
    </Flex>
  );
};
