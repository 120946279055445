import React, { useEffect, useState } from "react";
import {
  Flex,
  HeaderActionCard,
  Card,
} from "@sahadevia/ui-kit-modern-sahadevia";
import Documents from "./Documents";
import ImageList from "./ImageList";
import { FolderAction } from "../../../actions/folderAction";
import { useAppDispatch } from "../../../reducers";

function Ged() {
  const dispatch = useAppDispatch();
  const [tabsActive, setTabsActive] = useState(1);

  const tabs = [
    {
      id: 1,
      text: "Photos",
      onClick: () => setTabsActive(1),
      disabled: false,
      active: tabsActive === 1,
    },
    {
      id: 2,
      text: "Documents",
      onClick: () => setTabsActive(2),
      disabled: false,
      active: tabsActive === 2,
    },
  ];

  const handleGedImageList = (imageList: string[]) => {
    dispatch({ type: FolderAction.UPDATE_GED_IMAGE_LIST, data: imageList });
  };

  useEffect(() => {
    handleGedImageList([]);
  }, [tabsActive]);

  return (
    <Flex display="flex" direction="column" gap={20}>
      <Flex display="flex">
        <HeaderActionCard actionText={tabs} auto />
      </Flex>

      {tabsActive === 1 && (
        <Card title={tabs.find((tab) => tab.id === tabsActive)!.text || ""}>
          <ImageList handleGedImageList={handleGedImageList} />
        </Card>
      )}
      {tabsActive === 2 && (
        <Documents handleGedImageList={handleGedImageList} />
      )}
    </Flex>
  );
}

export default Ged;
