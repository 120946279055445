import { Dispatch } from "redux";
import { _fetch } from "../utils/fetch";

export enum StaticAction {
  GET_STATIC_STATE = "GET_STATIC_STATE",
}

export const getStaticState = (token: string) => {
  return (dispatch: Dispatch<any>) => {
    _fetch(
      "/api/static/load-state",
      StaticAction.GET_STATIC_STATE,
      JSON.stringify({
        token,
      }),
      dispatch
    );
  };
};
