import React, { useRef, useState, useEffect, ChangeEventHandler } from "react";
import { hexToRgb } from "../../../utils/general";

interface ColorPickerProps {
  onValid: Function;
}

interface InputTextProps {
  value: string;
  title: string;
  width?: Number;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

interface CircleColorProps {
  color: string;
  onClick: Function;
}

const InputText = ({ value, title, width = 60, onChange }: InputTextProps) => {
  return (
    <div className="d-flex flex-column w-100">
      <span className="m-auto">{title}</span>
      <input
        className="m-auto"
        style={{
          width: `${width}px`,
        }}
        // @ts-ignore
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const CircleColor = ({ color, onClick }: CircleColorProps) => {
  return (
    <div
      className="m-5 cursor-pointer"
      style={{
        display: "inline-block",
        height: "30px",
        width: "30px",
        borderRadius: "15px",
        backgroundColor: color,
        border: "solid 2px var(--gray)",
      }}
      onClick={() => onClick(color)}
    />
  );
};

function ColorPicker({ onValid }: ColorPickerProps) {
  const [rgb, setRgb] = useState({
    red: "0",
    green: "0",
    blue: "0",
    auto: false,
  });
  const [hexa, setHexa] = useState("#000000");

  useEffect(() => {
    if (!rgb.auto) {
      setHexa(rgbToHex(rgb.red, rgb.green, rgb.blue));
    }
  }, [rgb]);

  useEffect(() => {
    setRgb({
      ...rgb,
      ...hexToRgb(hexa),
      auto: true,
    });
  }, [hexa]);

  const Divider = () => {
    return (
      <div
        className="ml-10 mr-10 bg-gray"
        style={{
          height: "2px",
          width: "calc(100% - 20px)",
          borderRadius: "1px",
        }}
      />
    );
  };

  const componentToHex = (c: string) => {
    var hex = parseInt(c).toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };

  const rgbToHex = (r: string, g: string, b: string) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        zIndex: 10,
      }}
    >
      <div
        className="m-auto bg-white  overflow-hidden"
        style={{
          width: "300px",
          borderRadius: "8px",
        }}
      >
        <div
          className="mt-10 mb-10 ml-10 mr-10"
          style={{
            height: "20px",
            width: "calc(100% - 20px)",
            backgroundColor: hexa,
            borderRadius: "8px",
            border: "solid 2px var(--gray)",
          }}
        />
        <Divider />
        <div className="d-flex mb-10 mt-10">
          <InputText
            value={rgb.red}
            onChange={(e) =>
              setRgb({ ...rgb, red: e.target.value, auto: false })
            }
            title="R"
          />
          <InputText
            value={rgb.green}
            onChange={(e) =>
              setRgb({ ...rgb, green: e.target.value, auto: false })
            }
            title="G"
          />
          <InputText
            value={rgb.blue}
            onChange={(e) =>
              setRgb({ ...rgb, blue: e.target.value, auto: false })
            }
            title="B"
          />
        </div>
        <Divider />
        <div className="d-flex mb-10 mt-10">
          <InputText
            value={hexa}
            onChange={(e) => setHexa(e.target.value)}
            title="#"
            width={180}
          />
        </div>
        <Divider />
        <div className="ml-5 mr-5 mt-5 mb-5 d-flex">
          <CircleColor
            color="#ffffff"
            onClick={(color: string) => setHexa(color)}
          />
          <CircleColor
            color="#000000"
            onClick={(color: string) => setHexa(color)}
          />
          <CircleColor
            color="#ff0000"
            onClick={(color: string) => setHexa(color)}
          />
          <CircleColor
            color="#ff0000"
            onClick={(color: string) => setHexa(color)}
          />
          <CircleColor
            color="#00ff00"
            onClick={(color: string) => setHexa(color)}
          />
          <CircleColor
            color="#0000ff"
            onClick={(color: string) => setHexa(color)}
          />
        </div>
        <div
          className="w-100 bg-gray pt-10 pb-10 text-center cursor-pointer"
          onClick={() => onValid(hexa)}
        >
          <span>Valider</span>
        </div>
      </div>
    </div>
  );
}

export default ColorPicker;
