import { LoaderAction } from "../actions/loaderAction";
import { ModalAction } from "../actions/modalAction";

let API_URL = "";
if (process.env.NODE_ENV === "production") {
  API_URL = "https://" + window.location.host;
}

const ABORT_REQUEST_CONTROLLERS = new Map();

export function abortRequestSafe(key: any, reason = "CANCELLED") {
  ABORT_REQUEST_CONTROLLERS.get(key)?.abort?.(reason);
}

const abortAndGetSignalSafe = (key: any) => {
  abortRequestSafe(key); // abort previous request, if any
  const newController = new AbortController();
  ABORT_REQUEST_CONTROLLERS.set(key, newController);
  return newController.signal;
};

export const _fetch = (
  url: string,
  type: string,
  body: string,
  dispatch: any,
  callback?: any,
  signalKey?: any,
  disableLoader: boolean = false
) => {
  !disableLoader && dispatch({ type: LoaderAction.START_LOADING });

  return fetch(`${API_URL}${url}`, {
    ...(signalKey && { signal: abortAndGetSignalSafe(signalKey) }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      tokenAuth: window.localStorage.getItem("token"),
      ...JSON.parse(body || "{}"),
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      if (callback) {
        callback(res);
      }

      if (
        type.startsWith("UPDATE_") ||
        type.startsWith("ADD_") ||
        type.startsWith("INSERT_") ||
        type.startsWith("DELETE_") ||
        type.startsWith("CHANGE_") ||
        type.startsWith("SEND_") ||
        type.startsWith("NEW_") ||
        type.startsWith("CLOSE_")
      ) {
        //@ts-ignore
        window.updateFolder();
      }

      !disableLoader && dispatch({ type: LoaderAction.STOP_LOADING });
      dispatch({ type, ...res, body: body ? JSON.parse(body) : {} });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ModalAction.OPEN_INFO_MODAL,
        data: {
          title: "Alerte",
          message: "Une erreur est survenue !",
        },
      });
      console.log("API failed");
    });
};
