/* Libs */
import { Flex, Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

/* Utils */
import getUrl from "../../utils/getUrl";

/* Types */
import { TbaseReducer } from "../../types/reducer/reducerType";

type Props = {
  images: string[];
};

const ImageSlider = ({ images }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { id } = useParams();

  const { dataFile } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  // Uses modulo (%) to ensure that the index remains within the range of available images
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  const file = dataFile.files.find(
    (file) => file.token === images[currentIndex]
  );
  return (
    <div style={{ padding: 10 }}>
      <Flex
        display="flex"
        align="center"
        justify="center"
        styles={{ width: "500px" }}
      >
        {images.length === 1 ? (
          <div style={{ overflowX: "auto" }}>
            {file && file.extension !== "pdf" ? (
              <img
                loading="lazy"
                src={getUrl(id, images[currentIndex], 0)}
                alt={`images ${currentIndex}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            ) : (
              <embed
                src={getUrl(id, images[currentIndex])}
                type="application/pdf"
                width="320px"
                height="500px"
              />
            )}
          </div>
        ) : (
          <Flex
            display="flex"
            justify="space-between"
            styles={{ width: "100%" }}
          >
            <Flex
              display="flex"
              align="center"
              styles={{ transform: "rotate(90deg)" }}
            >
              <Icon onClick={prevSlide} icon={"CaretDown"} size={20} />
            </Flex>
            <div style={{ overflowX: "auto" }}>
              {file && file.extension !== "pdf" ? (
                <img
                  loading="lazy"
                  src={getUrl(id, images[currentIndex], 0)}
                  alt={`Slide ${currentIndex}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              ) : (
                <embed
                  src={getUrl(id, images[currentIndex])}
                  type="application/pdf"
                  width="320px"
                  height="500px"
                />
              )}
            </div>

            <Flex
              display="flex"
              align="center"
              styles={{ transform: "rotate(-90deg)" }}
            >
              <Icon onClick={nextSlide} icon={"CaretDown"} size={20} />
            </Flex>
          </Flex>
        )}
      </Flex>
    </div>
  );
};

export default ImageSlider;
