/* Libs */
import {
  Flex,
  HeaderActionCard,
  Icon,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { Dispatch } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useAppDispatch } from "../../../../reducers";

/* Types */
import { TbaseReducer } from "../../../../types/reducer/reducerType";
import {
  TCostingChoc,
  TCostingChocKey,
} from "../../../../types/folder/expertisesType";

/* Actions */
import { FolderAction } from "../../../../actions/folderAction";

/* Components */
import GeneralTable from "./tables/GeneralTable";
import PartsDetailsTable from "./tables/PartsDetailsTable";
import DetailsOperationTable from "./tables/DetailsOperationTable";
import DetailsSrgcTable from "./tables/DetailsSrgcTable";

type Props = {
  currentExpertise: number;
  currentchoc: number;
  setCurrentChoc: Dispatch<React.SetStateAction<number>>;
};

const Costing = ({ currentExpertise, currentchoc, setCurrentChoc }: Props) => {
  const dispatch = useAppDispatch();
  const chocs = useSelector(
    (state: TbaseReducer) =>
      state.folderReducer.expertises[currentExpertise].chocs,
    (oldArray, newArray) =>
      shallowEqual(JSON.stringify(oldArray), JSON.stringify(newArray))
  );

  const handleOnChange = (key: keyof TCostingChoc, item: any) => {
    dispatch({
      type: FolderAction.UPDATE_CHOC_LINE,
      data: { currentExpertise, key, tabsChocIdx: currentchoc, item },
    });
  };

  const addNewRow = (newData: any, key: TCostingChocKey) => {
    dispatch({
      type: FolderAction.ADD_CHOC_LINE,
      data: {
        newData,
        currentExpertise,
        currentchoc,
        key,
      },
    });
  };

  const deleteRow = (key: Exclude<TCostingChocKey, "general">, id: number) => {
    dispatch({
      type: FolderAction.DELETE_CHOC_LINE,
      data: {
        id,
        currentExpertise,
        currentchoc,
        key,
      },
    });
  };

  return (
    <Flex direction="column" gap={20}>
      <Flex justify="start">
        <HeaderActionCard
          actionText={chocs.map((_, idx) => ({
            id: idx,
            text: `Évalutation choc n°${idx + 1}`,
            onClick: () => setCurrentChoc(idx),
            disabled: false,
            active: idx === currentchoc,
          }))}
          endComponent={() => (
            <Icon
              style={{ marginLeft: "20px" }}
              icon={"Add"}
              backgroundHover
              size={20}
              onClick={() =>
                dispatch({
                  type: FolderAction.ADD_CHOC,
                  data: { currentExpertise },
                })
              }
            />
          )}
        />
      </Flex>
      <Flex direction="column">
        <Flex direction="column" gap={20}>
          <PartsDetailsTable
            currentExpertise={currentExpertise}
            currentchoc={currentchoc}
            handleOnChange={handleOnChange}
            addNewRow={addNewRow}
            deleteRow={deleteRow}
          />
          <DetailsOperationTable
            currentExpertise={currentExpertise}
            currentchoc={currentchoc}
            handleOnChange={handleOnChange}
            addNewRow={addNewRow}
            deleteRow={deleteRow}
          />
          <GeneralTable
            currentchoc={currentchoc}
            currentExpertise={currentExpertise}
          />
          <DetailsSrgcTable
            currentExpertise={currentExpertise}
            currentchoc={currentchoc}
            handleOnChange={handleOnChange}
            addNewRow={addNewRow}
            deleteRow={deleteRow}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default Costing;
