/* Libs */
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Flex,
  Header,
  Icon,
  ModalFlex,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* Reducers */
import { useAppDispatch } from "../reducers";

/* Components */
import CustomLink from "../components/general/CustomLink";
import ModalLoader from "../components/modal/ModalLoader";
import icon from "../images/gla.svg";

/* Configs */
import { URLS } from "../configs/URLS";

/* Actions */
import { searchFolders } from "../actions/searchAction";
import { abortRequestSafe } from "../utils/fetch";
import { AuthAction } from "../actions/authAction";
import { ModalAction, openMessageModal } from "../actions/modalAction";

/* Types */
import type { TFolderHistoryInfo } from "../types/localStorage/foldersHistoryType";
import type { TbaseReducer } from "../types/reducer/reducerType";
import ModalMessage from "../components/modal/ModalMessage";
import { get } from "../actions/folderAction";

type DashboardLayoutProps = {
  children: JSX.Element;
};

function DashboardLayout({ children }: DashboardLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const isMobile = window.innerWidth <= 600;

  const { folders } = useSelector((state: TbaseReducer) => state.searchReducer);

  const { loaderQueue } = useSelector(
    (state: TbaseReducer) => state.loaderReducer
  );

  const { redirectSignout, name, role, type } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const {
    confirmModal,
    uploadModal,
    infoModal,
    searchFoldersModal,
    managingHistoryModal,
    customModal,
    flexModal,
    messageModal,
  } = useSelector((state: TbaseReducer) => state.modalReducer);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    if (valueSearch && valueSearch.length > 4) {
      setIsLoading(true);
      abortRequestSafe("SEARCH_FOLDERS");
      dispatch(
        searchFolders(valueSearch, (res) => {
          if (res.success) {
            setIsLoading(false);
          }
        })
      );
    }
  }, [valueSearch]);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
  }, [id]);

  if (redirectSignout) {
    window.localStorage.setItem("token", "");
    return (
      <iframe
        style={{ display: "none" }}
        src={redirectSignout}
        onLoad={() => window.location.reload()}
      />
    );
  }

  const headerLink = [
    {
      text: "Dossiers",
      path: URLS.home,
      active: location.pathname.startsWith("/folder"),
      isMobile,
    },
    {
      text: "Tâches",
      path: URLS.tasks,
      isMobile,
    },
    {
      text: "États",
      path: URLS.status,
      isMobile,
    },
    {
      text: "Bibliothèque",
      path: URLS.library,
      isMobile,
    },
  ];
  return (
    <div
      className="Dashboard-container"
      style={{
        overflow:
          confirmModal.isOpen ||
          uploadModal.isOpen ||
          infoModal.isOpen ||
          managingHistoryModal.isOpen ||
          searchFoldersModal.isOpen ||
          (customModal.isOpen && customModal.target !== "google-map") ||
          messageModal.isOpen ||
          loaderQueue
            ? "hidden"
            : "",
      }}
    >
      <div
        className="Dashboard-container-body"
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
        }}
      >
        <Header
          isLoading={isLoading}
          logo={icon}
          onClickLogo={() => navigate(URLS.home)}
          dropdownData={{
            email: name,
            grade: `${role} ${type}`,
            version: "",
            date: process.env.REACT_APP_BUILD_TIME,
            onProfilClick: () =>
              (window.location.href = "https://www.hubex.fr"),
            onSignoutClick: () => dispatch({ type: AuthAction.SIGNOUT }),
          }}
          valueSearch={valueSearch}
          onChangeSearch={(value) => setValueSearch(value)}
          onFocusSearch={() => setIsSearchActive(true)}
          onBlurSearch={() => setIsSearchActive(false)}
          resultSearch={
            isSearchActive
              ? valueSearch
                ? folders
                    .sort((a, b) => {
                      if (a.numberFolder === valueSearch) return -1;
                      if (b.numberFolder === valueSearch) return 1;
                      return 0;
                    })
                    .map((folder) => ({
                      title: `${folder.numberFolder} / ${folder.vehicleLicensePlate} / ${folder.vehicleBrand}`,
                      onClick: () => {
                        setIsSearchActive(false);
                        navigate(URLS.folder(folder.id));
                      },
                    }))
                : localStorage
                    .getItem("foldersHistory")
                    ?.split(",")
                    .map((reference) => {
                      const folderInfo: TFolderHistoryInfo[] = JSON.parse(
                        localStorage.getItem("folderHistoryInfo") || "[]"
                      );

                      const folder = folderInfo.find((folder) =>
                        folder.hasOwnProperty(reference)
                      );

                      return {
                        title: folder ? folder[Number(reference)].text : "",
                        onClick: () => {
                          setIsSearchActive(false);
                          navigate(URLS.folder(reference));
                        },
                      };
                    })
                    .reverse()
              : undefined
          }
          linkChildren={
            <>
              {headerLink.map((link, idx) => (
                <CustomLink
                  key={idx}
                  text={link.text}
                  path={link.path}
                  isMobile={link.isMobile}
                />
              ))}
            </>
          }
          userIcon=""
          userIconText={(name || "")
            .split(" ")
            .map((n) => n[0])
            .join("")
            .toUpperCase()}
          actions={
            location.pathname !== URLS.home ? (
              <Flex display="flex" gap={10}>
                <Icon
                  backgroundHover
                  infoText="Message IxPerience"
                  icon={"Ixperience"}
                  size={14}
                  onClick={() =>
                    dispatch(openMessageModal({ source: "ixperience" }))
                  }
                />
                <Icon
                  backgroundHover
                  infoText="Mail"
                  icon={"Envelope"}
                  size={20}
                  onClick={() =>
                    dispatch(openMessageModal({ source: "mail-sortant" }))
                  }
                />
              </Flex>
            ) : null
          }
        />
        <div
          className="p-20"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 40px)",
          }}
        >
          {children}
        </div>
      </div>
      {messageModal.isOpen ? <ModalMessage /> : null}
      {loaderQueue ? <ModalLoader /> : null}
      {flexModal.isOpen && (
        <ModalFlex
          onClose={() => dispatch({ type: ModalAction.CLOSE_FLEX_MODAL })}
          style={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "10px",
          }}
          children={flexModal.children ? flexModal.children : <></>}
          position={flexModal.position ?? "rightBottom"}
          target={flexModal.target || "root"}
        />
      )}
    </div>
  );
}

export default DashboardLayout;
