import { SearchAction } from "../actions/searchAction";
import type { TOrder, TsearchInitialState } from "../types/search/searchType";
import { removeEmptyKey } from "../utils/general";

export const initialState = {
  isLoad: false,
  isLoadCount: true,
  order: {} as TOrder,
  filters: {},
  activeFilters: {},
  activePage: 1,
  totalItems: -1,
  items: [],
  optionsInsurance: [],
  optionsNatureEvent: [],
  optionsNatureExpertise: [],
  optionsRegion: [],
  optionsAgency: [],
  optionsExpert: [],
  optionsAssistant: [],
  optionsFolderStatus: [],
  folders: [],
} satisfies TsearchInitialState;

export const searchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SearchAction.LOAD_SEARCH:
      return {
        ...state,
        isLoad: true,
      };
    case SearchAction.CHANGE_ACTIVE_PAGE:
      return {
        ...state,
        isLoad: true,
        activePage: action.data,
      };
    case SearchAction.UPDATE_ORDER:
      return {
        ...state,
        isLoad: true,
        order: action.data,
      };
    case SearchAction.UPDATE_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case SearchAction.SET_ACTIVE_FILTERS:
      return {
        ...state,
        isLoadCount: true,
        activeFilters: removeEmptyKey(state.filters),
      };
    case SearchAction.COUNT_FOLDERS:
      return {
        ...state,
        isLoadCount: false,
        totalItems: action.data,
      };
    case SearchAction.GET_FOLDERS:
      return {
        ...state,
        isLoad: false,
        items: action.data,
      };
    case SearchAction.SEARCH_FOLDERS:
      return {
        ...state,
        isLoad: false,
        folders: action.data,
      };
    case SearchAction.GET_OPTIONS:
      return {
        ...state,
        optionsInsurance: action.optionsInsurance,
        optionsNatureEvent: action.optionsNatureEvent,
        optionsNatureExpertise: action.optionsNatureExpertise,
        optionsRegion: action.optionsRegion,
        optionsAgency: action.optionsAgency,
        optionsExpert: action.optionsExpert,
        optionsAssistant: action.optionsAssistant,
        optionsFolderStatus: action.optionsFolderStatus,
      };
    default:
      return state;
  }
};
