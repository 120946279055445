import { useNavigate } from "react-router-dom";
import { Button } from "@sahadevia/ui-kit-modern-sahadevia";
import { URLS } from "../configs/URLS";

function NotFoundView() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "600px",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <h3
          style={{
            margin: 0,
            marginBottom: 20,
            fontSize: 26,
          }}
        >
          Page non trouvée :/
        </h3>
        <p
          style={{
            margin: 0,
            marginBottom: 20,
          }}
        >
          Désolé, la page que vous cherchez est introuvable, vous pouvez
          retournez à la page d’accueil en utilisant le bouton ci-dessous
        </p>
        <Button
          text="Retour"
          onClick={() => navigate(URLS.home, { replace: true })}
        />
        <h2
          style={{
            margin: 0,
            marginTop: 20,
            backgroundImage: "linear-gradient(#504cfc, #aa3ffe)",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
            WebkitBackgroundClip: "text",
            fontSize: 200,
          }}
        >
          404
        </h2>
      </div>
    </div>
  );
}

export default NotFoundView;
