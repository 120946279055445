import { _fetch } from "../utils/fetch";

export enum AuthAction {
  REFRESH_TOKEN = "REFRESH_TOKEN",
  SIGNOUT = "SIGNOUT",
}

export const refreshToken = (
  token: string,
  url: string,
  disableLoader?: boolean
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/refresh-token",
      AuthAction.REFRESH_TOKEN,
      JSON.stringify({
        token,
        url,
      }),
      dispatch,
      null,
      disableLoader
    );
  };
};

export const signout = (url: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/signout",
      AuthAction.SIGNOUT,
      JSON.stringify({
        url,
      }),
      dispatch
    );
  };
};
