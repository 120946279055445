import moment from "moment";
import {
  faFileAlt,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

const getNumber = (int) => {
  return new Intl.NumberFormat("fr-FR").format(int);
};

const getDate = (date) => {
  if (!date || date === "0000-00-00") {
    return null;
  }

  return moment(date).format("DD/MM/YYYY");
};

const getCurrencyValue = (value, digits = 2) => {
  if (value == null) {
    return "";
  }

  const optionsCurrency = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", optionsCurrency).format(value);
};

const getIconFile = (extension) => {
  extension = extension ? extension.toLowerCase() : "";

  if (extension.includes("pdf")) {
    return [faFilePdf, "red"];
  } else if (extension.includes("doc") || extension.includes("docx")) {
    return [faFileWord, "blue"];
  } else if (extension.includes("xls") || extension.includes("xlsx")) {
    return [faFileExcel, "green"];
  } else if (
    extension.includes("png") ||
    extension.includes("jpg") ||
    extension.includes("jpeg")
  ) {
    return [faImage, "purple"];
  } else {
    return [faFileAlt, "orange"];
  }
};

const downloadFile = async (
  path,
  filename,
  isDownload = false,
  isFs = false
) => {
  if (!isDownload && !isFs) {
    let link = document.createElement("a");
    link.href = path;
    link.setAttribute("download", filename);
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  } else if (isFs) {
    const response = await fetch(path);
    const blob = await response.blob();

    return await blob.arrayBuffer();
  } else {
    const response = await fetch(path);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));

    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }
};

const resizeImage = (base64, mime, callback = (base64) => base64) => {
  const max = 1280;
  const image = new Image();
  image.src = base64;
  image.onload = () => {
    let initialWidth = image.width;
    let initialHeight = image.height;
    let finalWidth = 0;
    let finalHeight = 0;

    if (initialWidth > initialHeight) {
      if (initialWidth > max) {
        finalWidth = max;
        finalHeight = (finalWidth * initialHeight) / initialWidth;
      } else {
        finalWidth = initialWidth;
        finalHeight = initialHeight;
      }
    } else {
      if (initialHeight > max) {
        finalHeight = max;
        finalWidth = (finalHeight * initialWidth) / initialHeight;
      } else {
        finalWidth = initialWidth;
        finalHeight = initialHeight;
      }
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = finalWidth;
    canvas.height = finalHeight;
    ctx.drawImage(image, 0, 0, finalWidth, finalHeight);
    base64 = canvas.toDataURL(mime, 0.9);

    callback(base64);
  };
  image.onerror = () => {
    callback(base64);
  };
};

function isOneKeyNotEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== "") {
      return true; // Au moins une clé n'est pas vide
    }
  }
  return false; // Toutes les clés sont vides
}

/**
 * Filter an object by removing properties with empty values
 * @param {Object} obj The object to be filtered.
 * @returns {Object} A new object containing only non-empty properties.
 */
function removeEmptyKey(obj) {
  for (let key in obj) {
    if (!obj[key] || !obj[key].length) {
      delete obj[key];
    }
  }
  return obj;
}

const getTypeUser = (type) => {
  switch (type) {
    case "insured":
      return "Lésé";
    case "insurance":
      return "Assurance";
    case "principal":
      return "Mandant";
    case "repairman":
      return "Réparateur";
    case "expert":
      return "Expert";
    case "assistant":
      return "Assistant";
    default:
      return "Aucun";
  }
};

const getInitials = (value) => {
  if (!value) {
    return "";
  } else if (!value.includes(" ")) {
    return value;
  }

  let result = [];
  result = value.split(" ");

  return result.map((n) => n[0]).join("");
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16).toString(),
        green: parseInt(result[2], 16).toString(),
        blue: parseInt(result[3], 16).toString(),
      }
    : null;
};

export {
  getNumber,
  getDate,
  getCurrencyValue,
  getIconFile,
  downloadFile,
  resizeImage,
  isOneKeyNotEmpty,
  getTypeUser,
  getInitials,
  removeEmptyKey,
  hexToRgb,
};
