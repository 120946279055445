import moment from "moment";
import { FolderAction } from "../actions/folderAction";
import type {
  TDataFile,
  TDataMaileva,
  TFileType,
  TfolderInitialState,
  TGoogleMapData,
  TVehicle,
} from "../types/folder/folderType";
import type { TManaging } from "../types/folder/actions/managingType";
import {
  TCostingChoc,
  TCostingChocKey,
  TDetailsSRGC,
  TExpertises,
  TGeneralAmount,
  TPartsDetails,
  TdetailsOperation,
} from "../types/folder/expertisesType";
import { TFolderHistoryInfo } from "../types/localStorage/foldersHistoryType";

export const folderInitialState = {
  isLoad: false,
  dateUpdate: "",
  dateAppointmennt: "",
  numberFolder: "",
  nameInsurance: "",
  logoInsurance: "",
  nameAgency: "",
  nameClient: "",
  nameRepairman: "",
  initialExpert: "",
  initialAssistant: "",
  statusHome: "",
  statusColor: "",
  folderId: null,
  dataFile: {} as TDataFile,
  fileTypes: [] as TFileType[],
  availabilityAgency: [],
  agencyEmail: "",
  agencyId: null,
  agencyColor: "",
  questionList: null,
  agencyCoordinates: undefined,
  allAgency: {} as TGoogleMapData,
  vehicle: {} as TVehicle,
  vehicleInfo: { histovec: null, gaugeInfo: null },
  gedImageList: [] as string[],
  // EXPERTISES FAKE DATA CHANGE WHEN THE BACK IS READY
  expertises: [] as TExpertises[],
  dataMaileva: [] as TDataMaileva[],
} satisfies TfolderInitialState;

export const folderReducer = (
  state = folderInitialState,
  action: Record<string, any>
) => {
  const dataFile = state.dataFile;
  const expertises = state.expertises;
  switch (action.type) {
    case "LOAD_FOLDER":
      return {
        ...state,
        isLoad: true,
      };
    case FolderAction.GET_FOLDER:
      // start - add local history
      let foldersHistory = (localStorage.getItem("foldersHistory") || "").split(
        ","
      );

      let foldersHistoryInfo: TFolderHistoryInfo[] = JSON.parse(
        localStorage.getItem("folderHistoryInfo") || "[]"
      );

      foldersHistoryInfo = foldersHistoryInfo.filter(
        (folder) => !folder.hasOwnProperty(action.body.id)
      );

      if (foldersHistory.includes(action.body.id)) {
        foldersHistory.splice(foldersHistory.indexOf(action.body.id), 1);
      }

      foldersHistory.push(action.body.id);

      foldersHistory = foldersHistory.filter((reference) => reference);

      localStorage.setItem("foldersHistory", foldersHistory.join(","));

      foldersHistoryInfo.push({
        [action.body.id]: {
          text: `${action.numberFolder} / ${action.file.vehicle.license_plate} / ${action.file.vehicle.brand}`,
          numberFolder: action.numberFolder,
        },
      });

      localStorage.setItem(
        "folderHistoryInfo",
        JSON.stringify(foldersHistoryInfo)
      );
      // end - add local history
      console.log(action);

      return {
        ...state,
        isLoad: false,
        dateUpdate: action.dateUpdate,
        dateAppointmennt: action.dateAppointmennt,
        numberFolder: action.numberFolder,
        nameInsurance: action.nameInsurance,
        logoInsurance: action.logoInsurance,
        nameAgency: action.nameAgency,
        nameClient: action.nameClient,
        nameRepairman: action.nameRepairman,
        statusHome: action.statusHome,
        statusColor: action.statusColor,
        folderId: action.folderId,
        dataFile: action.file,
        fileTypes: action.fileTypes,
        initialExpert: action.initialExpert,
        initialAssistant: action.initialAssistant,
        agencyEmail: action.agencyEmail,
        agencyId: action.agencyId,
        agencyColor: action.agencyColor,
        questionList: action.questionList,
        agencyCoordinates: action.agencyCoordinates,
        vehicle: action.vehicleData,
        vehicleInfo: action.vehicleInfo,
        expertises: action.expertiseData,
        dataMaileva: action.dataMaileva,
      };
    case FolderAction.DELETE_FILES:
      dataFile.files = dataFile.files.filter((file) => {
        return !action.body.tokens.includes(file.token);
      });
      return {
        ...state,
        isLoad: false,
        dataFile,
      };
    case FolderAction.UPDATE_DATA_FILE:
      if (action.success) {
        dataFile.files.forEach((file) => {
          if (file.token === action.body.token) {
            file.filename = action.body.filename;
            file.type = action.body.type;
          }
        });
      }

      return {
        ...state,
        isLoad: false,
        dataFile,
      };
    case FolderAction.CHANGE_FOLDER_FILES:
      if (!action.body.isCopy) {
        dataFile.files = dataFile.files.filter(
          (file) => !action.body.fileTokens.includes(file.token)
        );
      }

      return {
        ...state,
        isLoad: false,
        dataFile,
      };

    case FolderAction.SEND_MESSAGE:
      return {
        ...state,
        isLoad: false,
      };
    case FolderAction.INSERT_FILES:
      if (action.success) {
        dataFile.files = [...action.data, ...dataFile.files];
      }

      return {
        ...state,
        isLoad: false,
        dataFile,
      };
    case FolderAction.GET_DOCUMENT:
      return {
        ...state,
        isLoad: false,
      };
    case FolderAction.CONVERT_PICTURE_TO_PDF:
      dataFile.files.forEach((file) => {
        if (action.body.file.token === file.token) {
          file.extension = action.data.extension;
          file.path = action.data.path;
        }
      });

      return {
        ...state,
        isLoad: false,
      };
    case FolderAction.NEW_MANAGING:
      const data = action.body.data;

      dataFile.managings.unshift({
        attachments: [],
        date: data.date,
        id: action.data.id,
        fromSource: data.fromSource,
        toSource: data.toSource,
        status: null,
        subject: data.subject,
        type: null,
        user: action.data.lacourId,
        color: data.grade,
        updatedAt: "",
        updatedSubject: [],
        deleted: 0,
        updatedCount: 0,
      });

      return {
        ...state,
        dataFile,
        isLoad: false,
      };
    case FolderAction.UPDATE_MANAGING:
      dataFile.managings.forEach((element) => {
        if (element.id === action.body.managingId) {
          element.color = action.body.data.newColor ?? element.color;
          element.subject = action.body.data.newSubject ?? element.subject;
          element.deleted = action.body.data.deleted ?? element.deleted;
          // TODO: See to improve at the subject update we take the id of the line added for display the book otherwise we take updatedcount already present
          element.updatedCount =
            action.updatedManagingId ??
            dataFile.managings.find(
              (managing) => managing.id === action.body.managingId
            )?.updatedCount;

          if (element.updatedSubject) {
            const ids = element.updatedSubject.map((managing) => managing.id);

            element.updatedSubject.unshift({
              id: Math.max(...ids),
              managingId: action.body.managingId,
              updatedManagingId: action.updatedManagingId,
              subject: action.body.data.oldSubject,
              color: action.body.data.oldColor,
              insertDate: action.body.data.insertDate,
            });
          }
        }
      });
      return {
        ...state,
        dataFile,
      };
    case FolderAction.GET_UPDATED_MANAGING:
      if (action.success) {
        const managing = dataFile.managings.find(
          (managing: TManaging) => managing.id === action.body.managingId
        );
        if (managing) {
          managing.updatedSubject = action.data;
        }
      }

      return {
        ...state,
        isLoad: false,
      };
    case FolderAction.UPDATE_PRESELECT_FILE:
      action.body.data.files.forEach(
        (f: { id: number; preselect: string[] }) => {
          const file = dataFile.files.find((file) => file.id === f.id);

          if (!file) {
            return;
          } else {
            file.preselect = f.preselect;
          }
        }
      );

      return {
        ...state,
        dataFile,
        isLoad: false,
      };

    case FolderAction.SEND_NEW_INTERNAL_INFORMATION_MESSAGE:
      const date = moment(new Date().toString()).format("DD-MM-YYYY");

      dataFile.internalInformationMessage.push({
        ...action.body.data,
        insertDate: date,
      });

      return {
        ...state,
        dataFile,
        isLoad: false,
      };

    case FolderAction.DELETE_INTERNAL_INFORMATION_MESSAGE:
      const messageToDelete = dataFile.internalInformationMessage.findIndex(
        (obj) => obj.id === action.body.data.messageId
      );

      dataFile.internalInformationMessage.splice(messageToDelete, 1);
      return {
        ...state,
        dataFile,
        isLoad: false,
      };

    case FolderAction.NEW_TASK:
      dataFile.tasks.push({ ...action.body.data });

      return {
        ...state,
        dataFile,
        isLoad: false,
      };

    case FolderAction.DELETE_TASK:
      const task = dataFile.tasks.find(
        (task) => task.id === action.body.data.taskId
      );
      if (task) task.isDelete = 1;
      return {
        ...state,
        dataFile,
        isLoad: false,
      };

    case FolderAction.CLOSE_TASK:
      const taskToClose = dataFile.tasks.find(
        (task) => task.id === action.body.data.taskId
      );
      if (taskToClose) taskToClose.closeDate = action.body.data.closeDate;
      return {
        ...state,
        dataFile,
        isLoad: false,
      };

    case FolderAction.ADD_EXPERTISE:
      const expertiseAddCopy = [...state.expertises];
      expertiseAddCopy.push({
        id: expertiseAddCopy.length + 1, // fake id
        conclusion: [],
        condition: [],
        chocs: [
          {
            partsDetails: [],
            detailsOperation: [],
            detailsSRGC: [],
            general: [],
            dataGeneral: {
              discount: 0,
              pourcRemiseIngr: 0,
              pourcRemiseMo: 0,
              pourcRemisePiece: 0,
            },
          },
        ],
        isLock: false,
      });

      return {
        ...state,
        expertises: expertiseAddCopy,
        isLoad: false,
      };
    case FolderAction.ADD_CHOC:
      const expertise = state.expertises;
      if (expertise[action.data.currentExpertise]) {
        expertise[action.data.currentExpertise].chocs = [
          ...expertise[action.data.currentExpertise].chocs,
          {
            partsDetails: [],
            detailsOperation: [],
            detailsSRGC: [],
            general: [],
            dataGeneral: {
              discount: 0,
              pourcRemiseIngr: 0,
              pourcRemiseMo: 0,
              pourcRemisePiece: 0,
            },
          },
        ];
      }

      return {
        ...state,
        expertises: expertise,
        isLoad: false,
      };
    case FolderAction.ADD_CHOC_LINE:
      const expertiseForAddCopy = state.expertises;
      const key: keyof Omit<TCostingChoc, "dataGeneral"> = action.data.key;

      if (expertiseForAddCopy[action.data.currentExpertise]) {
        const chocs: TCostingChoc[typeof key] =
          expertiseForAddCopy[action.data.currentExpertise].chocs[
            action.data.currentchoc
          ][key];

        expertiseForAddCopy[action.data.currentExpertise].chocs[
          action.data.currentchoc
        ][key] = [...chocs, action.data.newData];
      }

      return {
        ...state,
        expertises: expertiseForAddCopy,
        isLoad: false,
      };
    case FolderAction.UPDATE_CHOC_LINE:
      const expertiseForUpdateCopy = state.expertises;
      const expertiseToUpdate =
        expertiseForUpdateCopy[action.data.currentExpertise];

      if (expertiseToUpdate) {
        const key: keyof Omit<TCostingChoc, "dataGeneral"> = action.data.key;
        const chocs: (
          | TPartsDetails
          | TDetailsSRGC
          | TdetailsOperation
          | TGeneralAmount
        )[] = expertiseToUpdate.chocs[action.data.tabsChocIdx][key];

        if (key === "partsDetails" || key === "detailsSRGC") {
          const chocsToUpdate = chocs.map((choc) => {
            if (choc.id === action.data.item.id) {
              choc = action.data.item;
            }
            return choc;
          });
          //@ts-ignore
          expertiseToUpdate.chocs[action.data.tabsChocIdx][key] = chocsToUpdate;
        } else if (key === "detailsOperation") {
          const chocsToUpdate = chocs.map((choc) => {
            if (choc.id === action.data.item.id) {
              choc = action.data.item;
            }
            return choc;
          });
          //@ts-ignore
          expertiseToUpdate.chocs[action.data.tabsChocIdx][key] = chocsToUpdate;
        }
      }

      return {
        ...state,
        expertises: expertiseForUpdateCopy,
        isLoad: false,
      };
    case FolderAction.DELETE_CHOC_LINE:
      const expertiseForDeleteChocCopy = state.expertises;
      const chocKey: Exclude<TCostingChocKey, "dataGeneral"> = action.data.key;

      const chocsTable: (
        | TPartsDetails
        | TdetailsOperation
        | TDetailsSRGC
        | TGeneralAmount
      )[] =
        expertiseForDeleteChocCopy[action.data.currentExpertise].chocs[
          action.data.currentchoc
        ][chocKey];

      //@ts-ignore
      expertiseForDeleteChocCopy[action.data.currentExpertise].chocs[
        action.data.currentchoc
      ][chocKey] = chocsTable.filter((item) => item.id !== action.data.id);

      return {
        ...state,
        expertises: expertiseForDeleteChocCopy,
        isLoad: false,
      };
    case FolderAction.GET_AVAILABILITY_AGENCY:
      return {
        ...state,
        availabilityAgency: action.data,
        isLoad: false,
      };
    case FolderAction.UPDATE_QUESTION_LIST:
      const { questionId, value } = action.body.data;
      let { questionList } = action.body.data;

      if (questionList) {
        questionList = {
          ...questionList,
          ...{ [questionId]: value },
        };
      }

      return {
        ...state,
        questionList,
        isLoad: false,
      };
    case FolderAction.GET_ALL_AGENCY:
      return {
        ...state,
        allAgency: action.data,
        isLoad: false,
      };
    case FolderAction.GET_HISTOVEC:
      return {
        ...state,
        vehicleInfo: { ...state.vehicleInfo, histovec: action.data },
        isLoad: false,
      };
    case FolderAction.UPDATE_GAUGE_INFO:
      return {
        ...state,
        vehicleInfo: action.data,
        isLoad: false,
      };
    case FolderAction.UPDATE_VEHICLE_INFO:
      return {
        ...state,
        vehicle: { ...state.vehicle, ...action.data },
        isLoad: false,
      };
    case FolderAction.UPDATE_GED_IMAGE_LIST:
      state.gedImageList = action.data;

      return {
        ...state,
        isLoad: false,
      };
    default:
      return state;
  }
};
