import { IconNameType, colorType } from "@sahadevia/ui-kit-modern-sahadevia";

export type TManaging = {
  attachments: [];
  date: string;
  id: number;
  fromSource: TSources;
  toSource: TSources;
  status: null | string;
  subject: string;
  type: null | string;
  user: string;
  color: colorType;
  updatedAt: string;
  updatedSubject: TUpdatedManaging[];
  deleted: number;
  updatedCount: number;
  messages?: string;
  fromEmail?: string;
  emailDate?: string;
  metadata?: TManagingMetadata;
};

export type TManagingMetadata = {
  to: string[];
  from: string;
  date: string;
};

export type TUpdatedManaging = {
  id: number;
  managingId: number;
  updatedManagingId: number;
  subject: string;
  color: number;
  insertDate: string;
};

export enum ELevelColor {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
  LEVEL_4 = 4,
}

export type TitemPDF = {
  Date: string;
  De: string;
  Libelle: string;
  Source: string;
  PJ: number;
};

export type TOptionsDropdownAction = {
  title: string;
  icon: IconNameType;
  size: number;
  onClick: () => void;
};

export type TSources =
  | "manuel"
  | "lacour"
  | "pixtransfer"
  | "ixperience"
  | "redaxion"
  | "pilotage-cabinet"
  | "evalcar"
  | "ega"
  | "mail-entrant"
  | "mail-sortant"
  | "darva"
  | "gla-software"
  | "actions"
  | "suivis";
