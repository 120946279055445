/* Libs */
import {
  Button,
  Flex,
  Icon,
  InputText,
  Pagination,
  Table,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* Actions */
import { searchFolders, resetSearchFolders } from "../../actions/searchAction";
import { ModalAction } from "../../actions/modalAction";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Configs */
import { URLS } from "../../configs/URLS";
import { PAGE_LENGTH } from "../../configs/pagination";

/* Utils */
import { getDate, getCurrencyValue, getInitials } from "../../utils/general";

/* Components */
import ModalLayout from "../../layouts/ModalLayout";

/* Types */
import type { TbaseReducer } from "../../types/reducer/reducerType";

interface ModalSearchFoldersProps {
  onValid: Function;
}

const ModalSearchFolders = ({ onValid }: ModalSearchFoldersProps) => {
  const { folders } = useSelector((state: TbaseReducer) => state.searchReducer);
  const [currentRow, setCurrentRow] = useState(-1);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [reference, setReference] = useState<string>("");
  const [modalSearchActivePage, setModalSearchActivePage] = useState<number>(1);

  useEffect(() => {
    if (reference && folders.length === 1) {
      dispatch(resetSearchFolders());
      onValid(folders[0].id);
    }
  }, [folders.length]);

  const itemsTable = (folders as any).map((item: any) => ({
    ...item,
    sortNumberFolder: Number(item.numberFolder),
    dateMission: getDate(item.dateMission),
    dateAppointmennt: getDate(item.dateAppointmennt),
    dateVisit: getDate(item.dateVisit),
    dateClosing: getDate(item.dateClosing),
    totalReportDutyFree: item.totalReportDutyFree
      ? getCurrencyValue(item.totalReportDutyFree)
      : null,
    sortTotalReportDutyfree: item.totalReportDutyFree,
    vradeDutyFree: item.vradeDutyFree
      ? getCurrencyValue(item.vradeDutyFree)
      : null,
    nameExpert: getInitials(item.nameExpert),
    nameAssistant: getInitials(item.nameAssistant),
    statusHome: item.statusHome ? (
      <div
        className="p-10"
        style={{
          backgroundColor: `var(--${item.statusColor})`,
          borderRadius: "8px",
          color: "var(--white)",
        }}
      >
        {item.statusHome}
      </div>
    ) : null,
  }));

  return (
    <ModalLayout styles={{ padding: 50 }}>
      <Table
        auto
        style={{ minHeight: "500px", height: "auto" }}
        headerComponent={
          <Flex display="flex" styles={{ padding: 10 }} justify="space-between">
            <Flex display="flex" gap={10} align="center">
              <InputText
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13 && reference) {
                    dispatch(searchFolders(reference));
                  }
                }}
                value={reference}
                onChange={({ target }: any) => setReference(target.value)}
                placeholder={
                  "N° dossier / N° sinistre / Immatriculation / Nom assuré"
                }
              />
              <Button
                style={{ height: 31 }}
                text={"Rechercher"}
                onClick={() => {
                  if (reference) {
                    dispatch(searchFolders(reference));
                  }
                }}
                icon={<Icon icon={"Search"} size={20} color="var(--white)" />}
              />
            </Flex>

            <div style={{ position: "absolute", top: 10, right: 10 }}>
              <Icon
                icon={"Cross"}
                size={20}
                onClick={() =>
                  dispatch({ type: ModalAction.CLOSE_SEARCH_FOLDERS_MODAL })
                }
              />
            </div>
          </Flex>
        }
        lastColumnSticky
        onRowClick={(currentRow) => {
          onValid(currentRow.id);

          navigate(URLS.folder(currentRow.id));
          dispatch({ type: ModalAction.CLOSE_SEARCH_FOLDERS_MODAL });
        }}
        data={itemsTable.slice(
          (modalSearchActivePage - 1) * PAGE_LENGTH,
          modalSearchActivePage * PAGE_LENGTH
        )}
        columns={[
          {
            key: "numberFolder",
            sortKey: "sortNumberFolder",
            label: "Référence",
            canBeSorted: true,
          },
          {
            key: "nameRegion",
            label: "Région",
          },
          {
            key: "nameAgency",
            label: "Agence",
          },
          {
            key: "nameRepairman",
            sortKey: "nameRepairman",
            label: "Réparateur",
            canBeSorted: true,
            width: 160,
          },
          {
            key: "nameExpert",
            label: "Expert",
          },
          {
            key: "nameAssistant",
            label: "Secrétaire",
            width: 120,
          },
          {
            key: "nameInsurance",
            label: "Compagnie",
          },
          {
            key: "nameClient",
            label: "Client",
            width: 160,
          },
          {
            key: "phoneClient",
            label: "Téléphone",
          },
          {
            key: "vehicleBrand",
            label: "Marque",
          },
          {
            key: "vehicleModel",
            label: "Modèle",
            width: 120,
          },
          {
            key: "vehicleLicensePlate",
            label: "Immat.",
          },
          {
            key: "dateMission",
            label: "Date mis.",
          },
          {
            key: "dateAppointmennt",
            label: "Date RDV",
          },
          {
            key: "dateVisit",
            label: "Date vis.",
          },
          {
            key: "natureExpertise",
            label: "Nat. expertise",
            width: 120,
          },
          {
            key: "natureEvenment",
            label: "Nat. évènement",
          },
          {
            key: "totalReportDutyFree",
            sortKey: "sortTotalReportDutyfree",
            label: "Coût rép. HT",
            canBeSorted: true,
          },
          {
            key: "vradeDutyFree",
            label: "VRADE HT",
            canBeSorted: true,
          },
          {
            key: "dateClosing",
            label: "Date clôt.",
          },
          {
            key: "statusHome",
            label: "État",
          },
        ]}
        context={{ currentRow, setCurrentRow }}
        currentPage={modalSearchActivePage}
        itemsPerPage={PAGE_LENGTH}
        footerComponent={
          <>
            {!!itemsTable.length && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "16px",
                }}
              >
                <Pagination
                  totalItems={folders.length}
                  itemsPerPage={PAGE_LENGTH}
                  onPageChange={setModalSearchActivePage}
                />
              </div>
            )}
          </>
        }
      />
    </ModalLayout>
  );
};

export default ModalSearchFolders;
