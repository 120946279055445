/* Libs */
import { Dispatch, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Flex,
  HeaderActionCard,
  InputSelect,
  InputText,
  InputTextArea,
  MultipleSelect,
  Plus,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useParams } from "react-router-dom";
import moment from "moment";

/* Components */
import ModalLayout from "../../layouts/ModalLayout";
import ImageSlider from "../general/ImageSilder";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Types */
import type { TbaseReducer } from "../../types/reducer/reducerType";
import { PartiesTypes, TFile } from "../../types/folder/folderType";
import type { Toptions } from "../../types/search/searchType";
import { TResultBase } from "../../types/app/appType";

/* Actions */
import {
  insertMessage,
  sendEmail,
  sendMessage,
} from "../../actions/folderAction";
import { ModalAction } from "../../actions/modalAction";

/* Utils */
import getUrl from "../../utils/getUrl";
import { getInvalidEmails } from "../../utils/getInvalidEmails";

/* IMG */
import { previewTemplate } from "../../utils/managing/previewTemplate";
import { TManaging } from "../../types/folder/actions/managingType";

const ModalMessage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { messageModal } = useSelector(
    (state: TbaseReducer) => state.modalReducer
  );
  const { numberFolder, dataFile, agencyEmail, gedImageList } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );
  const { ixperienceMessageType } = useSelector(
    (state: TbaseReducer) => state.staticReducer
  );
  const { name, agencyId, userId } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const [to, setTo] = useState<string[]>([]);
  const [inputIdx, setInputIdx] = useState<number[]>([0]);
  const [messageType, setMessageType] = useState<number>(14); // default type "Divers"
  // TODO: refacto this part
  const [picturesSelected, setPicturesSelected] = useState<string[]>(
    messageModal.attachments
      ? messageModal.attachments
      : [] || gedImageList.length
      ? gedImageList
      : []
  );
  const [optionnalMail, setOptionnalMail] = useState<string[]>([]);
  const [errorEmailIdx, setErrorEmailIdx] = useState<number[]>([]);
  const [messageTabs, setMessageTabs] = useState<"prev" | "pj">("prev");
  const [message, setMessage] = useState<string>(
    messageModal.messages && !messageModal.reply
      ? messageModal.messages
      : messageModal.messageOptions === "ixperience"
      ? `Bonjour,\n\nCordialement,\n${name}\n${
          dataFile.agency.name || "GROUPE LANG & ASSOCIÉS"
        }`
      : `Bonjour,\n\nCordialement,`
  );
  const [subject, setSubject] = useState<string>(
    messageModal.subject
      ? messageModal.subject
      : `${dataFile.agency.name} / Sinistre N°${dataFile.sinister.number} / Immat ${dataFile.vehicle.license_plate} / Dossier N°${dataFile.mission.number}`
  );
  const { fromEmail, metadata, reply } = messageModal;

  const getUsers = (): Toptions[] => {
    let users: any = [];

    dataFile.parties.forEach((party) => {
      party.users.forEach((user) => {
        const isEmail = ["mail-sortant", "mail-entrant"].includes(
          messageModal.messageOptions || ""
        );
        const text = `${user.firstname}  ${isEmail ? user.email : ""}`;

        if (user.firstname && user.email) {
          // TODO: Type insurance not add for ixperience
          switch (user.type) {
            case PartiesTypes.EXPERT || PartiesTypes.ASSISTANT:
              users.push({
                value: `${isEmail ? user.email : "agency"}`,
                text,
              });
              break;
            case PartiesTypes.REPAIRMAN:
              users.push({
                value: `${isEmail ? user.email : "garage"}`,
                text,
              });
              break;
            case PartiesTypes.PRINCIPALE:
              users.push({
                value: `${isEmail ? user.email : "principale"}`,
                text,
              });
              break;
            case PartiesTypes.INSURED:
              users.push({
                value: `${isEmail ? user.email : "client"}`,
                text,
              });
              break;
            default:
              break;
          }
        }
      });
    });

    return users;
  };

  const onSendMessage = () => {
    dispatch(
      sendMessage(
        id,
        numberFolder,
        messageType,
        "agency",
        [...to],
        picturesSelected.map(
          (token) => dataFile.files.find((file) => file.token === token)!
        ),
        subject,
        message,
        (result: TResultBase) => {
          if (result.success) {
            dispatch({ type: ModalAction.CLOSE_MESSAGE_MODAL });
            dispatch(
              insertMessage(
                {
                  toSource: "ixperience",
                  folderId: Number(id),
                  fromId: userId,
                  metadata: { to },
                  filesToken: picturesSelected.map(
                    (token) =>
                      dataFile.files.find((file) => file.token === token)
                        ?.token!
                  ),
                  message,
                  subject,
                  fromSource: "gla-software",
                  grade: 1,
                },
                (result: TResultBase) => {
                  if (result.success) {
                    setPicturesSelected([]);
                    setTo([]);
                  }
                }
              )
            );
          }
        }
      )
    );
  };

  const replyTemplate = () => {
    return `<div style="display:flex; flex-direction: column; gap: 10px;">
      <div style="color:blue;">Le ${messageModal.emailDate}, ${fromEmail} à écrit:</div>
      <div>${messageModal.messages}<div>
    </div> `;
  };

  const onSendEmail = () => {
    dispatch(
      sendEmail(
        id,
        agencyEmail,
        agencyId,
        [...to, ...optionnalMail],
        picturesSelected.map(
          (token) => dataFile.files.find((file) => file.token === token)!
        ),
        subject,
        message,
        messageModal.reply ? replyTemplate() : null,
        true,
        (result: TResultBase) => {
          if (result.success) {
            dispatch({ type: ModalAction.CLOSE_MESSAGE_MODAL });
            dispatch(
              insertMessage({
                toSource: "mail-sortant",
                folderId: Number(id),
                fromId: userId,
                metadata: { to, optionnalMail },
                filesToken: picturesSelected.map(
                  (token) =>
                    dataFile.files.find((file) => file.token === token)?.token!
                ),
                message,
                subject: `Mail envoyé à ${[...to, ...optionnalMail].join(",")}`,
                fromSource: "gla-software",
                grade: 1,
              })
            );
          }
        }
      )
    );
  };

  const openModalFlex = (token: string) => {
    const file = dataFile.files.find((file) => file.token === token);

    dispatch({
      type: ModalAction.OPEN_FLEX_MODAL,
      data: {
        target: "modalMessage",
        position: "rightBottom",
        children: (
          <>
            {file?.extension !== "pdf" ? (
              <div style={{ zIndex: 1001 }}>
                <img
                  loading="lazy"
                  style={{
                    maxWidth: 320,
                    maxHeight: 500,
                  }}
                  src={getUrl(id, token, 0)}
                  alt={`images`}
                />
              </div>
            ) : (
              <div>
                <embed
                  src={getUrl(id, token)}
                  type="application/pdf"
                  width="320px"
                  height="500px"
                />
              </div>
            )}
          </>
        ),
      },
    });
  };

  const closeModalFlex = () => {
    dispatch({
      type: ModalAction.CLOSE_FLEX_MODAL,
    });
  };

  useEffect(() => {
    if (fromEmail) {
      if (messageModal.messageOptions === "mail-entrant") {
        setOptionnalMail([fromEmail]);
      } else {
        setTo([fromEmail]);
      }
    }
  }, []);

  return (
    <ModalLayout customStyles={{ width: "100%" }}>
      <Flex display="flex" justify="space-around" styles={{ height: "100%" }}>
        <Flex
          display="flex"
          direction="column"
          gap={10}
          justify="space-between"
          styles={{
            padding: "10px",
            width: "70%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <Flex display="flex" gap={10} direction="column">
            <h2 id="modalMessage">
              {fromEmail
                ? `Répondre à ${fromEmail}`
                : `Envoyer un message par ${messageModal.messageOptions}`}
            </h2>
            <MultipleSelect
              className="w-100"
              label="Destinataire"
              options={getUsers()}
              value={to}
              onChange={(value) => setTo(value as string[])}
              placeholder={"Destinataire"}
            />
            {messageModal.messageOptions === "ixperience" && (
              <InputSelect
                label="Type"
                options={ixperienceMessageType?.map((obj) => ({
                  text: obj.name,
                  value: obj.id,
                }))}
                value={messageType}
                onChange={(value) => setMessageType(value as number)}
                placeholder={"Type"}
              />
            )}
            {["mail-sortant", "mail-entrant"].includes(
              messageModal.messageOptions || ""
            ) && (
              <Flex gap={5} display="flex" direction="column">
                {inputIdx.map((value, idx) => (
                  <InputText
                    iconRight={{
                      name: "Trash",
                      size: 15,
                      infoText: "Supprimer",
                      onClick: () => {
                        setInputIdx((prev) => prev.filter((v) => v !== value));
                      },
                    }}
                    error={errorEmailIdx.includes(idx)}
                    value={optionnalMail[value] || ""}
                    key={idx}
                    className="w-100"
                    placeholder={`adresse mail libre n°${value + 1}`}
                    onChange={(e) => {
                      const index = idx;
                      const updatedMails = [...optionnalMail];
                      updatedMails[index] = e.target.value;
                      setOptionnalMail(updatedMails);
                      setErrorEmailIdx((prev) =>
                        prev.filter((errorIdx) => errorIdx !== idx)
                      );
                    }}
                  />
                ))}
                <Flex
                  display="flex"
                  justify="end"
                  styles={{ marginBottom: "5px" }}
                >
                  <Button
                    outline
                    text={"Ajouter"}
                    icon={<Plus size={10} />}
                    onClick={() => {
                      if (inputIdx.length) {
                        setInputIdx((prev) => [
                          ...prev,
                          prev[prev.length - 1] + 1,
                        ]);
                      } else {
                        setInputIdx([0]);
                      }
                    }}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>

          <Flex display="flex" gap={10} direction="column">
            <InputText
              value={subject}
              label="Objet"
              placeholder={""}
              onChange={({ target }) => setSubject(target.value)}
            />
            <InputTextArea
              rows={20}
              value={message}
              label="Message"
              placeholder={""}
              onChange={({ target }) => setMessage(target.value)}
            />
            <MultipleSelect
              onMouseEnterLi={(option) => openModalFlex(option.value as string)}
              options={dataFile.files.map((file: TFile) => ({
                text: file.filename,
                value: file.token,
              }))}
              value={picturesSelected}
              onChange={(value) => {
                const files = value.map(
                  (token) =>
                    dataFile.files.find((file) => file.token === token)!
                );
                if (files.length) {
                  setPicturesSelected(value as string[]);
                } else {
                  setPicturesSelected([]);
                }
                closeModalFlex();
              }}
              placeholder={"PJ"}
            />
          </Flex>

          <Flex display="flex" justify="end">
            <Button
              style={{ width: "fit-content" }}
              text={"Envoyer"}
              onClick={() => {
                if (messageModal.messageOptions === "ixperience") {
                  onSendMessage();
                } else {
                  if (
                    !optionnalMail.includes("") &&
                    !getInvalidEmails(optionnalMail).length
                  ) {
                    onSendEmail();
                  } else if (to.length) {
                    onSendEmail();
                  } else {
                    setErrorEmailIdx(getInvalidEmails(optionnalMail));
                  }
                }
              }}
              disabled={!to.length && !optionnalMail.length}
            />
          </Flex>
        </Flex>

        <Flex
          display="flex"
          direction="column"
          justify="space-between"
          styles={{ width: "100%" }}
        >
          <Flex
            display="flex"
            direction="column"
            styles={{
              padding: 10,
              height: "100%",
            }}
          >
            <Flex display="flex" justify="center" align="center" gap={10}>
              <HeaderActionCard
                disableBorder
                actionText={[
                  {
                    id: "prev",
                    text: "Prévisualisation",
                    onClick: () => setMessageTabs("prev"),
                    disabled: false,
                    active: messageTabs === "prev",
                  },
                  {
                    id: "pj",
                    text: "Pièces jointes",
                    onClick: () => setMessageTabs("pj"),
                    disabled: false,
                    active: messageTabs === "pj",
                  },
                ]}
              />
            </Flex>
            <Flex
              display="flex"
              align="center"
              justify="center"
              styles={{ height: "100%" }}
            >
              {messageTabs === "prev" ? (
                <iframe
                  style={{
                    minHeight: "100%",
                    width: "100%",
                    border: 0,
                    borderRadius: 8,
                    backgroundColor: "#EEEEEE",
                  }}
                  srcDoc={previewTemplate(
                    messageModal.messageOptions === "mail-sortant",
                    { subject, metadata, messages: message } as TManaging,
                    name,
                    dataFile,
                    reply,
                    messageModal
                  )}
                />
              ) : (
                <>
                  {picturesSelected.length > 0 ? (
                    <ImageSlider images={picturesSelected} />
                  ) : (
                    <p>Aucune image en pièces jointes</p>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ModalLayout>
  );
};

export default ModalMessage;
