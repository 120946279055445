const getUrl = (
  id: string | undefined,
  token: string,
  size?: number,
  timestamp?: string,
  isDownload?: number
) => {
  size = size || 0;
  timestamp = timestamp || "";
  isDownload = isDownload || 0;

  const tokenAuth = window.localStorage.getItem("token");

  if (document.location.toString().includes("localhost")) {
    return `http://localhost:5000/api/folder/get-file/${id}/${token}/${isDownload}/${size}`;
  } else if (document.location.toString().includes("development")) {
    return `https://development.glasoftware.fr/api/folder/get-file/${id}/${token}/${isDownload}/${size}`;
  } else {
    return `https://glasoftware.fr/api/folder/get-file/${id}/${token}/${isDownload}/${size}`;
  }
};

export default getUrl;
