import {
  TPartsDetails,
  TdetailsOperation,
} from "../../types/folder/expertisesType";

export const calculateTotal = (
  quantity: number,
  price: number,
  dilapidated = 0,
  discount = 0,
  vat = 0
) => {
  if (!price) return 0;

  const totalPrice = price * quantity;
  const priceDilapidated = (totalPrice * dilapidated) / 100;
  const priceDiscount = (totalPrice * discount) / 100;

  let total = totalPrice - priceDilapidated - priceDiscount;

  if (vat) {
    const priceVat = (total * vat) / 100;
    total += priceVat;
  }

  return total;
};

export const getTotalValue = (
  data: TPartsDetails[] | TdetailsOperation[],
  isVat = false
) => {
  let total = 0;
  if (data) {
    data.forEach((element) => {
      total += calculateTotal(
        element.nbe,
        element.priceHT,
        element.disrepair,
        element.discount,
        isVat ? element.TVA : 0
      );
    });
  }
  return Number(total.toFixed(2));
};
