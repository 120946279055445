import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";

import "react-quill/dist/quill.snow.css";
import "react-camera-media-stream/dist/index.css";
import "./styles/tui-color-picker.css";

import "@sahadevia/ui-kit-modern-sahadevia/src/styles/global.css";

//React-Redux
import store from "./store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

console.log("date update", process.env.REACT_APP_BUILD_TIME);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
