/* Libs */
import { useParams } from "react-router-dom";
import { Flex, Icon } from "@sahadevia/ui-kit-modern-sahadevia";

/* Actions */
import { ModalAction } from "../../../actions/modalAction";
import {
  deleteFiles,
  updatePreselectFile,
} from "../../../actions/folderAction";

/* Reducers */
import { useAppDispatch } from "../../../reducers";

/* Utils */
import { downloadFile } from "../../../utils/general";
import getUrl from "../../../utils/getUrl";
import { TbaseReducer } from "../../../types/reducer/reducerType";
import { useSelector } from "react-redux";

interface ButtonListProps {
  activeLines: Array<string>;
  files: Array<any>;
  resetActiveLines: Function;
  isPreselect?: boolean;
}

function ButtonList({
  activeLines,
  files,
  resetActiveLines,
  isPreselect,
}: ButtonListProps) {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { gedImageList } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  const _deleteFiles = (tokens: Array<string>) => {
    dispatch(deleteFiles(id, tokens, () => resetActiveLines()));
  };

  const _updatePreselectFile = (type: string) => {
    let tmpFiles = gedImageList.length
      ? files.filter((file) => gedImageList.includes(file.token))
      : files;

    tmpFiles.forEach(({ preselect = [] }) => {
      if (!preselect.includes(type)) {
        preselect.push(type);
      }
    });

    dispatch(
      updatePreselectFile({
        folderId: id || "",
        files: tmpFiles,
      })
    );
  };

  return (
    <Flex
      display="flex"
      styles={{
        border: "1px solid var(--medium-grey)",
        padding: "5px 10px",
        borderRadius: "6px",
      }}
      classNames="ml-auto"
      align="center"
      gap={10}
    >
      {isPreselect && activeLines.length ? (
        <>
          <div
            style={{
              borderRight: "1px solid var(--medium-grey)",
              paddingRight: "10px",
              color: "var(--secondary-bg-color)",
              fontWeight: 100,
              fontSize: 13,
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => _updatePreselectFile("AO")}
            >
              AO
            </span>
          </div>
          <div
            style={{
              borderRight: "1px solid var(--medium-grey)",
              paddingRight: "10px",
              color: "var(--secondary-bg-color)",
              fontWeight: 100,
              fontSize: 13,
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => _updatePreselectFile("RI")}
            >
              RI
            </span>
          </div>
          <div
            style={{
              borderRight: "1px solid var(--medium-grey)",
              paddingRight: "10px",
              color: "var(--secondary-bg-color)",
              fontWeight: 100,
              fontSize: 13,
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => _updatePreselectFile("AU")}
            >
              AU
            </span>
          </div>
        </>
      ) : null}
      <div
        style={{
          borderRight: "1px solid var(--medium-grey)",
          paddingRight: "10px",
        }}
      >
        <Icon
          backgroundHover
          infoText="Transférer vers un autre dossier"
          icon={"ExportDot"}
          size={15}
          onClick={() => {
            if (activeLines.length) {
              dispatch({
                type: ModalAction.OPEN_SEARCH_FOLDERS_MODAL,
                data: {
                  activeLines,
                  option: "transfert",
                  action: () => null,
                },
              });
            } else {
              dispatch({
                type: ModalAction.OPEN_INFO_MODAL,
                data: {
                  title: "Alerte",
                  message:
                    "Vous n'avez pas sélectionné de documents à transférer",
                },
              });
            }
          }}
        />
      </div>
      <div
        style={{
          borderRight: "1px solid var(--medium-grey)",
          paddingRight: "10px",
        }}
      >
        <Icon
          backgroundHover
          infoText="Copier vers un autre dossier"
          icon={"Copy"}
          size={15}
          onClick={() => {
            if (activeLines.length) {
              dispatch({
                type: ModalAction.OPEN_SEARCH_FOLDERS_MODAL,
                data: {
                  activeLines,
                  option: "copy",
                  action: () => null,
                },
              });
            } else {
              dispatch({
                type: ModalAction.OPEN_INFO_MODAL,
                data: {
                  title: "Alerte",
                  message: "Vous n'avez pas sélectionné de documents à copier",
                },
              });
            }
          }}
        />
      </div>
      <div
        style={{
          borderRight: "1px solid var(--medium-grey)",
          paddingRight: "10px",
        }}
      >
        <Icon
          backgroundHover
          infoText="Télécharger"
          icon={"Export"}
          size={15}
          onClick={() => {
            if (activeLines.length) {
              activeLines.forEach((token) => {
                downloadFile(
                  getUrl(id, token, undefined, undefined, 1),
                  `${files.find((f) => f.token === token).filename}.${
                    files.find((f) => f.token === token).extension
                  }`,
                  true
                );
              });
              resetActiveLines([]);
            } else {
              dispatch({
                type: ModalAction.OPEN_INFO_MODAL,
                data: {
                  title: "Alerte",
                  message:
                    "Vous n'avez pas sélectionné de documents à télécharger",
                },
              });
            }
          }}
        />
      </div>
      <div
        style={{
          borderRight: "1px solid var(--medium-grey)",
          paddingRight: "10px",
        }}
      >
        <Icon
          backgroundHover
          infoText="Import depuis l'ordinateur"
          icon={"Download"}
          size={15}
          onClick={() =>
            dispatch({
              type: ModalAction.OPEN_UPLOAD_MODAL,
            })
          }
        />
      </div>
      <div>
        <Icon
          backgroundHover
          infoText="Supprimer"
          icon={"Trash"}
          size={15}
          onClick={() => {
            if (activeLines.length) {
              dispatch({
                type: ModalAction.OPEN_CONFIRM_MODAL,
                data: {
                  title: "Supprimer des documents",
                  message:
                    "Êtes-vous sûr de vouloir supprimer les documents sélectionnées",
                  onConfirm: () => {
                    _deleteFiles(activeLines);
                  },
                },
              });
            } else {
              dispatch({
                type: ModalAction.OPEN_INFO_MODAL,
                data: {
                  title: "Alerte",
                  message:
                    "Vous n'avez pas sélectionné de documents à supprimer.",
                },
              });
            }
          }}
        />
      </div>
    </Flex>
  );
}

export default ButtonList;
