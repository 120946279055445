/* Libs */
import {
  Card,
  Flex,
  Grid,
  MultipleSelect,
  Tag,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

/* Components */
import FolderTable from "../components/Table/FolderTable";

/* Reducers */
import { useAppDispatch } from "../reducers";

/* Actions */
import {
  SearchAction,
  countFolders,
  getFolders,
} from "../actions/searchAction";

/* Configs */
import { PAGE_LENGTH } from "../configs/pagination";

/* Types */
import { TbaseReducer } from "../types/reducer/reducerType";
import { TStatusHomeColor } from "../types/static/staticType";
import { hexToRgb } from "../utils/general";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  scales: {
    x: {
      display: false, // Désactiver les labels en bas du graphique
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const StatusView = () => {
  const dispatch = useAppDispatch();
  const { statusHome } = useSelector(
    (state: TbaseReducer) => state.staticReducer
  );
  const { order, activePage } = useSelector(
    (state: TbaseReducer) => state.searchReducer
  );

  const [filter, setFilter] = useState<string[]>([]);
  const [statusViewFilter, setStatusViewFilter] = useState<
    Record<string, number[]>
  >({});

  useEffect(() => {
    dispatch(countFolders(statusViewFilter));
    dispatch(
      getFolders(
        statusViewFilter,
        PAGE_LENGTH,
        (activePage - 1) * PAGE_LENGTH,
        order.name,
        order.desc
      )
    );
    if (localStorage.getItem("statusHome")) {
      setFilter(JSON.parse(localStorage.getItem("statusHome")!));
    }
  }, []);

  useEffect(() => {
    dispatch(countFolders(statusViewFilter));
    dispatch(
      getFolders(
        statusViewFilter,
        PAGE_LENGTH,
        (activePage - 1) * PAGE_LENGTH,
        order.name,
        order.desc
      )
    );
  }, [statusViewFilter]);

  useEffect(() => {
    dispatch(
      getFolders(
        statusViewFilter,
        PAGE_LENGTH,
        (activePage - 1) * PAGE_LENGTH,
        order.name,
        order.desc
      )
    );
  }, [activePage]);

  const statusFilter = (filter: string[]) => {
    if (filter.length) {
      return statusHome.filter((el) => filter.includes(el.status));
    } else {
      return statusHome;
    }
  };

  const saveToLocalStorage = (key: string, data: unknown) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const colorMapping: Record<TStatusHomeColor, string> = {
    blue: "#809cff",
    green: "#11d979",
    orange: "#f8b533",
    red: "#ff6871",
    yellow: "#e9f46c",
  };

  // transforms hexa color into rgba color with opacity
  const chartBarWithOpacity = statusHome.map((el) => {
    const color = colorMapping[el.color];
    const rgbColor = hexToRgb(color);
    const opacity =
      Object.keys(statusViewFilter).length &&
      statusViewFilter.statusFolder.some((id) => el.id.includes(id))
        ? 1
        : 0.3;

    return {
      backgroundColor: `rgba(${rgbColor?.red},${rgbColor?.green},${rgbColor?.blue}, ${opacity})`,
    };
  });

  return (
    <Flex display="flex" gap={20} direction="column">
      <Grid display="grid" columnNumber={2} gap={20}>
        <Card
          title="États"
          action={
            <Flex display="flex">
              <div style={{ maxWidth: "300px" }}>
                <MultipleSelect
                  options={statusHome.map((el) => ({
                    text: el.status,
                    value: el.status,
                  }))}
                  placeholder={"Sélectionner les status"}
                  onChange={(value) => {
                    setFilter(value as string[]);
                    saveToLocalStorage("statusHome", value);
                  }}
                  value={filter}
                />
              </div>
            </Flex>
          }
        >
          <Grid display="grid" columnNumber={2} gap={10}>
            {statusFilter(filter).map((status, idx) => (
              <Tag
                key={idx}
                style={{
                  width: "100%",
                  opacity: !Object.keys(statusViewFilter).length
                    ? "30%"
                    : Object.keys(statusViewFilter).length &&
                      !statusViewFilter.statusFolder.some((id) =>
                        status.id.includes(id)
                      )
                    ? "30%"
                    : "",
                }}
                number={status.statusTotal}
                text={status.status}
                color={status.color}
                onClick={() => {
                  setStatusViewFilter({ ["statusFolder"]: status.id });
                }}
              />
            ))}
          </Grid>
        </Card>

        <Card
          title="Répartitions"
          className="min-width-full "
          classNameChildren="relative h-100"
        >
          <Bar
            options={options}
            data={{
              labels: statusHome.map((el) => el.status),
              datasets: [
                {
                  borderRadius: 10,
                  minBarLength: 10,
                  data: statusHome.map((el) => el.statusTotal),
                  backgroundColor: chartBarWithOpacity.map(
                    (el) => el.backgroundColor
                  ),
                },
              ],
            }}
          />
        </Card>
      </Grid>
      <FolderTable
        filters={statusViewFilter}
        onSort={(_sortKey, key, order) => {
          dispatch({
            type: SearchAction.UPDATE_ORDER,
            data: { name: key, desc: order },
          });
        }}
      />
    </Flex>
  );
};

export default StatusView;
