/* Libs */
import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {
  Flex,
  HeaderActionCard,
  InfoCard,
} from "@sahadevia/ui-kit-modern-sahadevia";

/* Components */
import Ged from "../components/folder/ged/Ged";
import MissionIntervener from "../components/folder/mission/MissionIntervener";
import Information from "../components/folder/actions/Information";
import Task from "../components/folder/actions/Task";
import Managing from "../components/folder/actions/Managing";
import FolderModals from "../modals/FolderModals";
import Vehicle from "../components/folder/vehicle/Vehicle";
import FolderPreparation from "../components/folder/folderPreparation/FolderPreparation";
import Expertise from "../components/folder/expertise/Expertise";

/* Actions */
import { get } from "../actions/folderAction";
import { ModalAction } from "../actions/modalAction";

/* Reducers */
import { useAppDispatch } from "../reducers";

/* Utils */
import { getTypeUser } from "../utils/general";

/* Types */
import type { TbaseReducer } from "../types/reducer/reducerType";

export type TPartiesData = {
  title: string;
  users: Tparties[];
};

export type Tparties = {
  address1: string;
  address2: string;
  city: string;
  email: string;
  firstname: string;
  is_pro: boolean;
  phone: string;
  type: PartiesTypes;
  name: string;
  zip_code: string;
};

export enum PartiesTypes {
  EXPERT = "expert",
  ASSISTANT = "assistant",
  REPAIRMAN = "repairman",
  INSURANCE = "insurance",
  PRINCIPALE = "principal",
  INSURED = "insured",
}

function FolderView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id, primary } = useParams();

  const { name } = useSelector((state: TbaseReducer) => state.authReducer);
  const [socketId, setSocketId] = useState("");

  const [usersOnline, setUsersOnline] = useState<
    { name: string; socketId: string }[]
  >([]);
  const [userOnSamePage, setUserOnSamePage] = useState<{ socketIds: string[] }>(
    {
      socketIds: [],
    }
  );

  useEffect(() => {
    // @ts-ignore
    window.openFolder(
      id,
      (name || "")
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase(),
      (result: { users: { name: string; socketId: string }[] }) => {
        setUsersOnline(result.users);
      },
      () => {
        dispatch(get(id, true));
      },
      setUserOnSamePage
    );

    // @ts-ignore
    window.getSocketId((socketId: string) => setSocketId(socketId));

    if (socketId) {
      // @ts-ignore
      window.changeFolderPage(id, socketId, location.pathname);
    }

    return () => {
      // @ts-ignore
      window.closeFolder();
    };
  }, []);

  useEffect(() => {
    if (usersOnline && usersOnline.length) {
      // @ts-ignore
      window.changeFolderPage(id, socketId, location.pathname);
    }
  }, [location.pathname]);

  const {
    dataFile,
    statusHome,
    statusColor,
    logoInsurance,
    initialExpert,
    initialAssistant,
  } = useSelector((state: TbaseReducer) => state.folderReducer, shallowEqual);

  if (!primary) {
    navigate(`/folder/${id}/mission`, { replace: true });
  }

  const changeTabs = (tabs: string) => {
    navigate(`/folder/${id}/${tabs}`);
  };

  if (!dataFile || !dataFile.hasOwnProperty("mission")) {
    return (
      <div className="h-100 d-flex">
        <div
          className="m-auto flex-column d-flex white p-20"
          style={{
            backgroundColor: "var(--primary-color)",
            borderRadius: "8px",
          }}
        >
          <span className="mt-5">Chargement du dossier en cours..</span>
        </div>
      </div>
    );
  }

  const tabs = [
    {
      id: "mission",
      text: "Mission / Intervenant",
      onClick: () => changeTabs("mission"),
      disabled: false,
      active: primary === "mission",
    },
    {
      id: "preparation",
      text: "Préparation dossier",
      onClick: () => changeTabs("preparation"),
      disabled: false,
      active: primary === "preparation",
    },
    {
      id: "actions",
      text: "Actions",
      onClick: () => {
        changeTabs("actions");
      },
      disabled: false,
      active: primary === "actions",
    },
    {
      id: "vehicle",
      text: "Vehicule",
      onClick: () => changeTabs("vehicle"),
      disabled: false,
      active: primary === "vehicle",
    },
    {
      id: "expertise",
      text: "Expertise",
      onClick: () => changeTabs("expertise"),
      disabled: false,
      active: primary === "expertise",
    },
    {
      id: "VGE",
      text: "Suivi VGE",
      onClick: () => changeTabs("VGE"),
      disabled: true,
      active: primary === "VGE",
    },
    {
      id: "VRADE",
      text: "VRADE",
      onClick: () => changeTabs("VRADE"),
      disabled: true,
      active: primary === "VRADE",
    },
    {
      id: "report",
      text: "Rapport",
      onClick: () => changeTabs("report"),
      disabled: true,
      active: primary === "report",
    },
    {
      id: "honorary",
      text: "Honoraires",
      onClick: () => changeTabs("honorary"),
      disabled: true,
      active: primary === "honorary",
    },
    {
      id: "GED",
      text: "GED",
      onClick: () => changeTabs("GED"),
      disabled: false,
      active: primary === "GED",
    },
  ];

  const getName = (partiesTypes: PartiesTypes): string => {
    if (dataFile && dataFile.parties) {
      let result: string = "";

      dataFile.parties.forEach((party) => {
        party.users.forEach((user: any) => {
          if (user.type === partiesTypes) {
            result = user.name || user.firstname || "";
          }
        });
      });

      return result;
    } else return "";
  };

  const headerInfoTabs = [
    {
      text: dataFile.mission.number,
    },
    {
      text: dataFile.vehicle.license_plate,
    },

    {
      key: PartiesTypes.EXPERT,
      text: initialExpert,
    },
    {
      key: PartiesTypes.ASSISTANT,
      text: initialAssistant,
    },

    {
      text: dataFile.agency.name,
    },
    {
      key: PartiesTypes.INSURED,
      text: getName(PartiesTypes.INSURED),
    },
    {
      key: PartiesTypes.REPAIRMAN,
      text: getName(PartiesTypes.REPAIRMAN),
    },
    {
      key: PartiesTypes.INSURANCE,
      text: getName(PartiesTypes.INSURANCE),
      component: (onClick: () => void, idx: number) =>
        logoInsurance ? (
          <img
            loading="lazy"
            className="cursor-pointer"
            alt="insurance"
            src={logoInsurance}
            height={20}
            onClick={onClick}
          />
        ) : (
          <p key={idx} className="m-0 cursor-pointer" onClick={onClick}>
            {getName(PartiesTypes.INSURANCE)}
          </p>
        ),
    },
  ];

  const onInfoClick = (info: { key?: string }) => {
    const userInfo = dataFile.parties[0].users.find(
      (user) => user.type === info.key
    );

    if (!userInfo) return;

    dispatch({
      type: ModalAction.OPEN_CUSTOM_MODAL,
      data: {
        children: (
          <InfoCard
            style={{ border: "none" }}
            tagAction
            tagText={getTypeUser(info.key)}
            color="blue"
            title={userInfo.firstname}
            // actionButtonChildren={
            // <Icon disabled icon={<PenIcon size={20} />} />
            // }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              {userInfo.address1 && <div>{userInfo.address1}</div>}
              {userInfo.address2 && <div>{userInfo.address2}</div>}
              {userInfo.name && <div>{userInfo.name}</div>}
              {userInfo.city && <div>{userInfo.city}</div>}
              {userInfo.phone && <div>{userInfo.phone}</div>}
              {userInfo.email && <div>{userInfo.email}</div>}
            </div>
          </InfoCard>
        ),
      },
    });
  };

  return (
    <Flex display="flex" gap={20} direction="column">
      <FolderModals />
      <HeaderActionCard
        sitcky
        dataTop={[
          <>
            {statusHome ? (
              <div
                className="p-10"
                style={{
                  marginLeft: "-10px",
                  backgroundColor: `var(--${statusColor}-light)`,
                  borderRadius: "8px",
                  color: "var(--white)",
                }}
              >
                {statusHome}
              </div>
            ) : null}
          </>,
          <>
            {headerInfoTabs.map((info, idx) => {
              if (info.component) {
                return info.component(() => onInfoClick(info), idx);
              } else {
                return (
                  <Fragment key={idx}>
                    {info.text && info.text.length > 0 && (
                      <p
                        className={`p-10 m-0 ${
                          info.key ? "cursor-pointer" : ""
                        }`}
                        onClick={() => onInfoClick(info)}
                      >
                        {info.text}
                      </p>
                    )}
                  </Fragment>
                );
              }
            })}
          </>,
        ]}
        actionText={tabs}
      />

      <div style={{ position: "fixed", bottom: 0, left: 0, zIndex: 150 }}>
        <div className="mb-10 d-flex">
          {usersOnline &&
            usersOnline.map((item, idx) => (
              <div
                key={idx}
                style={{
                  display: "inline-flex",
                  backgroundColor:
                    userOnSamePage &&
                    userOnSamePage.socketIds.includes(item.socketId)
                      ? "var(--red)"
                      : "var(--primary-bg-color)",
                  color: "var(--white)",
                  height: "40px",
                  width: "40px",
                  fontSize: "12px",
                  borderRadius: "30px",
                  marginLeft: "10px",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <span className="m-auto">{item.name}</span>
              </div>
            ))}
        </div>
      </div>

      {primary === "mission" && <MissionIntervener dataFile={dataFile} />}
      {primary === "preparation" && <FolderPreparation />}
      {primary === "vehicle" && <Vehicle />}
      {primary === "GED" && <Ged />}
      {primary === "actions" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            paddingBottom: 60,
          }}
        >
          <Information />
          <Task />
          <Managing />
        </div>
      )}
      {primary === "expertise" && <Expertise />}
    </Flex>
  );
}

export default FolderView;
