/* LIBS */
import moment from "moment";

/* TYPES */
import { TModalInitialState } from "../../types/app/appType";
import {
  TManaging,
  TManagingMetadata,
} from "../../types/folder/actions/managingType";
import { TDataFile } from "../../types/folder/folderType";

/* IMG */
import agencyLogo from "../../images/logo-lang-130.png";
import agencyLogoSmall from "../../images/logo-lang-small-80.png";

export const previewTemplate = (
  footer = false,
  managing: TManaging,
  name: string,
  data: TDataFile,
  reply: boolean | undefined,
  messageModal: TModalInitialState["messageModal"] | null
) => {
  const { subject, messages, metadata } = managing;

  return `<style>
      body{margin:0}
    </style>
    <div style="font-family: sans-serif; font-size: 14px; display:flex; flex-direction: column; gap: 10px; color: #000000; background-color: #EEEEEE; padding: 20px; border-radius: 10px white-space: pre-line>
    <span>${subject}</span>
    <div style="border-bottom: 1px solid blue;">${
      messages && !messages.includes("<br/>")
        ? messages
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .split("\n")
            .map((line) => (line.length > 0 ? `<p>${line}</p>` : ""))
            .join("")
        : `${messages}`
    }
      ${
        !footer
          ? ""
          : `<table
          cellpadding="0"
          cellspacing="0"
          class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
          style="
            vertical-align: -webkit-baseline-middle;
          "
        >
          <tbody>
            <tr>
              <td>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                  style="
                    vertical-align: -webkit-baseline-middle;
                  "
                >
                  <tbody>
                    <tr>
                      <td width="150" style="vertical-align: middle">
                        <span
                          class="template3__ImageContainer-sc-vj949k-0 jeScQV"
                          style="margin-right: 20px; display: block"
                          ><img
                            src="${agencyLogoSmall}"
                            role="presentation"
                            width="80"
                            class="image__StyledImage-sc-hupvqm-0 gYgOut"
                            style="max-width: 80px"
                        /></span>
                      </td>
                      <td style="vertical-align: middle">
                        <h2
                          color="#000000"
                          class="name__NameContainer-sc-1m457h3-0 jxbGUj"
                          style="
                            margin: 0px;
                            font-size: 18px;
                            color: rgb(0, 0, 0);
                            font-weight: 600;
                          "
                        >
                          <span>${name}</span>
                        </h2>
                        <p
                          color="#000000"
                          font-size="medium"
                          class="company-details__CompanyContainer-sc-j5pyy8-0 VnOLK"
                          style="
                            margin: 0px;
                            font-weight: 500;
                            color: rgb(0, 0, 0);
                            font-size: 14px;
                            line-height: 22px;
                          "
                        >
                          <span>${data.agency.legalName}</span>
                        </p>
                      </td>
                      <td width="30"><div style="width: 30px"></div></td>
                      <td
                        color="#d83737"
                        direction="vertical"
                        width="1"
                        height="auto"
                        class="color-divider__Divider-sc-1h38qjv-0 llIisW"
                        style="
                          width: 1px;
                          border-bottom: none;
                          border-left: 1px solid rgb(216, 55, 55);
                        "
                      ></td>
                      <td width="30"><div style="width: 30px"></div></td>
                      <td style="vertical-align: middle">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                          style="
                            vertical-align: -webkit-baseline-middle;
                          "
                        >
                          <tbody>
                            <tr height="25" style="vertical-align: middle">
                              <td width="30" style="vertical-align: middle">
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                  style="
                                    vertical-align: -webkit-baseline-middle;
                                  "
                                >
                                  <tbody>
                                    <tr>
                                      <td style="vertical-align: bottom">
                                        <span
                                          color="#d83737"
                                          width="11"
                                          class="contact-info__IconWrapper-sc-mmkjr6-1 bglVXe"
                                          style="
                                            display: inline-block;
                                            background-color: rgb(0, 0, 0);
                                          "
                                          ><img
                                            src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/phone-icon-2x.png"
                                            color="#d83737"
                                            alt="mobilePhone"
                                            width="13"
                                            class="contact-info__ContactLabelIcon-sc-mmkjr6-0 cnkwri"
                                            style="
                                              display: block;
                                              background-color: rgb(0, 0, 0);
                                            "
                                        /></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style="padding: 0px; color: rgb(0, 0, 0)">
                                <a
                                  href="tel:${data.agency.phone}"
                                  color="#000000"
                                  class="contact-info__ExternalLink-sc-mmkjr6-2 ibLXSU"
                                  style="
                                    text-decoration: none;
                                    color: rgb(0, 0, 0);
                                    font-size: 12px;
                                  "
                                  ><span>${data.agency.phone}</span></a
                                >
                              </td>
                            </tr>
                            <tr height="25" style="vertical-align: middle">
                              <td width="30" style="vertical-align: middle">
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                  style="
                                    vertical-align: -webkit-baseline-middle;
                                  "
                                >
                                  <tbody>
                                    <tr>
                                      <td style="vertical-align: bottom">
                                        <span
                                          color="#d83737"
                                          width="11"
                                          class="contact-info__IconWrapper-sc-mmkjr6-1 bglVXe"
                                          style="
                                            display: inline-block;
                                            background-color: rgb(0, 0, 0);
                                          "
                                          ><img
                                            src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/email-icon-2x.png"
                                            color="#d83737"
                                            alt="emailAddress"
                                            width="13"
                                            class="contact-info__ContactLabelIcon-sc-mmkjr6-0 cnkwri"
                                            style="
                                              display: block;
                                              background-color: rgb(0, 0, 0);
                                            "
                                        /></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style="padding: 0px">
                                <a
                                  href="mailto:${data.agency.email}"
                                  color="#000000"
                                  class="contact-info__ExternalLink-sc-mmkjr6-2 ibLXSU"
                                  style="
                                    text-decoration: none;
                                    color: rgb(0, 0, 0);
                                    font-size: 12px;
                                  "
                                  ><span>${data.agency.email}</span></a
                                >
                              </td>
                            </tr>
                            <tr height="25" style="vertical-align: middle">
                              <td width="30" style="vertical-align: middle">
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                  style="
                                    vertical-align: -webkit-baseline-middle;
                                  "
                                >
                                  <tbody>
                                    <tr>
                                      <td style="vertical-align: bottom">
                                        <span
                                          color="#d83737"
                                          width="11"
                                          class="contact-info__IconWrapper-sc-mmkjr6-1 bglVXe"
                                          style="
                                            display: inline-block;
                                            background-color: rgb(0, 0, 0);
                                          "
                                          ><img
                                            src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/link-icon-2x.png"
                                            color="#d83737"
                                            alt="website"
                                            width="13"
                                            class="contact-info__ContactLabelIcon-sc-mmkjr6-0 cnkwri"
                                            style="
                                              display: block;
                                              background-color: rgb(0, 0, 0);
                                            "
                                        /></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style="padding: 0px">
                                <a
                                  href="https://${data.agency.website}"
                                  color="#000000"
                                  class="contact-info__ExternalLink-sc-mmkjr6-2 ibLXSU"
                                  style="
                                    text-decoration: none;
                                    color: rgb(0, 0, 0);
                                    font-size: 12px;
                                  "
                                  ><span>${data.agency.website}</span></a
                                >
                              </td>
                            </tr>
                            <tr height="25" style="vertical-align: middle">
                              <td width="30" style="vertical-align: middle">
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                  style="
                                    vertical-align: -webkit-baseline-middle;
                                  "
                                >
                                  <tbody>
                                    <tr>
                                      <td style="vertical-align: bottom">
                                        <span
                                          color="#d83737"
                                          width="11"
                                          class="contact-info__IconWrapper-sc-mmkjr6-1 bglVXe"
                                          style="
                                            display: inline-block;
                                            background-color: rgb(0, 0, 0);
                                          "
                                          ><img
                                            src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/address-icon-2x.png"
                                            color="#d83737"
                                            alt="address"
                                            width="13"
                                            class="contact-info__ContactLabelIcon-sc-mmkjr6-0 cnkwri"
                                            style="
                                              display: block;
                                              background-color: rgb(0, 0, 0);
                                            "
                                        /></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style="padding: 0px">
                                <span
                                  color="#000000"
                                  class="contact-info__Address-sc-mmkjr6-3 jxDmGK"
                                  style="font-size: 12px; color: rgb(0, 0, 0)"
                                  ><span
                                    >${data.agency.address1}${
              data.agency.address2 ? `, ${data.agency.address2}` : ""
            }, ${data.agency.zip}, ${data.agency.city}</span
                                  ></span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                  style="
                    width: 100%;
                    vertical-align: -webkit-baseline-middle;
                  "
                >
                  <tbody>
                    <tr>
                      <td height="30"></td>
                    </tr>
                    <tr>
                      <td
                        color="#d83737"
                        direction="horizontal"
                        width="auto"
                        height="1"
                        class="color-divider__Divider-sc-1h38qjv-0 llIisW"
                        style="
                          width: 100%;
                          border-bottom: 1px solid rgb(216, 55, 55);
                          border-left: none;
                          display: block;
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td height="30"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                  style="
                    width: 100%;
                    vertical-align: -webkit-baseline-middle;
                  "
                >
                  <tbody>
                    <tr>
                      <td style="vertical-align: top">
                        <img
                          src="${agencyLogo}"
                          role="presentation"
                          width="130"
                          class="image__StyledImage-sc-hupvqm-0 gYgOut"
                          style="display: inline-block; max-width: 130px"
                        />
                      </td>
                      <td style="text-align: right; vertical-align: top">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                          style="
                            display: inline-block;
                            vertical-align: -webkit-baseline-middle;
                          "
                        >
                          <tbody>
                            <tr style="text-align: right">
                              <td>
                                <a
                                  href="https://www.facebook.com/groupelangassocies"
                                  color="#d83737"
                                  class="social-links__LinkAnchor-sc-py8uhj-2 hBVWAh"
                                  style="
                                    display: inline-block;
                                    padding: 0px;
                                    background-color: rgb(0, 0, 0);
                                  "
                                  ><img
                                    src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/facebook-icon-2x.png"
                                    alt="facebook"
                                    color="#d83737"
                                    width="24"
                                    class="social-links__LinkImage-sc-py8uhj-1 hSTSwA"
                                    style="
                                      background-color: rgb(0, 0, 0);
                                      max-width: 135px;
                                      display: block;
                                    "
                                /></a>
                              </td>
                              <td width="5"><div></div></td>
                              <td>
                                <a
                                  href="https://www.linkedin.com/company/groupe-lang-et-associes/"
                                  color="#d83737"
                                  class="social-links__LinkAnchor-sc-py8uhj-2 hBVWAh"
                                  style="
                                    display: inline-block;
                                    padding: 0px;
                                    background-color: rgb(0, 0, 0);
                                  "
                                  ><img
                                    src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/linkedin-icon-2x.png"
                                    alt="linkedin"
                                    color="#d83737"
                                    width="24"
                                    class="social-links__LinkImage-sc-py8uhj-1 hSTSwA"
                                    style="
                                      background-color: rgb(0, 0, 0);
                                      max-width: 135px;
                                      display: block;
                                    "
                                /></a>
                              </td>
                              <td width="5"><div></div></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td height="30"></td>
            </tr>
          </tbody>
        </table>`
      }
      ${
        reply
          ? `<div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 50%;
        "
      >
        <blockquote
          style="
            border-left: 2px solid blue;
            background-color: #f6f6f6;
            margin: 20px 0;
            padding: 0 0.4em;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          "
        >
          <div style="color:blue;">Le ${
            messageModal?.emailDate ??
            moment(metadata?.date).format("DD/MM/YYY HH:MM")
          }, 
            ${metadata?.from ?? messageModal?.fromEmail} à écrit:
          </div>
          <div>${
            messageModal?.messages &&
            messageModal?.messages
              .split("\n")
              .map((line) => `<p>${line}</p>`)
              .join("")
          }<div>
        </blockquote>
      </div>`
          : ""
      } 
    </div>
  </div>`;
};
