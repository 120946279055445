/* Libs */
import { Dispatch, SetStateAction } from "react";
import {
  DropdownAction,
  Flex,
  InputCheckbox,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useParams } from "react-router-dom";
import moment from "moment";

/* Components */
import { PPRESELECTIONS } from "./ImageList";

/* Reducers */
import { useAppDispatch } from "../../../reducers";

/* Actions */
import { ModalAction } from "../../../actions/modalAction";
import {
  convertPictureToPDF,
  deleteFiles,
  updatePreselectFile,
} from "../../../actions/folderAction";

/* Utils */
import { downloadFile } from "../../../utils/general";
import getUrl from "../../../utils/getUrl";

/* Types */
import type { TFile } from "../../../types/folder/folderType";
import type { TResultBase } from "../../../types/app/appType";
import { TbaseReducer } from "../../../types/reducer/reducerType";
import { useSelector } from "react-redux";

type Props = {
  file: TFile;
  idx: number;
  handleGedImageList: (idxArray: string[]) => void;
  onClick?: () => void;
};

const ImageCard = ({ file, idx, handleGedImageList, onClick }: Props) => {
  const { gedImageList } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );
  const dispatch = useAppDispatch();

  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "10px",
        border: "1px solid var(--medium-grey)",
        height: "300px",
      }}
    >
      <Flex
        display="flex"
        styles={{
          padding: "10px",
          borderBottom: "1px solid var(--medium-grey)",
        }}
        justify="space-between"
        align="center"
      >
        <div>
          <InputCheckbox
            text={""}
            isCheck={gedImageList.includes(file.token)}
            onChange={() => null}
            onClick={() => {
              if (gedImageList.includes(file.token)) {
                handleGedImageList(
                  gedImageList.filter((token) => token !== file.token)
                );
              } else {
                handleGedImageList([...gedImageList, file.token]);
              }
            }}
          />
        </div>
        <div>
          <p style={{ margin: 0, fontSize: "12px" }}>
            {moment(file.date).format("DD/MM/YYYY HH:mm:ss")}
          </p>
        </div>
        <DropdownAction
          options={[
            {
              title: "Convertir en pdf",
              icon: "File",
              size: 10,
              onClick: () =>
                dispatch({
                  type: ModalAction.OPEN_CONFIRM_MODAL,
                  data: {
                    title: "Confirmation",
                    message:
                      "Êtes-vous sûr de vouloir convertir cette image en pdf ?",
                    onConfirm: () => dispatch(convertPictureToPDF(id, file)),
                  },
                }),
            },
            {
              title: "Télécharger",
              icon: "Export",
              size: 10,
              onClick: () =>
                downloadFile(
                  getUrl(id, file.token, 1920, undefined, 1),
                  `${file.filename}.${file.extension}`,
                  true
                ),
            },
            {
              title: "Supprimer",
              icon: "Trash",
              size: 10,
              onClick: () =>
                dispatch({
                  type: ModalAction.OPEN_CONFIRM_MODAL,
                  data: {
                    title: "Supprimer une image",
                    message: "Êtes-vous sûr de vouloir supprimer l'image ?",
                    onConfirm: () => dispatch(deleteFiles(id, [file.token])),
                  },
                }),
            },
          ]}
        />
      </Flex>
      <Flex
        display="flex"
        direction="column"
        align="center"
        styles={{ height: "100%", overflow: "hidden", cursor: "pointer" }}
        onClick={onClick}
      >
        <img
          loading="lazy"
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            userSelect: "none",
          }}
          className="m-auto"
          src={getUrl(id, file.token, 220, file.timestamp)}
          alt={`image`}
        />
      </Flex>
      <Flex
        display="flex"
        justify="space-between"
        align="center"
        styles={{
          padding: 5,
          flex: 0,
          width: "100%",
          borderTop: "1px solid var(--medium-grey)",
        }}
      >
        {PPRESELECTIONS.map((preselect, idx) => {
          const idxPreselect = (file.preselect || []).indexOf(preselect);

          const isPreselect = idxPreselect !== -1;
          return (
            <div
              className="cursor-pointer"
              style={{
                padding: 5,
                margin: "0px 5px",
                width: "100%",
                textAlign: "center",
                fontSize: "10px",
                borderRadius: "10px",
                background: `${isPreselect ? "var(--primary-bg-color)" : ""}`,
                color: `${isPreselect ? "var(--white)" : ""}`,
              }}
              key={idx}
              onClick={() => {
                let tmpPreselect = file.preselect || [];

                if (isPreselect) {
                  tmpPreselect.splice(idxPreselect, 1);
                } else {
                  tmpPreselect.push(preselect);
                }

                dispatch(
                  updatePreselectFile(
                    {
                      folderId: id,
                      files: [{ id: file.id, preselect: tmpPreselect }],
                    },
                    (result: TResultBase) => {
                      if (!result.success) {
                        dispatch({
                          type: ModalAction.OPEN_INFO_MODAL,
                          data: {
                            title: "Alerte",
                            message: "Une erreur est survenue !",
                          },
                        });
                      }
                    }
                  )
                );
              }}
            >
              {preselect}
            </div>
          );
        })}
      </Flex>
    </div>
  );
};
export default ImageCard;
