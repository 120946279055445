/* Libs */
import { useSelector } from "react-redux";

/* Types */
import type { TbaseReducer } from "../../../types/reducer/reducerType";

/* Components */
import TasksTable from "../../Table/TasksTable";

const Task = () => {
  const { dataFile } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  return <TasksTable title="Actions à réaliser" data={dataFile.tasks} />;
};

export default Task;
