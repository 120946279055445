/* Libs */
import { useEffect } from "react";
import { useSelector } from "react-redux";

/* Reducers */
import { useAppDispatch } from "../reducers";

/* Actions */
import { countTasks, getTasks } from "../actions/tasksAction";

/* Types */
import { TbaseReducer } from "../types/reducer/reducerType";
import { PAGE_LENGTH } from "../configs/pagination";

/* Components */
import TasksTable from "../components/Table/TasksTable";
import Filter from "../components/general/Filter";
import { Flex } from "@sahadevia/ui-kit-modern-sahadevia";

const TasksView = () => {
  const dispatch = useAppDispatch();
  const { activeFilters } = useSelector(
    (state: TbaseReducer) => state.searchReducer
  );
  const { tasks, activePage } = useSelector(
    (state: TbaseReducer) => state.tasksReducer
  );

  useEffect(() => {
    dispatch(countTasks(activeFilters));
    dispatch(
      getTasks(activeFilters, PAGE_LENGTH, (activePage - 1) * PAGE_LENGTH)
    );
  }, [activeFilters, activePage]);

  return (
    <Flex display="flex" direction="column" gap={20}>
      <div>
        <h2 className="mt-0 mb-0">Liste des Tâches</h2>
        <Filter />
      </div>
      <TasksTable title="Tâches" data={tasks} disableAddTask />
    </Flex>
  );
};

export default TasksView;
