/* Libs */
import { useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

/* Components */
import DashboardLayout from "./layouts/DashboardLayout";

/* Actions */
import { refreshToken as _refreshToken } from "./actions/authAction";
import { SearchAction } from "./actions/searchAction";
import { getStaticState } from "./actions/staticAction";

/* Reducers */
import { useAppDispatch } from "./reducers";

/* Routes */
import { routes } from "./routes/routes";

/* Types */
import type { TbaseReducer } from "./types/reducer/reducerType";

function App() {
  const dispatch = useAppDispatch();

  const { isAuthenticated, redirect, regionId, agencyId, token } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const { filters } = useSelector((state: TbaseReducer) => state.searchReducer);

  const { isload } = useSelector((state: TbaseReducer) => state.staticReducer);

  const refreshToken = (disableLoader?: boolean) => {
    const token = window.localStorage.getItem("token") || "";

    const url = window.location.href;
    const arr = url.split("/");

    dispatch(_refreshToken(token, arr[0] + "//" + arr[2], disableLoader));
  };

  useEffect(() => {
    if (agencyId) {
      dispatch({
        type: SearchAction.UPDATE_FILTERS,
        data: Object.assign({}, filters, {
          nameRegion: [regionId],
          nameAgency: [agencyId],
        }),
      });
      dispatch({
        type: SearchAction.SET_ACTIVE_FILTERS,
      });
    } else if (regionId) {
      dispatch({
        type: SearchAction.UPDATE_FILTERS,
        data: Object.assign({}, filters, { nameRegion: [regionId] }),
      });
      dispatch({
        type: SearchAction.SET_ACTIVE_FILTERS,
      });
    }
  }, [agencyId, regionId]);

  useEffect(() => {
    refreshToken();

    const onMessage = (result: any) => {
      if (result.data.type === "auth" && result.data.success) {
        window.localStorage.setItem("token", result.data.token || "");
        refreshToken();
      }
    };

    window.addEventListener("message", onMessage, false);

    const intervalToken = setInterval(() => {
      refreshToken(true);
    }, 20 * 60 * 1000);

    return () => {
      window.removeEventListener("message", onMessage);
      clearInterval(intervalToken);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getStaticState(token));
    }
  }, [isAuthenticated]);

  if (!isAuthenticated && redirect) {
    return (
      <div className="d-flex h-100">
        <iframe
          title="auth"
          src={redirect.replace("signin", "signin-light")}
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            border: "0",
          }}
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  if (isAuthenticated && document.location.hash.includes("success")) {
    document.location.hash = "/";
  }

  return (
    <>
      <HashRouter>
        <Routes>
          {routes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                route.hideDashboard ? (
                  route.element
                ) : (
                  <DashboardLayout>{route.element}</DashboardLayout>
                )
              }
            />
          ))}
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
