/* Libs */
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Flex,
  Button,
  InputText,
  InputSelect,
} from "@sahadevia/ui-kit-modern-sahadevia";

/* Components */
import ModalLayout from "../../layouts/ModalLayout";

/* Data */
import { categories } from "../../views/LibraryView";

/* Actions */
import { ModalAction } from "../../actions/modalAction";
import { addLibrary } from "../../actions/libraryAction";

/* Reducers */
import { useAppDispatch } from "../../reducers";

type Props = {
  title?: string;
};

type File = {
  name: string;
  base64: string | ArrayBuffer;
};

const ModalAddLibrary = ({ title }: Props) => {
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [keyword, setKeyword] = useState("");
  const [url, setUrl] = useState("");
  const [file, setFile] = useState<File | null>(null);

  return (
    <ModalLayout>
      <>
        {title ? <h4 style={{ fontSize: 14, margin: 0 }}>{title}</h4> : null}
        <InputText
          placeholder="Nom"
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
        <InputText
          placeholder="Mots clés"
          value={keyword}
          onChange={({ target }) => setKeyword(target.value)}
        />
        <InputSelect
          placeholder="Catégorie"
          options={[...categories]}
          onChange={(value) => setCategory(value.toString())}
          value={category}
        />
        <InputText
          placeholder="URL"
          value={url}
          onChange={({ target }) => setUrl(target.value)}
        />
        <p className="text-center m-0">OU</p>
        <div className="d-flex" style={{ gap: "10px" }}>
          <input
            ref={inputRef}
            type="file"
            style={{ display: "none" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                const file = e.target.files[0];

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  setFile({
                    name: file.name,
                    base64: reader.result || "",
                  });
                };
                reader.onerror = () => {
                  setFile(null);
                };
              } else {
                setFile(null);
              }
            }}
          />
          <Button
            text="Sélectionner un fichier"
            onClick={() => {
              if (inputRef && inputRef.current) {
                inputRef.current.click();
              }
            }}
          />
          <p className="mt-auto mb-auto">
            {file ? file.name : "aucun fichier sélectionné"}
          </p>
        </div>
        <Flex display="flex" justify="left" gap={10}>
          <Button
            onClick={() => {
              dispatch({ type: ModalAction.CLOSE_CURRENT_MODAL });
            }}
            text={"Annuler"}
            outline
          />
          <Button
            onClick={() => {
              dispatch(addLibrary(name, keyword, category, file, url));
              dispatch({ type: ModalAction.CLOSE_CURRENT_MODAL });
            }}
            text={"Ajouter"}
          />
        </Flex>
      </>
    </ModalLayout>
  );
};

export default ModalAddLibrary;
