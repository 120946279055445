/* Libs */
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../reducers";
import { useSelector } from "react-redux";
import { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  Card,
  Flex,
  InputText,
} from "@sahadevia/ui-kit-modern-sahadevia";

/* Actions */
import {
  deleteInternalInformationMessage,
  sendNewInternalInformationMessage,
} from "../../../actions/folderAction";

/* Types */
import type { TbaseReducer } from "../../../types/reducer/reducerType";
import type { TResultWithData } from "../../../types/app/appType";

const Information = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { dataFile } = useSelector(
    (state: TbaseReducer) => state.folderReducer
  );

  const { name } = useSelector((state: TbaseReducer) => state.authReducer);
  const [newMessage, setNewMessage] = useState<string>("");

  const handleAddInformation = () => {
    dispatch(
      sendNewInternalInformationMessage(
        { folderId: Number(id), name, message: newMessage },
        (result: TResultWithData) => {
          if (result.success) {
            setNewMessage("");
          }
        }
      )
    );
  };

  return (
    <Card title={"Informations internes"}>
      <>
        <div style={{ overflow: "auto", height: "100%" }}>
          {dataFile.internalInformationMessage &&
            dataFile.internalInformationMessage.map((message, idx) => {
              return (
                <MessageCard
                  key={idx}
                  idx={idx}
                  messageId={message.id}
                  setNewMessage={setNewMessage}
                  {...message}
                />
              );
            })}
        </div>
        <Flex display="flex" gap={10} styles={{ width: "40%" }}>
          <InputText
            className="w-100"
            placeholder={"Ajouter une information"}
            onChange={({ target }: any) => setNewMessage(target.value)}
            value={newMessage}
          />
          <Button text={"Ajouter"} onClick={() => handleAddInformation()} />
        </Flex>
      </>
    </Card>
  );
};

export default Information;

/** Private Components  **/

type MessageCardProps = {
  idx: number;
  name: string;
  messageId: number | undefined;
  insertDate?: string | undefined;
  message: string;
  setNewMessage: Dispatch<SetStateAction<string>>;
};

const MessageCard = ({
  idx,
  name,
  messageId,
  insertDate,
  message,
  setNewMessage,
}: MessageCardProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(
      deleteInternalInformationMessage(
        { folderId: Number(id), messageId },
        (result: TResultWithData) => {
          if (result.success) {
            setNewMessage("");
          }
        }
      )
    );
  };

  return (
    <div
      className={`p-10 mb-10`}
      style={{
        borderRadius: "8px",
        backgroundColor:
          idx % 2 === 0 ? "var(--thirty-color)" : "var(--fourth-color)",
      }}
    >
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="d-flex w-100">
          <h5 style={{ margin: 0, fontSize: "12px", fontStyle: "italic" }}>
            {name}
          </h5>
          <span
            className="ml-5 mt-auto mb-auto"
            style={{ fontSize: "10px", fontStyle: "italic" }}
          >
            {insertDate}
          </span>
          <span
            className="mt-auto mb-auto ml-auto cursor-pointer"
            style={{ fontSize: "10px", fontStyle: "italic", color: "red" }}
            onClick={handleDelete}
          >
            Supprimer
          </span>
        </div>
      </div>
      <p className="m-0 mt-5">{message}</p>
    </div>
  );
};
