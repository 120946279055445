import { TExpertises } from "./expertisesType";
import type { TTasks } from "./actions/tasksTypes";
import type { TInternalInformationMessage } from "./actions/informationType";
import type { TManaging } from "./actions/managingType";

export enum PartiesTypes {
  EXPERT = "expert",
  ASSISTANT = "assistant",
  REPAIRMAN = "repairman",
  INSURANCE = "insurance",
  PRINCIPALE = "principal",
  INSURED = "insured",
}

export type Tparties = {
  address1: string;
  address2: string;
  city: string;
  email: string;
  firstname: string;
  is_pro: boolean;
  phone: string;
  type: PartiesTypes;
  name: string;
  zip_code: string;
};

export type TPartiesData = {
  title: string;
  users: Tparties[];
};

export type TFile = {
  id: number;
  token: string;
  filename: string;
  extension: string;
  data: string;
  timestamp: string;
  path: string;
  type: number;
  typeName: string;
  date: string;
  preselect: string[] | null;
};

export type TDataFile = {
  parties: TPartiesData[];
  agency: any;
  sinister: any;
  vehicle: any;
  mission: any;
  internalInformationMessage: TInternalInformationMessage[];
  managings: TManaging[];
  files: TFile[];
  tasks: TTasks[];
};

export type TFileType = {
  value: number;
  name: string;
  format: string;
};

export type TAvailabilityAgency = {
  id: number;
  nameAgency: string;
  dates: any[]; // TODO: changer le any avec le type correspondant
};

export type TCoordinates = { lat: number; lng: number };

export type TMapData = {
  id: number;
  reference: string;
  locationCode: string;
  natureExpertise: string;
  agencyName: string;
  agencyLat: number;
  agencyLng: number;
  agencyColor: string;
  garageId: number;
  garageName: string;
  garageLat: number;
  garageLng: number;
  garageAddress1: string;
  garageAddress2: string;
  garageCity: string;
  garageZip: number;
  garageError: number;
  clientLat: number;
  clientLng: number;
  clientAddress1: string;
  clientAddress2: string;
  clientCity: string;
  clientZip: number;
  clientError: number;
  expertColor: string;
  expertName: string;
};

export type TMapDataAgencies = {
  agencyId: number;
  agencyLat: number;
  agencyLng: number;
  agencyColor: string;
  agencyName: string;
  folder: TMapData[];
};

export type TMapDataCustomers = {
  customerId: number;
  customerLat: number;
  customerLng: number;
  agencyColor: string;
  agencyName: string;

  folder: TMapData[];
};

export type TMapDataGarages = {
  garageId: number;
  garageLat: number;
  garageLng: number;
  agencyColor: string;
  agencyName: string;

  folder: TMapData[];
};

export type TGoogleMapData = {
  agencies: TMapDataAgencies[];
  customers: TMapDataCustomers[];
  garages: TMapDataGarages[];
};

export type TSwitchButtonKeys =
  | "ImmatButton"
  | "VINButton"
  | "NumPRButton"
  | "DateDernierCTButton";

export type TVehicle = {
  Annee_Modele: string;
  Carrosserie: string;
  Categorie: string;
  Code_Moteur: string | null;
  Constructeur: string;
  Couleur: string;
  Date1MEC: string | null;
  DateCertificatImmat: string;
  DateDernierCT: string;
  Energie: string;
  Finition: string;
  Genre: string;
  Immat: string;
  Modele: string;
  Nation_Immat: string;
  Nb_Places: string;
  NumPR: number | null;
  Numero_Chassis: number | null;
  Puissance_Fiscale: string;
  Puissance_Reelle: string;
  Type_Mine: string;
  VIN: string;
  generalStatus: string;
  formula: string;
  gearbox: string;
} & {
  [K in TSwitchButtonKeys]: boolean;
};

export type THistovec = { operations: []; technical_control: []; url: string };

export type TVehicleInfo = {
  histovec: THistovec | null;
  gaugeInfo: { [key: string]: number } | null;
};

export type TDataMaileva = {
  id: number;
  dateSend: string;
  dateUpdate: string;
  status: string;
  type: string;
};

export type TfolderInitialState = {
  isLoad: boolean;
  dateUpdate: string;
  dateAppointmennt: string;
  numberFolder: string;
  nameInsurance: string;
  logoInsurance: string | null;
  nameAgency: string;
  nameClient: string;
  nameRepairman: string;
  statusHome: string;
  statusColor: string;
  folderId: null | number;
  dataFile: TDataFile;
  fileTypes: TFileType[];
  availabilityAgency: TAvailabilityAgency[];
  expertises: TExpertises[];
  initialExpert: string;
  initialAssistant: string;
  agencyEmail: string;
  agencyId: number | null;
  agencyColor: string;
  questionList: Record<string, boolean> | null;
  agencyCoordinates: TCoordinates | undefined;
  allAgency: TGoogleMapData;
  vehicle: TVehicle;
  vehicleInfo: TVehicleInfo;
  gedImageList: string[];
  dataMaileva: TDataMaileva[];
};

export type TPreslect = "AO" | "RI" | "AU";
