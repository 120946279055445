import {
  TFile,
  THistovec,
  TVehicle,
  TVehicleInfo,
} from "../types/folder/folderType";
import type { TInternalInformationMessage } from "../types/folder/actions/informationType";
import type { TTaskCard } from "../types/folder/actions/tasksTypes";
import { TInsuredData } from "../types/folder/vehicle/vehicleType";
import { TSources } from "../types/folder/actions/managingType";

import { _fetch } from "../utils/fetch";

export enum FolderAction {
  GET_FOLDER = "GET_FOLDER",
  DELETE_FILES = "DELETE_FILES",
  UPDATE_DATA_FILE = "UPDATE_DATA_FILE",
  CHANGE_FOLDER_FILES = "CHANGE_FOLDER_FILES",
  SEND_MESSAGE = "SEND_MESSAGE",
  SEND_EMAIL = "SEND_EMAIL",
  INSERT_FILES = "INSERT_FILES",
  GET_DOCUMENT = "GET_DOCUMENT",
  CONVERT_PICTURE_TO_PDF = "CONVERT_PICTURE_TO_PDF",
  NEW_MANAGING = "NEW_MANAGING",
  UPDATE_MANAGING = "UPDATE_MANAGING",
  GET_UPDATED_MANAGING = "GET_UPDATED_MANAGING",
  SEND_NEW_INTERNAL_INFORMATION_MESSAGE = "SEND_NEW_INTERNAL_INFORMATION_MESSAGE",
  GET_NEW_INTERNAL_INFORMATION_MESSAGE = "GET_NEW_INTERNAL_INFORMATION_MESSAGE",
  DELETE_INTERNAL_INFORMATION_MESSAGE = "DELETE_INTERNAL_INFORMATION_MESSAGE",
  NEW_TASK = "NEW_TASK",
  DELETE_TASK = "DELETE_TASK",
  CLOSE_TASK = "CLOSE_TASK",
  UPDATE_PRESELECT_FILE = "UPDATE_PRESELECT_FILE",
  ADD_EXPERTISE = "ADD_EXPERTISE",
  ADD_CHOC = "ADD_CHOC",
  ADD_CHOC_LINE = "ADD_CHOC_LINE",
  UPDATE_CHOC_LINE = "UPDATE_CHOC_LINE",
  DELETE_CHOC_LINE = "DELETE_CHOC_LINE",
  INSERT_MESSAGE = "INSERT_MESSAGE",
  GET_AVAILABILITY_AGENCY = "GET_AVAILABILITY_AGENCY",
  UPDATE_QUESTION_LIST = "UPDATE_QUESTION_LIST",
  GET_ALL_AGENCY = "GET_ALL_AGENCY",
  GET_HISTOVEC = "GET_HISTOVEC",
  GET_HISTOVEC_PDF = "GET_HISTOVEC_PDF",
  UPDATE_GAUGE_INFO = "UPDATE_GAUGE_INFO",
  UPDATE_VEHICLE_INFO = "UPDATE_VEHICLE_INFO",
  UPDATE_GED_IMAGE_LIST = "UPDATE_GED_IMAGE_LIST",
}

export const get = (id: string | undefined, disableLoader?: boolean) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/get",
      FolderAction.GET_FOLDER,
      JSON.stringify({ id }),
      dispatch,
      () => {},
      null,
      disableLoader
    );
  };
};

export const deleteFiles = (
  id: string | undefined,
  tokens: Array<string>,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/delete-files",
      FolderAction.DELETE_FILES,
      JSON.stringify({ id, tokens }),
      dispatch,
      callback,
      null
    );
  };
};

export const updateDataFileOff = (
  token: string,
  filename: string,
  type: number
) => {
  return (dispatch: any) => {
    dispatch({
      type: FolderAction.UPDATE_DATA_FILE,
      success: true,
      body: { token, filename, type },
    });
  };
};

export const updateDataFile = (
  id: string | undefined,
  token: string,
  filename: string,
  type: number,
  callback?: Function,
  disableLoader?: boolean
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/update-data-file",
      FolderAction.UPDATE_DATA_FILE,
      JSON.stringify({ id, token, filename, type }),
      dispatch,
      callback,
      null,
      disableLoader
    );
  };
};

export const changeFolderFiles = (
  folderId: Number,
  fileTokens: Array<string>,
  callback: Function,
  isCopy?: Boolean
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/change-folder-files",
      FolderAction.CHANGE_FOLDER_FILES,
      JSON.stringify({ folderId, fileTokens, isCopy }),
      dispatch,
      callback,
      null
    );
  };
};

export const sendMessage = (
  folderId: string | undefined,
  referenceId: string | null,
  type: number,
  from: string,
  to: string[],
  picturesSelected: (TFile | undefined)[],
  subject: string,
  message: string,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/send-message",
      FolderAction.SEND_MESSAGE,
      JSON.stringify({
        folderId,
        referenceId,
        type,
        from,
        to,
        attachments: picturesSelected,
        subject,
        message,
      }),
      dispatch,
      callback,
      null
    );
  };
};

export const sendEmail = (
  folderId: string | undefined,
  from: string,
  agencyId: number | null,
  to: string[],
  picturesSelected: (TFile | undefined)[],
  subject: string,
  message: string,
  messageToReply: string | null | undefined,
  isMessageMail: boolean,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/send-email",
      FolderAction.SEND_EMAIL,
      JSON.stringify({
        folderId,
        from,
        agencyId,
        to,
        attachments: picturesSelected,
        subject,
        message,
        messageToReply,
        isMessageMail,
      }),
      dispatch,
      callback,
      null
    );
  };
};

export const insertFiles = (
  folderId: string | undefined,
  files: Array<any>,
  callback: Function | null
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/insert-files",
      FolderAction.INSERT_FILES,
      JSON.stringify({ folderId, files }),
      dispatch,
      callback,
      null
    );
  };
};

export const getDocumentPDF = (
  token: string,
  data: object,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/get-document",
      FolderAction.GET_DOCUMENT,
      JSON.stringify({ token, data }),
      dispatch,
      callback,
      null
    );
  };
};

export const convertPictureToPDF = (
  folderId: string | undefined,
  file: TFile,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/convert-picture-to-pdf",
      FolderAction.CONVERT_PICTURE_TO_PDF,
      JSON.stringify({ file, folderId }),
      dispatch,
      callback,
      null
    );
  };
};

export const newManaging = (
  data: {
    folderId: number;
    from: number | null;
    fromSource: TSources;
    toSource: TSources;
    subject: string;
    grade: number;
  },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/new-managing",
      FolderAction.NEW_MANAGING,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null,
      true
    );
  };
};

export const updateManaging = (
  managingId: number,
  folderId: string | undefined,
  data: object,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/update-managing",
      FolderAction.UPDATE_MANAGING,
      JSON.stringify({ managingId, data, folderId }),
      dispatch,
      callback,
      null,
      true
    );
  };
};

export const getUpdatedManaging = (managingId: number, callback?: Function) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/get-updated-managing",
      FolderAction.GET_UPDATED_MANAGING,
      JSON.stringify({ managingId }),
      dispatch,
      callback,
      null
    );
  };
};

export const updatePreselectFile = (
  data: {
    files: { id: number; preselect: string[] }[];
    folderId: string;
  },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/update-preselect-file",
      FolderAction.UPDATE_PRESELECT_FILE,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const sendNewInternalInformationMessage = (
  data: TInternalInformationMessage,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/send-internal-information-message",
      FolderAction.SEND_NEW_INTERNAL_INFORMATION_MESSAGE,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const insertMessage = (
  data: {
    toSource: TSources;
    folderId: number;
    fromId: number | null;
    metadata: { to: string[]; optionnalMail?: string[] };
    filesToken: string[];
    message: string;
    subject: string;
    fromSource: TSources;
    grade: number;
  },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/insert-message",
      FolderAction.INSERT_MESSAGE,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const deleteInternalInformationMessage = (
  data: { folderId: number; messageId: number | undefined },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/delete-information-message",
      FolderAction.DELETE_INTERNAL_INFORMATION_MESSAGE,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const newTask = (
  data: Omit<
    TTaskCard & { folderId: number },
    "idx" | "dateEnd" | "id" | "isDelete" | "closeDate" | "date"
  >,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/tasks/new-task",
      FolderAction.NEW_TASK,
      JSON.stringify({ data }),
      dispatch,
      callback
    );
  };
};

export const deleteTask = (
  data: { folderId: number; taskId: number },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/tasks/delete-task",
      FolderAction.DELETE_TASK,
      JSON.stringify({ data }),
      dispatch,
      callback
    );
  };
};

export const closeTask = (
  data: { closeDate: string | null; folderId: number; taskId: number },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/tasks/close-task",
      FolderAction.CLOSE_TASK,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const getAvailabilityAgency = (
  data: { agencyId: number | null; nameAgency: string },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/availability-agency",
      FolderAction.GET_AVAILABILITY_AGENCY,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const getAllAgency = (callback?: Function) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/all-agency",
      FolderAction.GET_ALL_AGENCY,
      JSON.stringify({}),
      dispatch,
      callback
    );
  };
};

export const updateQuestionList = (
  data: {
    folderId: number | null;
    questionId: number | null;
    value: boolean;
    questionList: Record<string, boolean> | null;
  },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/update-question-list",
      FolderAction.UPDATE_QUESTION_LIST,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const getHistovec = (
  data: TInsuredData & { folderId?: string; vehicleInfo: TVehicleInfo },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/get-histovec",
      FolderAction.GET_HISTOVEC,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const getHistovecPDF = (data: THistovec | null, callback?: Function) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/get-histovec-pdf",
      FolderAction.GET_HISTOVEC_PDF,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null
    );
  };
};

export const updateGaugeInfo = (
  data: {
    [key: string]: number | string | TVehicleInfo;
    folderId: string;
    vehicleInfo: TVehicleInfo;
    source: "gaugeInfo" | "vehicleData";
  },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/update-gauge-info",
      FolderAction.UPDATE_GAUGE_INFO,
      JSON.stringify({ data }),
      dispatch,
      callback,
      null,
      true
    );
  };
};

export const updateVehicleInfo = (
  data: {
    newVehicleData: TVehicle;
    folderId: string;
    vehicleInfo: TVehicleInfo;
    source: "gaugeInfo" | "vehicleData";
  },
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/folder/update-vehicle-info",
      FolderAction.UPDATE_VEHICLE_INFO,
      JSON.stringify({
        data: {
          ...data.newVehicleData,
          folderId: data.folderId,
          vehicleInfo: data.vehicleInfo,
          source: data.source,
        },
      }),
      dispatch,
      callback,
      null,
      true
    );
  };
};
