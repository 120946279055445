import { _fetch } from "../utils/fetch";

export enum LibraryAction {
  GET_LIBRARY = "GET_LIBRARY",
  ADD_LIBRARY = "ADD_LIBRARY",
  EDIT_LIBRARY = "EDIT_LIBRARY",
  DELETE_LIBRARY = "DELETE_LIBRARY",
  ADD_NOTE_LIBRARY = "ADD_NOTE_LIBRARY",
}

export const getLibrary = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/library/get-library",
      LibraryAction.GET_LIBRARY,
      JSON.stringify({ search: null, category: null }),
      dispatch
    );
  };
};

export const addLibrary = (
  name: string,
  keyword: string,
  category: string,
  file: null | object,
  url: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/library/add-library",
      LibraryAction.ADD_LIBRARY,
      JSON.stringify({
        name,
        keyword,
        category,
        file,
        url,
      }),
      dispatch
    );
  };
};

export const editLibrary = (
  id: number | undefined,
  name: string | undefined,
  keyword: string | undefined,
  category: string | undefined,
  callback?: Function
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/library/edit-library",
      LibraryAction.EDIT_LIBRARY,
      JSON.stringify({
        id,
        name,
        keyword,
        category,
      }),
      dispatch,
      callback
    );
  };
};

export const deleteLibrary = (id: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/library/delete-library",
      LibraryAction.DELETE_LIBRARY,
      JSON.stringify({ id }),
      dispatch
    );
  };
};

export const addNoteLibrary = (id: number, note: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/library/add-note-library",
      LibraryAction.ADD_NOTE_LIBRARY,
      JSON.stringify({ id, note }),
      dispatch
    );
  };
};
