import { ModalAction } from "../actions/modalAction";
import { TAction, TModalInitialState } from "../types/app/appType";

export const initialState = {
  confirmModal: {
    isOpen: false,
    title: "",
    message: "",
    onConfirm: () => {},
  },
  uploadModal: {
    isOpen: false,
    title: "",
    message: "",
    onConfirm: () => {},
  },
  searchFoldersModal: {
    isOpen: false,
    title: "",
    message: "",
    activeLines: [],
    option: null,
    onConfirm: () => {},
  },
  managingHistoryModal: {
    isOpen: false,
    currentManagingId: null,
    managingIdx: null,
  },
  infoModal: {
    isOpen: false,
    title: "",
    message: "",
  },
  addLibraryModal: {
    isOpen: false,
    title: "",
    message: "",
  },
  editLibraryModal: {
    isOpen: false,
    title: "",
    message: "",
    id: 0,
  },
  customModal: {
    children: null,
    isOpen: false,
  },
  flexModal: {
    children: null,
    isOpen: false,
    target: null,
  },
  messageModal: {
    messageOptions: null,
    attachments: undefined,
    messages: undefined,
    subject: undefined,
    isOpen: false,
  },
} satisfies TModalInitialState;

export const modalReducer = (state = initialState, action: TAction) => {
  const { onConfirm, message, title, id } = action.data || {
    isOpen: false,
    message: "",
    onConfirm: () => {},
  };

  switch (action.type) {
    case ModalAction.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          isOpen: true,
          message,
          title,
          onConfirm,
        },
      };
    case ModalAction.CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          isOpen: false,
        },
      };
    case ModalAction.OPEN_UPLOAD_MODAL:
      return {
        ...state,
        uploadModal: {
          ...state.uploadModal,
          isOpen: true,
          onConfirm,
        },
      };
    case ModalAction.CLOSE_UPLOAD_MODAL:
      return {
        ...state,
        uploadModal: {
          ...state.uploadModal,
          isOpen: false,
        },
      };
    case ModalAction.CLOSE_CURRENT_MODAL:
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          isOpen: false,
        },
        uploadModal: {
          ...state.uploadModal,
          isOpen: false,
        },
        searchFoldersModal: {
          ...state.searchFoldersModal,
          isOpen: false,
        },
        infoModal: {
          ...state.infoModal,
          isOpen: false,
        },
        managingHistoryModal: {
          ...state.managingHistoryModal,
          isOpen: false,
        },
        addLibraryModal: {
          ...state.addLibraryModal,
          isOpen: false,
        },
        editLibraryModal: {
          ...state.editLibraryModal,
          isOpen: false,
        },
        customModal: {
          isOpen: false,
        },
        flexModal: {
          isOpen: false,
        },
        messageModal: {
          isOpen: false,
        },
      };
    case ModalAction.OPEN_SEARCH_FOLDERS_MODAL:
      return {
        ...state,
        searchFoldersModal: {
          ...state.searchFoldersModal,
          activeLines: action.data.activeLines,
          option: action.data.option,
          isOpen: true,
          onConfirm,
        },
      };
    case ModalAction.CLOSE_SEARCH_FOLDERS_MODAL:
      return {
        ...state,
        searchFoldersModal: {
          ...state.searchFoldersModal,
          isOpen: false,
        },
      };
    case ModalAction.OPEN_INFO_MODAL:
      return {
        ...state,
        infoModal: {
          ...state.infoModal,
          isOpen: true,
          title,
          message,
        },
      };
    case ModalAction.CLOSE_INFO_MODAL:
      return {
        ...state,
        infoModal: {
          ...state.infoModal,
          isOpen: false,
        },
      };
    case ModalAction.OPEN_HISTORY_MANAGING_MODAL:
      return {
        ...state,
        managingHistoryModal: {
          ...state.managingHistoryModal,
          currentManagingId: action.data.currentManagingId,
          managingIdx: action.data.managingIdx,
          isOpen: true,
        },
      };
    case ModalAction.CLOSE_HISTORY_MANAGING_MODAL:
      return {
        ...state,
        managingHistoryModal: {
          ...state.managingHistoryModal,
          isOpen: false,
        },
      };
    case ModalAction.OPEN_ADD_LIBRARY_MODAL:
      return {
        ...state,
        addLibraryModal: {
          ...state.addLibraryModal,
          isOpen: true,
          title,
          message,
        },
      };
    case ModalAction.OPEN_EDIT_LIBRARY_MODAL:
      return {
        ...state,
        editLibraryModal: {
          ...state.editLibraryModal,
          isOpen: true,
          title,
          message,
          id: id || 0,
        },
      };
    case ModalAction.OPEN_CUSTOM_MODAL:
      return {
        ...state,
        customModal: {
          ...state.customModal,
          children: action.data.children,
          target: action.data.target,
          isOpen: true,
        },
      };
    case ModalAction.CLOSE_CUSTOM_MODAL:
      return {
        ...state,
        customModal: {
          ...state.customModal,
          isOpen: false,
        },
      };
    case ModalAction.OPEN_FLEX_MODAL:
      return {
        ...state,
        flexModal: {
          ...state.flexModal,
          target: action.data.target || "root",
          children: action.data.children,
          position: action.data.position,
          isOpen: true,
        },
      };
    case ModalAction.CLOSE_FLEX_MODAL:
      return {
        ...state,
        flexModal: {
          ...state.flexModal,
          isOpen: false,
        },
      };
    case ModalAction.OPEN_MESSAGE_MODAL:
      return {
        ...state,
        messageModal: {
          ...state.messageModal,
          messageOptions: action.data.messageOptions,
          attachments: action.data.attachments,
          messages: action.data.messages,
          subject: action.data.subject,
          fromEmail: action.data.fromEmail,
          reply: action.data.reply,
          emailDate: action.data.emailDate,
          metadata: action.data.metadata,
          isOpen: true,
        },
      };
    case ModalAction.CLOSE_MESSAGE_MODAL:
      return {
        ...state,
        messageModal: {
          ...state.messageModal,
          isOpen: false,
        },
      };
    default:
      return state;
  }
};
