import FolderView from "../views/FolderView";
import LibraryView from "../views/LibraryView";
import NotFoundView from "../views/NotFoundView";
import SearchView from "../views/SearchView";
import StatusView from "../views/StatusView";
import TasksView from "../views/TasksView";

export const routes = [
  {
    path: "/",
    element: <SearchView />,
  },
  {
    path: "/tasks",
    element: <TasksView />,
  },
  {
    path: "/status",
    element: <StatusView />,
  },
  {
    path: "/folder/:id",
    element: <FolderView />,
  },
  {
    path: "/folder/:id/:primary",
    element: <FolderView />,
  },
  {
    path: "/folder/:id/:primary/:secondary",
    element: <FolderView />,
  },
  {
    path: "/library",
    element: <LibraryView />,
  },
  {
    path: "/library-empty",
    hideDashboard: true,
    element: <LibraryView />,
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
];
