/**
 * Checks invalid email addresses in a given table
 * @param {string[]} emails Table of email addresses to check.
 * @returns {number[]} Table containing indexes of invalid email addresses.
 */
export const getInvalidEmails = (emails: string[]): number[] => {
  const invalidEmails = emails.reduce((acc: number[], email, index) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      acc.push(index);
    }
    return acc;
  }, []);

  return invalidEmails;
};
