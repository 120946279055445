/* Libs */
import { Col, Row } from "react-flexbox-grid";
import {
  Button,
  Card,
  Icon,
  InputDate,
  InputText,
  MultipleSelectSearch,
  MultipleSelect,
  useToggle,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Actions */
import { SearchAction } from "../../actions/searchAction";

/* Types */
import { Toptions } from "../../types/search/searchType";
import { TbaseReducer } from "../../types/reducer/reducerType";

/* Configs */
import { VEHICLE_BRAND } from "../../configs/vehicleBrand";

const Filter = () => {
  const dispatch = useAppDispatch();
  const [showAllInput, setShowAllInput] = useToggle();

  const {
    isLoadCount,
    order,
    activePage,
    filters,
    activeFilters,
    totalItems,
    items,
    optionsInsurance,
    optionsNatureEvent,
    optionsNatureExpertise,
    optionsRegion,
    optionsAgency,
    optionsExpert,
    optionsAssistant,
    optionsFolderStatus,
  } = useSelector((state: TbaseReducer) => state.searchReducer);

  const getInputDate = (title: string, key: string) => {
    return (
      <Col xs={12} md={4} lg={2} className="mb-20">
        <InputDate
          label={title}
          value={filters[key] || ""}
          onChange={({ target }: any) => {
            dispatch({
              type: SearchAction.UPDATE_FILTERS,
              data: Object.assign({}, filters, {
                [key]: target.value,
              }),
            });
          }}
        />
      </Col>
    );
  };

  const getInputText = (title: string, key: string) => {
    return (
      <Col xs={12} md={4} lg={2}>
        <InputText
          onKeyDown={(e) => {
            if (
              JSON.stringify(filters) !== JSON.stringify(activeFilters) &&
              e.key === "Enter"
            ) {
              dispatch({
                type: SearchAction.SET_ACTIVE_FILTERS,
              });
            }
          }}
          className="mb-10"
          label={title}
          value={filters[key] || ""}
          placeholder=""
          onChange={({ target }: any) => {
            dispatch({
              type: SearchAction.UPDATE_FILTERS,
              data: Object.assign({}, filters, {
                [key]: target.value,
              }),
            });
          }}
        />
      </Col>
    );
  };

  const getMultipleSelect = (
    title: string,
    key: string,
    options: Array<any>
  ) => {
    return (
      <Col xs={12} md={4} lg={2}>
        <MultipleSelect
          sortAlphabetically
          className="mb-10"
          label={title}
          placeholder={title}
          options={options}
          value={filters[key] || []}
          onChange={(value) => {
            dispatch({
              type: SearchAction.UPDATE_FILTERS,
              data: Object.assign({}, filters, {
                [key]: value,
              }),
            });
          }}
        />
      </Col>
    );
  };

  const getMultipleSelectSearch = (
    title: string,
    key: string,
    options: Array<any>
  ) => {
    return (
      <Col xs={12} md={4} lg={2}>
        <MultipleSelectSearch
          sortAlphabetically
          className="mb-10"
          label={title}
          placeholder={title}
          options={options}
          value={filters[key] || []}
          onChange={(value) => {
            dispatch({
              type: SearchAction.UPDATE_FILTERS,
              data: Object.assign({}, filters, {
                [key]: value,
              }),
            });
          }}
        />
      </Col>
    );
  };

  const getIconInput = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "end",
        }}
      >
        <div
          onClick={() => setShowAllInput()}
          style={{
            display: "flex",
            alignItems: "center",
            transform: "rotate(90deg)",
            padding: "5px",
            border: "solid 1px var(--light-grey)",
            borderRadius: "10px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
        >
          <Icon icon={showAllInput ? "Cross" : "DotVertical"} size={10} />
        </div>
      </div>
    );
  };

  const yearFolderOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: currentYear - 2015 },
      (_, idx) => 2016 + idx
    );
    return years.map((year) => ({ text: year, value: year }));
  };

  const optionsFilter = (options: Toptions[]) => {
    const { nameRegion, nameAgency } = filters;

    if (!nameRegion) {
      return options;
    }
    return options.filter((option) => {
      if (nameAgency && nameAgency.length && option.agencyId) {
        return (
          nameRegion.includes(option.regionId) &&
          nameAgency.includes(option.agencyId)
        );
      } else {
        return (
          nameRegion &&
          nameRegion.length &&
          nameRegion.includes(option.regionId)
        );
      }
    });
  };
  return (
    <Card className="mt-20" title="Filtres">
      <>
        <Row>
          {getInputDate("Date début sinistre", "start_dateSinister")}
          {getInputDate("Date fin sinistre", "end_dateSinister")}
          {getInputDate("Date début mission", "start_dateMission")}
          {getInputDate("Date fin mission", "end_dateMission")}
          {getInputDate("Date début RDV", "start_dateAppointmennt")}
          {getInputDate("Date fin RDV", "end_dateAppointmennt")}
          {getInputDate("Date début dépôt", "start_dateClosing")}
          {getInputDate("Date fin dépôt", "end_dateClosing")}
        </Row>
        <Row>
          {getInputText("Numéro de dossier", "numberFolder")}
          {getInputText("Numéro de sinistre", "numberSinister")}
          {getInputText("Assuré", "nameClient")}
          {getMultipleSelect(
            "Année du dossier",
            "dateMission",
            yearFolderOptions()
          )}
          {getMultipleSelect(
            "État du dossier",
            "statusFolder",
            optionsFolderStatus
          )}
          {getIconInput()}
        </Row>
        {showAllInput && (
          <>
            <Row>
              {getMultipleSelectSearch(
                "Marque véhicule",
                "vehicleBrand",
                VEHICLE_BRAND.map((brand) => ({ text: brand, value: brand }))
              )}
              {getInputText("Modèle véhicule", "vehicleModel")}
              {getInputText("Immatriculation", "vehicleLicensePlate")}
              {getInputText("Mandant", "namePrincipal")}
              {getInputText("Réparateur", "nameRepairman")}
            </Row>
            <Row>
              {getMultipleSelect(
                "Compagnie",
                "nameInsurance",
                optionsInsurance
              )}
              {getMultipleSelect("Région", "nameRegion", optionsRegion)}
              {getMultipleSelect(
                "Agence",
                "nameAgency",
                optionsFilter(optionsAgency)
              )}
              {getMultipleSelect(
                "Expert",
                "nameExpert",
                optionsFilter(optionsExpert)
              )}
              {getMultipleSelect(
                "Secretaire",
                "nameAssistant",
                optionsFilter(optionsAssistant)
              )}
            </Row>
            <Row>
              {getMultipleSelect(
                "Nature d'évènement",
                "natureEvenment",
                optionsNatureEvent
              )}
              {getMultipleSelect(
                "Nature d'expertise",
                "natureExpertise",
                optionsNatureExpertise
              )}
            </Row>
          </>
        )}
        <div className="d-flex">
          <div
            className="ml-auto search-view-filter-button-flex-direction"
            style={{ display: "flex", gap: 15 }}
          >
            <Button
              outline
              text={"Rénitialiser"}
              onClick={() => {
                dispatch({
                  type: SearchAction.UPDATE_FILTERS,
                  data: {},
                });
                dispatch({
                  type: SearchAction.SET_ACTIVE_FILTERS,
                });
              }}
            />
            <Button
              text={"Rechercher"}
              onClick={() => {
                if (JSON.stringify(filters) !== JSON.stringify(activeFilters)) {
                  dispatch({
                    type: SearchAction.SET_ACTIVE_FILTERS,
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    </Card>
  );
};

export default Filter;
