/* Libs */
import {
  Flex,
  HeaderActionCard,
  Icon,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

/* Components */
import Costing from "./costing/Costing";

/* Types */
import { TbaseReducer } from "../../../types/reducer/reducerType";
import { FolderAction } from "../../../actions/folderAction";
import { useAppDispatch } from "../../../reducers";

const Expertise = () => {
  const dispatch = useAppDispatch();
  const [tabsActive, setTabsActive] = useState(2);
  const expertises = useSelector(
    (state: TbaseReducer) => state.folderReducer.expertises,
    (oldArray, newArray) =>
      shallowEqual(JSON.stringify(oldArray), JSON.stringify(newArray))
  );
  const [currentExpertise, setCurrentExpertise] = useState(
    expertises.length - 1
  );
  const [currentchoc, setCurrentChoc] = useState(0);

  useEffect(() => {
    setCurrentExpertise(expertises.length - 1);
    setCurrentChoc(0);
  }, [expertises.length]);

  const tabs = [
    {
      id: 1,
      text: "Conditions",
      onClick: () => setTabsActive(1),
      disabled: true,
      active: tabsActive === 1,
    },
    {
      id: 2,
      text: "Chiffrage",
      onClick: () => setTabsActive(2),
      disabled: false,
      active: tabsActive === 2,
    },
    {
      id: 2,
      text: "Conclusion",
      onClick: () => setTabsActive(3),
      disabled: true,
      active: tabsActive === 3,
    },
  ];

  const getComponent = (activeId: number): JSX.Element => {
    switch (activeId) {
      case 1:
        return <div>Conditions Component</div>;
      case 2:
        return (
          <Costing
            currentExpertise={currentExpertise}
            currentchoc={currentchoc}
            setCurrentChoc={setCurrentChoc}
          />
        );
      case 3:
        return <div>Conclusion Component</div>;
      default:
        return <></>;
    }
  };

  return (
    <Flex direction="column" gap={20}>
      <HeaderActionCard
        actionText={expertises?.map((expertise, idx) => ({
          id: expertise.id,
          text: `Expertise n°${idx + 1}`,
          onClick: () => {
            setCurrentChoc(expertises[idx].chocs.length - 1);
            setCurrentExpertise(idx);
          },
          disabled: false,
          active: idx === currentExpertise,
        }))}
        auto
        endComponent={() => (
          <Icon
            style={{ marginLeft: "20px" }}
            icon={"Add"}
            backgroundHover
            size={20}
            onClick={() => dispatch({ type: FolderAction.ADD_EXPERTISE })}
          />
        )}
      />
      <HeaderActionCard actionText={tabs} auto style={{ width: "30%" }} />
      {getComponent(tabsActive)}
    </Flex>
  );
};

export default Expertise;
