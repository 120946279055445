import { TasksAction } from "../actions/tasksAction";
import type { TtasksInitialState } from "../types/folder/actions/tasksTypes";

export const initialState = {
  isLoad: false,
  filters: [],
  activeFilters: {},
  activePage: 1,
  totalTasks: 0,
  isLoadCount: true,
  tasks: [],
  optionsRegion: [],
  optionsAgency: [],
  optionsInsurance: [],
} satisfies TtasksInitialState;

export const tasksReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TasksAction.LOAD_TASKS:
      return {
        ...state,
        isLoad: true,
      };
    case TasksAction.CHANGE_TASKS_ACTIVE_PAGE:
      return {
        ...state,
        isLoad: true,
        activePage: action.data,
      };
    case TasksAction.UPDATE_TASKS_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case TasksAction.SET_TASKS_ACTIVE_FILTERS:
      return {
        ...state,
        isLoadCount: true,
        activeFilters: state.filters,
      };
    case TasksAction.COUNT_TASKS:
      return {
        ...state,
        isLoadCount: false,
        totalTasks: action.data,
      };
    case TasksAction.GET_TASKS:
      return {
        ...state,
        tasks: action.data,
        isLoad: false,
      };
    case TasksAction.GET_TAKS_OPTIONS:
      return {
        ...state,
        optionsRegion: action.optionsRegion,
        optionsAgency: action.optionsAgency,
        optionsInsurance: action.optionsInsurance,
      };
    default:
      return state;
  }
};
