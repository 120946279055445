import { _fetch } from "../utils/fetch";

export enum TasksAction {
  GET_TASKS = "GET_TASKS",
  LOAD_TASKS = "LOAD_TASKS",
  CHANGE_TASKS_ACTIVE_PAGE = "CHANGE_TASKS_ACTIVE_PAGE",
  UPDATE_TASKS_FILTERS = "UPDATE_TASKS_FILTERS",
  SET_TASKS_ACTIVE_FILTERS = "SET_TASKS_ACTIVE_FILTERS",
  COUNT_TASKS = "COUNT_TASKS",
  GET_TAKS_OPTIONS = "GET_TAKS_OPTIONS",
}

export const countTasks = (activeFilters: any) => {
  return (dispatch: any) => {
    _fetch(
      "/api/tasks/count",
      TasksAction.COUNT_TASKS,
      JSON.stringify({ filters: activeFilters }),
      dispatch
    );
  };
};

export const getTasks = (filters: any, value: number, offset: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/tasks/pagination",
      TasksAction.GET_TASKS,
      JSON.stringify({ filters, value, offset }),
      dispatch
    );
  };
};

export const getOptions = () => {
  return (dispatch: any) => {
    _fetch("/api/tasks/options", TasksAction.GET_TAKS_OPTIONS, "", dispatch);
  };
};
