/* Libs */
import { Flex, Icon, Modal } from "@sahadevia/ui-kit-modern-sahadevia";
import { CSSProperties } from "react";

/* Reducers */
import { useAppDispatch } from "../reducers";

/* Actions */
import { ModalAction } from "../actions/modalAction";

type Props = {
  children: JSX.Element;
  target?: string | null;
  styles?: CSSProperties;
  disableOverflow?: boolean;
  customStyles?: CSSProperties;
};

const ModalLayout = ({
  children,
  styles,
  disableOverflow,
  customStyles,
  target,
}: Props) => {
  const dispatch = useAppDispatch();
  return (
    <Modal
      onClick={() => dispatch({ type: ModalAction.CLOSE_CURRENT_MODAL })}
      target={target || "root"}
      style={styles}
    >
      <Flex
        classNames="shadow"
        onClick={(e) => e?.stopPropagation()}
        display="flex"
        direction="column"
        styles={{
          background: "var(--white)",
          border: "1px solid var(--light-grey)",
          padding: "15px 20px",
          borderRadius: 8,
          minWidth: 460,
          maxHeight: "calc(100% - 40px)",
          maxWidth: "calc(100% - 40px)",
          ...customStyles,
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <Icon
            style={{
              backgroundColor: "var(--red-light)",
              position: "absolute",
              borderRadius: "50%",
              right: -35,
              top: -35,
              padding: 6,
            }}
            icon={"Cross"}
            size={15}
            color="var(--white)"
            onClick={() => dispatch({ type: ModalAction.CLOSE_CURRENT_MODAL })}
          />
        </div>

        <div
          id="modalLayout"
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: !disableOverflow ? "auto" : undefined,
            gap: 20,
            ...customStyles,
          }}
        >
          {children}
        </div>
      </Flex>
    </Modal>
  );
};

export default ModalLayout;
