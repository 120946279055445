/* Libs */
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Flex,
  InputCheckbox,
  MultipleSelect,
  Pagination,
  StatusColor,
  Table,
  tableColumnType,
} from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";
import { useParams } from "react-router-dom";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Action */
import {
  closeTask,
  deleteTask,
  newManaging,
  newTask,
} from "../../actions/folderAction";
import { TasksAction } from "../../actions/tasksAction";

/* Types */
import { TResultBase } from "../../types/app/appType";
import { TbaseReducer } from "../../types/reducer/reducerType";
import { TTaskCard, TTasks } from "../../types/folder/actions/tasksTypes";

/* Utils */
import { getGradeColor, getGradeLevel } from "../../utils/getGradeColor";
import { PAGE_LENGTH } from "../../configs/pagination";

type Props = {
  data: TTasks[];
  title: string;
  disableAddTask?: boolean;
};

const optionsSubject = [
  { value: "Objet1", text: "Objet1" },
  { value: "Objet2", text: "Objet2" },
  { value: "Objet3", text: "Objet3" },
];

const TasksTable = ({ data, title, disableAddTask }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { userList } = useSelector(
    (state: TbaseReducer) => state.staticReducer
  );
  const { name, userId } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );
  const { activePage, totalTasks } = useSelector(
    (state: TbaseReducer) => state.tasksReducer
  );

  const [showCloturedTasks, setShowCloturedTasks] = useState(false);
  const [colFilter, setColFilter] = useState<(string | number)[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleAddRow = (newData: any) => {
    const { messageTo, grade, message, subject } = newData;
    dispatch(
      newTask(
        {
          folderId: Number(id),
          grade: getGradeLevel(grade),
          messageFrom: name,
          messageTo,
          subject: subject,
          message,
        },
        (result: TResultBase) => {
          if (result.success) {
            dispatch(
              newManaging({
                folderId: Number(id),
                from: userId,
                fromSource: "gla-software",
                toSource: "actions",
                subject: subject + " (Ajouté)",
                grade: getGradeLevel(grade),
              })
            );
          }
        }
      )
    );
  };

  const handleLockOrUnlockTask = (task: any) => {
    const {
      closeDate,
      id: taskId,
      subject,
      messageFrom,
      grade,
      folderId,
    } = task;

    dispatch(
      closeTask(
        {
          closeDate: !!closeDate
            ? null
            : moment(new Date().toString()).format("DD/MM/YYYY"),
          taskId,
          folderId: folderId,
        },
        (result: TResultBase) => {
          if (result) {
            dispatch(
              newManaging({
                folderId: folderId,
                from: messageFrom,
                fromSource: "gla-software",
                toSource: "actions",
                subject:
                  subject + `${closeDate ? " (Déclôturé)" : " (Clôturé)"}`,
                grade,
              })
            );
          }
        }
      )
    );
  };

  const handleDeleteTask = (task: any) => {
    const { id: taskId, subject, messageFrom, grade, folderId } = task;

    dispatch(
      deleteTask(
        {
          taskId,
          folderId: folderId,
        },
        (result: TResultBase) => {
          if (result) {
            dispatch(
              newManaging({
                folderId: folderId,
                from: messageFrom,
                fromSource: "gla-software",
                toSource: "actions",
                subject: subject + " (Supprimé)",
                grade,
              })
            );
          }
        }
      )
    );
  };

  const filterColumns = (columns: any, colFilter: (string | number)[]) => {
    if (colFilter.length) {
      return columns.filter((objet: any) =>
        colFilter.some((colKey) => colKey === objet.key)
      );
    } else {
      return columns;
    }
  };

  const tasksFilter = (data: Omit<TTaskCard, "idx">[]) => {
    if (Object.keys(data).length) {
      return data.filter(
        (task) =>
          (!task.isDelete && showCloturedTasks && task.closeDate) ||
          (!showCloturedTasks && !task.closeDate)
      );
    } else return [];
  };

  const columns: tableColumnType<TTasks>[] = [
    {
      key: "",
      label: "N°",
      component: (_, idx) => <>{idx! + 1}</>,
    },
    {
      key: "grade",
      label: "Niveau",
      isSelectColor: true,
      component: (item: any) => {
        return <StatusColor color={getGradeColor(item.grade)} />;
      },
    },
    {
      key: "date",
      label: "Date",
    },
    {
      key: "messageFrom",
      label: "De",
    },
    {
      key: "messageTo",
      label: "À",
      isSelect: true,
      selectData:
        userList &&
        userList.map((user) => ({
          text: user.name,
          value: user.name,
        })),
    },
    {
      key: "subject",
      label: "Objet",
      isSelect: true,
      selectData: optionsSubject,
    },
    {
      key: "message",
      label: "Tâche",
      isInput: true,
    },
    {
      key: "closeDate",
      label: "Date clôture",
    },
  ];

  return (
    <Table
      disableOverflow={isDropdownOpen}
      data={tasksFilter(data)}
      bottomAddRow={!colFilter.length && !disableAddTask}
      handleAddRow={handleAddRow}
      headerComponent={
        <Flex
          display="flex"
          justify="space-between"
          gap={5}
          styles={{ padding: 20 }}
        >
          <h2 style={{ margin: 0, fontSize: "18px" }}>{title}</h2>

          <Flex display="flex" gap={10}>
            <InputCheckbox
              text={"Afficher les tâches clôturées"}
              isCheck={showCloturedTasks}
              onClick={() => setShowCloturedTasks(!showCloturedTasks)}
              onChange={() => null}
            />
            <div style={{ width: "200px" }}>
              <MultipleSelect
                isDropdownOpen={(value) => setIsDropdownOpen(value)}
                options={columns.map((col) => ({
                  text: col.label,
                  value: col.key,
                }))}
                placeholder={"Sélection de colonnes"}
                onChange={(value) => {
                  setColFilter(value);
                }}
                value={colFilter}
              />
            </div>
          </Flex>
        </Flex>
      }
      customRowStyle={(item) => {
        return item.closeDate ? { background: "var(--light-grey)" } : {};
      }}
      columns={filterColumns(columns, colFilter)}
      context={null}
      footerComponent={
        <div
          style={{ display: "flex", justifyContent: "end", padding: "16px" }}
        >
          <Pagination
            totalItems={totalTasks}
            itemsPerPage={PAGE_LENGTH}
            onPageChange={(page) =>
              dispatch({
                type: TasksAction.CHANGE_TASKS_ACTIVE_PAGE,
                data: page,
              })
            }
          />
        </div>
      }
      currentPage={activePage}
      itemsPerPage={PAGE_LENGTH}
    />
  );
};

export default TasksTable;
